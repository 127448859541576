import Api from 'api/Api';
import swal from 'sweetalert';
import { getFromLocalStorage, getObjectElement } from './helpers';

export const mobileOperators = [
  1375, // Beeline
  1764, // Kcell
  1765, // Activ
  476, // Tele2
  3421, // Altel
];

export const RepayTransaction = (repayParams, log) => {
  let logQuery = {
    Sn: log.TransId,
    Account: log.Account,
    Service: log.ServiceId,
    Status: log.PostStatus.String,
    TerminalId: log.TerminalId,
    Amount: log.Amount,
    Error: log.Error_name.String,
    ErrorCode: log.Error_code.String,
  };

  Api.log({
    logType: 'repayTrying',
    userid: Number(getFromLocalStorage('userId')),
    data: {
      params: repayParams,
      log: logQuery,
      repayType: getRepayType(log, repayParams),
    },
    timestamp: (Date.now() / 1000) | 0,
  });

  //пытаемся перепровести
  Api.repayTrans(JSON.stringify(repayParams)).then((response) => {
    //все ок, транзакция прошла
    if (response.result !== undefined) {
      swal(
        'Транзакция перепроведена!',
        'Новый SN = ' + response.newSN,
        'success'
      );
      //логгируем успешное перепроведение
      Api.log({
        logType: 'repaySuccess',
        userid: Number(getFromLocalStorage('userId')),
        data: { oldSn: repayParams.Sn, newSn: response.newSN },
        timestamp: (Date.now() / 1000) | 0,
      });
    } else {
      swal('Ошибка!', response.error, 'error');
      Api.log({
        logType: 'repayError',
        userid: Number(getFromLocalStorage('userId')),
        data: { oldSn: repayParams.Sn },
        timestamp: (Date.now() / 1000) | 0,
      });
    }
  });
};

const levenshtein = (s1, s2, costs) => {
  let i, j, l1, l2, flip, ch, chl, ii, ii2, cost, cutHalf;
  l1 = s1.length;
  l2 = s2.length;

  costs = costs || {};
  let cr = costs.replace || 1;
  let cri = costs.replaceCase || costs.replace || 1;
  let ci = costs.insert || 1;
  let cd = costs.remove || 1;

  cutHalf = flip = Math.max(l1, l2);

  let minCost = Math.min(cd, ci, cr);
  let minD = Math.max(minCost, (l1 - l2) * cd);
  let minI = Math.max(minCost, (l2 - l1) * ci);
  let buf = new Array(cutHalf * 2 - 1);

  for (i = 0; i <= l2; ++i) {
    buf.splice(i, 1, i * minD);
  }

  for (i = 0; i < l1; ++i, flip = cutHalf - flip) {
    const s1Item = getObjectElement(s1, Number(i));

    ch = s1Item;
    chl = ch.toLowerCase();
    buf.splice(flip, 1, (i + 1) * minI);

    ii = flip;
    ii2 = cutHalf - flip;

    for (j = 0; j < l2; ++j, ++ii, ++ii2) {
      const s2Item = getObjectElement(s2, j);
      const bufI = getObjectElement(buf, ii2 + 1);
      const bufI2 = getObjectElement(buf, ii);
      const bufI3 = getObjectElement(buf, ii2);

      cost = ch === s2Item ? 0 : checkLevenshtein(chl, s2, j, cri, cr);
      buf.splice(ii + 1, 1, Math.min(bufI + cd, bufI2 + ci, bufI3 + cost));
    }
  }

  return getObjectElement(buf, l2 + cutHalf - flip);
};

const checkLevenshtein = (chl, s2, j, cri, cr) => {
  const s2Item = getObjectElement(s2, Number(j));
  if (chl === s2Item.toLowerCase()) return cri;
  return cr;
};

// 1. другой мобильный сервис
// 2. другой моб сервис с заменй 1-2 цифр
// 3. с мобильного на другой сервис
// 4. на другой сервис с полной заменой номера аккаунта
const getRepayType = (oldD, newD) => {
  if (
    mobileOperators.includes(1 * oldD.ServiceId) &&
    mobileOperators.includes(1 * newD.IdService) &&
    oldD.Account === newD.Account
  ) {
    return 1;
  }
  if (
    mobileOperators.includes(1 * oldD.ServiceId) &&
    mobileOperators.includes(1 * newD.IdService) &&
    levenshtein(oldD.Account, newD.Account) < 3
  ) {
    return 2;
  }
  if (
    mobileOperators.includes(1 * oldD.ServiceId) &&
    mobileOperators.indexOf(1 * newD.IdService) === -1 &&
    levenshtein(oldD.Account, newD.Account) < 3
  ) {
    return 3;
  }
  if (
    oldD.ServiceId !== newD.IdService &&
    levenshtein(oldD.Account, newD.Account) > 3
  ) {
    return 4;
  }
  return 5;
};
