export const ELMA_USERNAME = process.env.REACT_APP_ELMA_USERNAME || '';
export const ELMA_PASSWORD = process.env.REACT_APP_ELMA_PASSWORD || '';
export const JWT_PUBLIC_KEY = process.env.REACT_APP_JWT_PUBLIC_KEY || '';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';

export const MARKED_FOR_CANCELLATION = 1;
export const IN_THE_PROGRESS_OF_CANCELLATION = 2;
export const CANCELLED = 3;
export const CANCELLATION_DENIED = 4;
export const RECREATED = 5;
export const PAYMENT_STATUSES = {
  [MARKED_FOR_CANCELLATION]: 'Помечен на отмену',
  [IN_THE_PROGRESS_OF_CANCELLATION]: 'В процессе отмены',
  [CANCELLED]: 'Отменен',
  [CANCELLATION_DENIED]: 'Отказано в отмене',
  [RECREATED]: 'Перепроведен',
};
export const MIN_DAYS_BEFORE_CHANGE_PASSWORD = 3;
export const CHANGE_PASSWORD_PERIOD_DAYS = 90;

export const FAQ_LINK = `${window.location.origin}/info?tab=`;
export const SELECT_MANAGER_USERS = 4;
export const SELECT_SUPERVISOR_USERS = 3;
export const SELECT_OPERATOR_USERS = 1;
export const BCK_SERVICES_FOR_SHOW_PHONE = [
  10459, 9984, 9967, 9965, 9964, 10024, 9966, 9968,
];
