import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';

import Button from 'components/ui/Button';
import { clearPersonInfo, setPersonInfo } from 'features/mainSlice';
import { formatLkDate } from 'functions/helpers';
import {
  blockPersonApi,
  getPersonPaymentsLimit,
  getAituPayStatus,
  getUserStatusTitle,
  showPersonInfoLimitAmount,
  getPersonInfoTableValue,
} from 'functions/kassa24';
import { COLORS, PERSON_STATUSES, LIMITS } from 'constants/person';

import ActivationPerson from './activation/ActivationPerson';
let key = 0;

const PersonInfo = () => {
  const dispatch = useDispatch();
  const personInfo = useSelector((state) => state.main.personInfo);
  const [changedStatus, setChangeStatus] = useState('');
  const [showActivateModal, setShowActivateModal] = useState(false);
  const {
    Balance,
    CellPhone,
    Name,
    Patronymic,
    PaymentsLimit,
    RegDate,
    ReplenishmentLimit,
    IdentificationDeclineText,
    IdentificationRequestDate,
    IdentificationProcessingDate,
    Surname,
    typeOfUser,
    Login,
    Email,
    Status,
    DateOfBlocking,
    ReasonForBlocking,
    ActivDate: activateDate,
    Migration,
    Offer,
  } = personInfo;

  useEffect(() => {
    dispatch(clearPersonInfo());
  }, [dispatch]);

  const blockPerson = () =>
    dispatch(blockPersonApi(personInfo, setPersonInfo, setChangeStatus));

  const status = useMemo(
    () => changedStatus || Status,
    [changedStatus, Status]
  );

  const lostLimitForPaymentsPerMonth = getPersonPaymentsLimit({
    type: 'payments',
    personInfo,
  });

  const lostLimitForReplenishmentsPerMonth = getPersonPaymentsLimit({
    type: 'replenish',
    personInfo,
  });

  const aituPayStatus = useMemo(() => {
    return getAituPayStatus({ Offer, Migration });
  }, [Offer, Migration]);

  const identificationRowColor = useMemo(
    () => (typeOfUser === 'Идентифицирован' ? COLORS.GREEN : COLORS.RED),
    [typeOfUser]
  );

  return (
    <>
      {personInfo.ID && (
        <Grid container spacing={3}>
          <Grid item sm={6} align="top">
            {showActivateModal && (
              <ActivationPerson
                fullName={`${Surname} ${Name} ${Patronymic}`}
                setShowActivateModal={setShowActivateModal}
                isItUserActivation={true}
              />
            )}
            <Card>
              <CardHeader
                title={
                  <>
                    <div>
                      {Surname} {Name} {Patronymic}
                    </div>
                  </>
                }
                subheader={
                  <>
                    <div
                      className=" mdc-typography--caption"
                      style={{
                        color:
                          status === PERSON_STATUSES.BLOCKED
                            ? COLORS.RED
                            : COLORS.GREEN,
                      }}
                    >
                      {getUserStatusTitle(status, ReasonForBlocking)}
                      {getPersonInfoTableValue('status', {
                        DateOfBlocking,
                        status,
                      })}
                    </div>
                    <div
                      className=" mdc-typography--caption"
                      style={{ color: identificationRowColor }}
                    >
                      {typeOfUser}
                    </div>
                    {getPersonInfoTableValue('identificationDate', {
                      identificationRowColor,
                      IdentificationRequestDate,
                    })}
                    {getPersonInfoTableValue('identificationProcessingDate', {
                      identificationRowColor,
                      IdentificationProcessingDate,
                    })}

                    {IdentificationDeclineText && (
                      <div
                        className=" mdc-typography--caption"
                        style={{ color: COLORS.RED }}
                      >
                        Причина отказа в идентификации:{' '}
                        {IdentificationDeclineText.split('\n').map((i) => (
                          <div key={key++}>{i}</div>
                        ))}
                      </div>
                    )}
                  </>
                }
              />
              <Divider />
              <CardContent>
                Текущий баланс:{' '}
                {getPersonInfoTableValue('balance', { Balance })} тг <br />
                Номер счета: {Login} <br />
                Номер телефона: {CellPhone} <br />
                E-mail: {Email} <br />
                Дата регистрации: {RegDate.replace('T', ' ').replace(
                  'Z',
                  ''
                )}{' '}
                <br />
                Дата активации: {formatLkDate(activateDate)} <br />
                Статус миграции в AituPay: {aituPayStatus || '-'}
                <br />
                <br />
                Общая сумма платежей с начала месяца:{' '}
                {getPersonInfoTableValue('payments', { PaymentsLimit })} тг{' '}
                <br />
                Общая сумма пополнений с начала месяца:{' '}
                {getPersonInfoTableValue('replenishmentLimit', {
                  ReplenishmentLimit,
                })}{' '}
                тг
                {typeOfUser !== '' &&
                ReplenishmentLimit !== '' &&
                PaymentsLimit !== '' ? (
                  <>
                    <br />
                    Оставшийся лимит на платежи в этом месяце:
                    {showPersonInfoLimitAmount(lostLimitForPaymentsPerMonth)}
                    <br />
                    Оставшийся лимит на пополнения в этом месяце:
                    {showPersonInfoLimitAmount(
                      lostLimitForReplenishmentsPerMonth
                    )}
                    <br />
                  </>
                ) : (
                  'Невозможно рассчитать лимиты для данного типа пользователя'
                )}
              </CardContent>
              <CardActions>
                <Button onClick={blockPerson} color="secondary">
                  Заблокировать
                </Button>

                <Button
                  color="primary"
                  onClick={() => setShowActivateModal(true)}
                >
                  Активировать
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item sm={6} align="top">
            <Card>
              <CardHeader title="Лимиты для НЕ идентифицированного кошелька" />
              <Divider />
              <CardContent>
                В месяц: {LIMITS['Не идентифицирован'].month} тг <br />В день:{' '}
                {LIMITS['Не идентифицирован'].day} тг <br />
                На один платеж: {LIMITS['Не идентифицирован'].onePayment} тг
                <br />
                Максимальный баланс пользователя:{' '}
                {LIMITS['Не идентифицирован'].balance} тг
              </CardContent>
            </Card>
            <br />
            <Card>
              <CardHeader title="Лимиты для Идентифицированного кошелька" />
              <Divider />
              <CardContent>
                В месяц: {LIMITS['Идентифицирован'].month} тг <br />В день:{' '}
                {LIMITS['Идентифицирован'].day} тг <br />
                На один платеж: {LIMITS['Идентифицирован'].onePayment} тг
                <br />
                Максимальный баланс пользователя:{' '}
                {LIMITS['Идентифицирован'].balance} тг
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PersonInfo;
