import React, { useEffect, useState, useMemo, useCallback } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import Api from 'api/Api';
import { useUser } from 'hooks';
import Button from 'components/ui/Button';
import TableCustom from 'components/ui/Table';
import CustomTextField from 'components/ui/TextField';
import {
  getFromLocalStorage,
  getHumanDateFormat,
  getItemsByUserRole,
} from 'functions/helpers';
import { USER_AUDITOR, USER_OPERATOR } from 'constants/user';

export const BLACK_LIST_TABLE_HEAD = [
  { field: 'ID', label: 'ID' },
  { field: 'Account', label: 'Лицевой счет' },
  { field: 'CreationDate', label: 'Дата добавления' },
  {
    field: 'Operation',
    label: 'Операции',
    sort: false,
    deniedForRoles: [USER_OPERATOR, USER_AUDITOR],
  },
];

const BlackList = () => {
  const [account, setAccount] = useState('');
  const [reports, setReports] = useState([]);
  const [tableCols, setTableCols] = useState([]);
  const { userRoles } = useUser();
  const isReverse = false;

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableHead = useMemo(() => {
    return getItemsByUserRole(BLACK_LIST_TABLE_HEAD, userRoles);
  }, [userRoles]);

  const setReportData = useCallback(
    (report) => {
      if (isReverse) {
        setReports(report.reverse());
      } else {
        setReports(report);
      }
    },
    [isReverse]
  );

  const getReport = useCallback(() => {
    Api.getBlackListReport({ account: account }).then((response) => {
      if (typeof response !== 'undefined') {
        setReportData(response.sort((a, b) => a.ID - b.ID));
      }
    });
  }, [account, setReportData]);

  const removeAccountFromBlackList = useCallback(
    (ID, Account) => {
      swal({
        title: 'Подтвердите действие',
        text: `Вы уверены что хотите удалить лицевой счет ${Account} из черного списка?`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((sure) => {
        if (sure) {
          Api.removeFromBlackList({ ID: Number(ID) }).then((result) => {
            if (typeof result !== 'undefined') {
              const { status } = result;

              if (status === 0) {
                swal('ОК!', `Лицевой счет удален из черного списка`, 'success');
                getReport();
                Api.log({
                  logType: 'removeFromBlackList',
                  userid: Number(getFromLocalStorage('userId')),
                  data: { ID },
                  timestamp: (Date.now() / 1000) | 0,
                });
              }
            }
          });
        }
      });
    },
    [getReport]
  );

  useEffect(() => {
    let columns = [];
    reports.map((item) => {
      return columns.push({
        ID: item.ID,
        Account: item.Account,
        CreationDate: getHumanDateFormat(item.CreationDate),
        Operation: (
          <IconButton
            onClick={() => removeAccountFromBlackList(item.ID, item.Account)}
            style={{ padding: 0 }}
          >
            <HighlightOffIcon color="secondary" />
          </IconButton>
        ),
      });
    });

    setTableCols(columns);
  }, [reports, removeAccountFromBlackList]);

  const handleChangeAccount = (accountValue) => {
    setAccount(accountValue);
  };

  const addAccountToBlackList = () => {
    swal({
      title: 'Добавления пользователя в черный список',
      text: 'Введите лицевой счет:',
      content: 'input',
      button: {
        text: 'Добавить',
        closeModal: false,
      },
    }).then((enteredAccount) => {
      if (enteredAccount) {
        Api.addToBlackList({ Account: enteredAccount })
          .then((response) => {
            if (response !== null) {
              Api.log({
                logType: 'addToBlackList',
                userid: Number(getFromLocalStorage('userId')),
                data: { account: enteredAccount },
                timestamp: (Date.now() / 1000) | 0,
              });

              swal('ОК!', 'Пользователь добавлен в черный список', 'success');
              getReport();
            }
          })
          .catch(() =>
            swal(
              'Ошибка!',
              'Не удалось добавить пользователя в черный список',
              'error'
            )
          );
      } else {
        swal('Ошибка!', 'Лицевой счет обязателен для заполнения', 'error');
      }
      return swal.close();
    });
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item sm={10} xs={12}>
          <Typography variant="h1">Управление черным списком</Typography>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Button color="primary" fullWidth onClick={addAccountToBlackList}>
            Добавить в черный список
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={3} xs={12}>
          <CustomTextField
            label="Введите лицевой счет"
            value={account}
            fullWidth
            type="text"
            variant="filled"
            onChange={(e) => handleChangeAccount(e.target.value.trim())}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Button
            color="primary"
            className="noMarg"
            fullWidth
            onClick={getReport}
          >
            Найти
          </Button>
        </Grid>
      </Grid>
      {reports.length > 0 && (
        <div className="searchResult">
          <TableCustom
            head={tableHead}
            columns={tableCols}
            pageSize={30}
            showPaging={true}
          />
        </div>
      )}
    </>
  );
};

export default BlackList;
