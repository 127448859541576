import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import swal from 'sweetalert';
import useStyles from '../useStyles';

import Api from 'api/Api';
import TextField from 'components/ui/TextField';
import Button from 'components/ui/Button';
import DatePicker from 'components/ui/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { setFiscalSearchData, setFiscalSearchParams } from 'features/mainSlice';

const DATE_FROM_DEFAULT = new Date(
  moment().subtract(3, 'days').format('YYYY-MM-DD 00:00:00')
);
const DATE_TO_DEFAULT = new Date();

const SearchForm = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { fiscalSearchParams } = useSelector((state) => state.main);
  const [transactionNumber, setTransactionNumber] = useState(
    fiscalSearchParams.transactionNumber || ''
  );
  const [idTerminal, setIdTerminal] = useState(
    fiscalSearchParams.idTerminal || ''
  );

  const [dateFrom, setDateFrom] = useState(DATE_FROM_DEFAULT);
  const [dateTo, setDateTo] = useState(DATE_TO_DEFAULT);

  const clearSearchForm = useCallback(() => {
    dispatch(setFiscalSearchData([]));
    dispatch(setFiscalSearchParams({}));
    setTransactionNumber('');
    setIdTerminal('');
    setDateFrom(DATE_FROM_DEFAULT);
    setDateTo(DATE_TO_DEFAULT);
  }, [dispatch]);

  const searchFiscalOperations = useCallback(() => {
    if (idTerminal === '') {
      swal('Ошибка!', `Поле ID терминала не заполнено!`, 'error');
    } else {
      let data = {};
      data.userType = 'user';
      data.transactionNumber = transactionNumber;
      data.value = idTerminal;
      data.dateFrom = moment(dateFrom).format('YYYY-MM-DD HH:mm:ss');
      data.dateTo = moment(dateTo).format('YYYY-MM-DD HH:mm:ss');
      dispatch(setFiscalSearchParams({ transactionNumber, idTerminal }));

      Api.getFiscalOperations(data).then((res) => {
        if (res?.data && res.data !== null) {
          dispatch(setFiscalSearchData(res.data));
        } else {
          dispatch(setFiscalSearchData([]));
        }
      });
    }
  }, [dateFrom, dateTo, transactionNumber, idTerminal, dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3} align="top">
        <div className="customInp">
          <TextField
            name="terminalId"
            onChange={(e) => setIdTerminal(e.target.value)}
            type="number"
            fullWidth
            variant="filled"
            placeholder="ID терминала"
            value={idTerminal}
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={3} align="top">
        <div className="customInp">
          <TextField
            placeholder="Номер транзакции"
            id="fiscalNumber"
            name="fiscalNumber"
            onChange={(e) => setTransactionNumber(e.target.value)}
            type="number"
            fullWidth
            variant="filled"
            value={transactionNumber}
          />
        </div>
      </Grid>

      <Grid xs={6} sm={2} item>
        <DatePicker selected={dateFrom} onChange={setDateFrom} />
      </Grid>
      <Grid xs={6} sm={2} item>
        <DatePicker selected={dateTo} onChange={setDateTo} />
      </Grid>
      <Grid xs={12} sm={2} item>
        <div className={classes.flexButtons}>
          {(transactionNumber || idTerminal) && (
            <Button
              color="secondary"
              onClick={clearSearchForm}
              className="noMarg"
              fullWidth
            >
              Сбросить
            </Button>
          )}
          <Button
            color="primary"
            onClick={searchFiscalOperations}
            className="noMarg"
            fullWidth
          >
            Найти
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SearchForm;
