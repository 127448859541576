import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import moment from 'moment';
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from '@material-ui/core';

import {
  convertAmount,
  exportReport,
  getHumanDateFormat,
} from 'functions/helpers';
import {
  getOperationTitle,
  getCommissionValue,
} from 'functions/personOperations';
import Api from 'api/Api';
import Button from 'components/ui/Button';
import DatePicker from 'components/ui/DatePicker';

const TABLE_HEADER = [
  'SN',
  'Дата платежа',
  'Сумма',
  'Комиссия',
  'Cервис',
  'Тип операции',
  'Номер лицевого счета',
];
let headKey = 0;

const PersonOperations = () => {
  const personInfo = useSelector((state) => state.main.personInfo);
  const [operations, setOperations] = useState([]);
  const [DateTo, setDateTo] = useState(new Date());
  const [DateFrom, setDateFrom] = useState(
    new Date(moment().subtract(1, 'days'))
  );

  useEffect(() => {
    setOperations([]);
  }, [personInfo.ID]);

  const handleChangeDateFrom = (date) => {
    setDateFrom(date);
  };

  const handleChangeDateTo = (date) => {
    setDateTo(date);
  };

  const searchOperations = useCallback(() => {
    Api.getPersonOperations({
      IDPerson: personInfo.ID,
      DateFrom: moment(DateFrom).format('YYYY-MM-DD HH:00:00'),
      DateTo: moment(DateTo).format('YYYY-MM-DD HH:59:00'),
    })
      .then((response) => {
        if (response !== null && typeof response !== 'undefined') {
          setOperations(response);
        } else {
          swal('', 'Не найдено операций за выбранный период', 'warning');
        }
      })
      .catch(() =>
        swal('Ошибка!', 'Не удалось получить список операций', 'error')
      );
  }, [personInfo, DateFrom, DateTo]);

  const sortedOperations = useMemo(() => {
    return []
      .concat(operations)
      .sort(({ Date: { String: a } }, { Date: { String: b } }) => {
        const val1 = new Date(a).getTime();
        const val2 = new Date(b).getTime();

        return val1 - val2;
      });
  }, [operations]);

  const exportDataHandler = useCallback(() => {
    exportReport(operations, 'personHistory');
  }, [operations]);

  return (
    <>
      {personInfo.ID !== 0 && personInfo.ID !== undefined && (
        <>
          <h2>Просмотр операций кошелька за выбранный период</h2>

          <Grid container spacing={3}>
            <Grid item sm={3} align="top">
              <DatePicker selected={DateFrom} onChange={handleChangeDateFrom} />
            </Grid>
            <Grid item sm={3} align="top">
              <DatePicker selected={DateTo} onChange={handleChangeDateTo} />
            </Grid>
            <Grid item sm={3} align="top">
              <Button
                fullWidth
                color="primary"
                className="noMarg"
                onClick={searchOperations}
              >
                Показать
              </Button>
            </Grid>
            <Grid item sm={3} align="top">
              <Button
                color="primary"
                fullWidth
                className="noMarg"
                onClick={exportDataHandler}
                disabled={!sortedOperations.length}
              >
                Экспорт в Excel
              </Button>
            </Grid>
            <Grid item xs={12} align="top">
              {sortedOperations.length ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEADER.map((item, i) => (
                          <TableCell key={headKey++}>{item}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedOperations.map(
                        ({
                          SN,
                          Date: paymentDate,
                          Amount,
                          Commission,
                          ServiceName,
                          ServiceId,
                          Description,
                          IsSatty,
                          RecipientCellPhone,
                          RecipientLogin,
                          SwitchSN,
                          SwitchFI,
                        }) => {
                          return (
                            <TableRow key={SN.String}>
                              <TableCell>{SN.String}</TableCell>
                              <TableCell>
                                {getHumanDateFormat(paymentDate['String'])}
                              </TableCell>
                              <TableCell>
                                {convertAmount(Amount.String)} тг
                              </TableCell>
                              <TableCell>
                                {getCommissionValue(Commission.String)} тг
                              </TableCell>
                              <TableCell>
                                {ServiceName.String}{' '}
                                {ServiceId.Int64
                                  ? `(id: ${ServiceId.Int64})`
                                  : '-'}
                              </TableCell>
                              <TableCell>
                                {getOperationTitle({
                                  IsSatty: IsSatty.Int64,
                                  Description: Description.String,
                                  RecipientCellPhone: RecipientCellPhone.String,
                                })}
                                {Boolean(SwitchFI.String) && (
                                  <div>
                                    <b>Банк:</b> {SwitchFI.String}
                                  </div>
                                )}
                                {Boolean(SwitchSN.String) && (
                                  <div>
                                    <b>Номер перевода:</b> {SwitchSN.String}
                                  </div>
                                )}
                              </TableCell>
                              <TableCell>
                                {Number(RecipientLogin.String) || '-'}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default PersonOperations;
