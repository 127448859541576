import React from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'functions/helpers';
import { MYPOST_BITRIX_TASK_TYPES } from 'constants/bitrixTask';
import BitrixTaskForm from 'components/BitrixTaskForm';

const bitixFormsFields = [
  { label: 'Город', name: 'city', req: false },
  { label: 'Улица', name: 'street', req: false },
  { label: 'Номер дома', name: 'houseNumber', req: false },
  { label: 'Номер постамата *', name: 'postOfficeNumber', req: true },
  { label: 'Наименование точки', name: 'pointName', req: false },
  { label: 'Комментарий', name: 'comment', req: false },
];

const PostOfficeNotWork = ({ sendBitrixTask }) => {
  const initialValues = {
    city: '',
    street: '',
    houseNumber: '',
    postOfficeNumber: '',
    pointName: '',
    comment: '',
  };

  const sendBitrixTaskFunc = ({
    city,
    street,
    houseNumber,
    postOfficeNumber,
    pointName,
    comment,
  }) => {
    const data = {
      city,
      street,
      houseNumber,
      postOfficeNumber,
      pointName,
      comment,
      userId: Number(getFromLocalStorage('userId')),
    };

    sendBitrixTask(data, MYPOST_BITRIX_TASK_TYPES.POSTOFFICE_NOT_WORKING);
  };

  return (
    <BitrixTaskForm
      fields={bitixFormsFields}
      initialState={initialValues}
      sendBitrixTask={sendBitrixTaskFunc}
    />
  );
};

PostOfficeNotWork.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
};

export default PostOfficeNotWork;
