import React from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'functions/helpers';
import BitrixTaskForm from 'components/BitrixTaskForm';
import { PROVIDER_BITRIX_TASK_TYPES } from 'constants/bitrixTask';

const bitixFormsFields = [
  { label: 'Наименование организации', name: 'companyName', req: false },
  { label: 'Вид деятельности', name: 'activityType', req: false },
  { label: 'Телефон *', name: 'phone', req: true },
  { label: 'Адрес', name: 'address', req: false },
  { label: 'E-mail', name: 'email', req: false },
  { label: 'Комментарий', name: 'comment', type: 'textarea', req: false },
];

const BecomeProvider = ({ sendBitrixTask }) => {
  const state = {
    phone: '',
    email: '',
    comment: '',
    companyName: '',
    activityType: '',
    address: '',
  };

  const sendBitrixTaskFunc = ({
    phone,
    email,
    comment,
    companyName,
    activityType,
    address,
  }) => {
    const data = {
      phone,
      email,
      comment,
      companyName,
      activityType,
      address,
      userId: Number(getFromLocalStorage('userId')),
    };

    sendBitrixTask(data, PROVIDER_BITRIX_TASK_TYPES.BECOME_PROVIDER);
  };

  return (
    <BitrixTaskForm
      fields={bitixFormsFields}
      initialState={state}
      sendBitrixTask={sendBitrixTaskFunc}
    />
  );
};

BecomeProvider.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
};

export default BecomeProvider;
