import React, { useState, useEffect } from 'react';
import Api from 'api/Api';
import { getParsedHtmlContent } from 'functions/helpers';
import { Typography } from '@material-ui/core';

let newsKey = 0;

const News = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    Api.getNews().then((response) => {
      let newsList = JSON.parse(response.data);
      if (newsList !== undefined) {
        setNews(newsList);
      }
    });
  }, []);

  return (
    <>
      <Typography variant="h1">Новости</Typography>
      {news.map((item) => (
        <div className="actionItem" key={newsKey++}>
          <Typography variant="h2" className="iconBlue noBold">
            {item.title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {item.date}
          </Typography>
          {/* eslint-disable-next-line react/no-danger */}
          <div>{getParsedHtmlContent(item.text)} </div>
        </div>
      ))}
      {news.length === 0 && 'Список новостей пуст'}
    </>
  );
};

export default News;
