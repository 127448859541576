import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Button from 'components/ui/Button';
import Api from 'api/Api';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { setTermList } from 'features/mainSlice';

const SearchTerminal = () => {
  const dispatch = useDispatch();
  const [IDTerminal, setIDTerminal] = useState('');
  const [Name, setName] = useState('');
  const [HourWork, setHourWork] = useState('');
  const [Buildnum, setBuildnum] = useState('');

  //поиск теремов
  const searchTerm = () => {
    //начинать поиск только если введен id терминала или имя+какая-то часть адреса
    let start =
      IDTerminal !== 0 || (Name !== '' && (HourWork !== '' || Buildnum !== ''));

    if (start) {
      Api.getTerm(
        JSON.stringify({
          IDTerminal: Number(IDTerminal),
          Name,
          HourWork,
          Buildnum,
        })
      )
        .then((response) => {
          dispatch(setTermList(response));
        })
        .catch();
    } else {
      swal('Ошибка!', 'Заполните ID теминала или Название и Адрес ', 'error');
    }
  };

  return (
    <div style={{ margin: 0 }}>
      <div className="grid1fr6">
        <div className="customInp customInpT">
          <TextField
            type="number"
            value={IDTerminal}
            name="IDTerminal"
            onInput={(e) => setIDTerminal(e.target.value)}
            label="Номер терминала"
            variant="filled"
          />
        </div>
        <div className="lineHeight3">
          <b>или</b>
        </div>
        <div className="customInp customInpT">
          <TextField
            type="text"
            value={Name}
            name="Name"
            onInput={(e) => setName(e.target.value)}
            label="Имя терминала"
            variant="filled"
          />
        </div>
        <div className="customInp customInpT">
          <TextField
            type="text"
            value={HourWork}
            name="HourWork"
            onInput={(e) => setHourWork(e.target.value)}
            label="Улица"
            variant="filled"
          />
        </div>
        <div className="customInp customInpT">
          <TextField
            type="text"
            value={Buildnum}
            name="Buildnum"
            onInput={(e) => setBuildnum(e.target.value)}
            label="Дом"
            variant="filled"
          />
        </div>
        <Button color="primary" onClick={searchTerm} className="noMarg ">
          Найти
        </Button>
      </div>
    </div>
  );
};

export default SearchTerminal;
