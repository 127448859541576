import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import TableCustom from 'components/ui/Table';

const useStyles = makeStyles((theme) => ({
  blockedStatus: {
    backgroundColor: '#f94949',
    padding: 3,
    color: 'white',
    textAlign: 'center',
    borderRadius: 5,
  },
  activeStatus: {
    backgroundColor: '#55a755',
    padding: 3,
    color: 'white',
    textAlign: 'center',
    borderRadius: 5,
  },
}));

const AgentsTable = ({ tableRows }) => {
  const [tableData, setTableData] = useState([]);
  const classes = useStyles();
  const tableHead = useMemo(() => {
    return [
      { field: 'IDAgents', label: 'ID агента', width: '10%' },
      { field: 'AgentName', label: ' Наименование агента', width: '20%' },
      { field: 'FullName', label: 'Куратор', width: '20%' },
      { field: 'CuratorLogin', label: 'Логин куратора', width: '20%' },
      { field: 'Filial', label: 'Филиал', width: '20%' },
      { field: 'Status', label: 'Статус агента', width: '10%' },
    ];
  }, []);

  useEffect(() => {
    let tableRowsModified = [];
    tableRows.forEach((user) => {
      let userData = { ...user };
      userData.Status =
        user.Blocked.Int16 === 1 ? (
          <div className={classes.blockedStatus}>Заблокирован</div>
        ) : (
          <div className={classes.activeStatus}>Активен</div>
        );
      console.log('userData', userData);
      tableRowsModified.push(userData);
    });

    setTableData(tableRowsModified);
  }, [classes.activeStatus, classes.blockedStatus, tableRows]);

  return (
    <div style={{ marginTop: 20 }}>
      <TableCustom
        head={tableHead}
        columns={tableData}
        pageSize={100}
        showPaging={true}
      />
    </div>
  );
};

AgentsTable.propTypes = {
  tableRows: PropTypes.array.isRequired,
};

export default AgentsTable;
