import React from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'functions/helpers';
import BitrixTaskForm from '../BitrixTaskForm';
import { OWN_NETWORK_BITRIX_TASK_TYPES } from 'constants/bitrixTask';

const bitrixFormsFields = [
  { label: 'Id терминала *', name: 'idTerminal', req: true },
  { label: 'Город *', name: 'city', req: true },
  {
    label: 'Ответственный директор',
    name: 'responsibleId',
    req: true,
    type: 'select',
  },
  { label: 'Адрес', name: 'address', req: false },
  { label: 'Наименование точки *', name: 'pointName', req: true },
  { label: 'Номер телефона', name: 'phone', req: false },
  { label: 'Комментарий', name: 'comment', req: false },
];

const TerminalDoesntWork = ({ sendBitrixTask }) => {
  const initialState = {
    idTerminal: '',
    city: '',
    address: '',
    pointName: '',
    phone: '',
    comment: '',
    responsibleId: '',
  };

  const sendBitrixTaskFunc = ({
    idTerminal,
    city,
    address,
    pointName,
    phone,
    comment,
    responsibleId,
  }) => {
    const data = {
      idTerminal,
      city,
      address,
      pointName,
      phone,
      comment,
      userId: Number(getFromLocalStorage('userId')),
      responsibleId,
    };

    sendBitrixTask(data, OWN_NETWORK_BITRIX_TASK_TYPES.TERMINAL_DOESNT_WORK);
  };

  return (
    <BitrixTaskForm
      fields={bitrixFormsFields}
      initialState={initialState}
      sendBitrixTask={sendBitrixTaskFunc}
    />
  );
};

TerminalDoesntWork.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
};

export default TerminalDoesntWork;
