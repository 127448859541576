import React from 'react';
import * as Sentry from '@sentry/react';
import {
  Routes,
  Route,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';

// Layouts
import LoginLayout from 'layouts/LoginLayout';
import MainLayout from 'layouts/Main/';

// Screens
import ErrorPage from 'screens/ErrorPage';
import NotFoundPage from 'screens/NotFoundPage';
import Login from 'screens/Login';

import history from 'appHistory';
import { COMPONENT_WITH_MAIN } from 'constants/route';

const getRouteElement = (Component, Layout = '') => (
  <Layout>
    <Component />
  </Layout>
);

export default (
  <Sentry.ErrorBoundary fallback={ErrorPage}>
    <HistoryRouter history={history}>
      <Routes>
        {COMPONENT_WITH_MAIN.map(({ path, component }) => {
          return (
            <Route
              key={path}
              path={path}
              element={getRouteElement(component, MainLayout)}
            />
          );
        })}
        <Route path="/" element={getRouteElement(Login, LoginLayout)} />
        <Route path="*" element={getRouteElement(NotFoundPage, MainLayout)} />
      </Routes>
    </HistoryRouter>
  </Sentry.ErrorBoundary>
);
