import * as Sentry from '@sentry/react';

export function logErrorToSentry(error) {
  if (!window.location.href.includes('localhost')) {
    Sentry.withScope(() => {
      Sentry.captureMessage(error);
    });
  }
}

export function logWarningToSentry(text) {
  if (!window.location.href.includes('localhost')) {
    Sentry.captureMessage(text, 'warning');
  }
}

export function logInfoToSentry(text) {
  if (!window.location.href.includes('localhost')) {
    Sentry.captureMessage(text, 'info');
  }
}
