import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import swal from 'sweetalert';
import jwt from 'jsonwebtoken';
import { useFormik } from 'formik';
import { setUserInfo, setIsAuth } from 'features/userSlice';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from 'components/ui/Button';
import Api from 'api/Api';
import {
  JWT_PUBLIC_KEY,
  MIN_DAYS_BEFORE_CHANGE_PASSWORD,
} from 'constants/main';
import {
  setToLocalStorage,
  clearLocalStorage,
  b64DecodeUnicode,
  getDaysBeforePassExpired,
} from 'functions/helpers';
import history from 'appHistory';
import useStyles from './useStyles';

const validationSchema = yup.object({
  username: yup
    .string('Введите логин')
    .required('Поле обязательно для заполнения'),
  password: yup
    .string('Введите пароль')
    .required('Поле обязательно для заполнения'),
});

const SignIn = () => {
  useEffect(() => {
    clearLocalStorage();
  }, []);

  const dispatch = useDispatch();

  const auth = ({ username, password }) => {
    Api.login({
      username: username.trim(),
      password: password.trim(),
    }).then((response) => {
      if (typeof response !== 'undefined') {
        jwt.verify(
          response.token,
          b64DecodeUnicode(JWT_PUBLIC_KEY),
          (err, decoded) => {
            if (err === null && typeof decoded !== 'undefined') {
              dispatch(setIsAuth(true));
              dispatch(setUserInfo(decoded));

              setToLocalStorage('token', response.token);
              setToLocalStorage('userId', Number(decoded.userId));
              setToLocalStorage('login', decoded.login);
              setToLocalStorage('name', decoded.name);
              setToLocalStorage('passwordExpired', decoded.pwdLastSet);

              const dayBeforeExp = getDaysBeforePassExpired(decoded.pwdLastSet);

              if (dayBeforeExp <= MIN_DAYS_BEFORE_CHANGE_PASSWORD)
                history.push('changePassword');
              else history.push('/kassa24');
            } else {
              swal('Ошибка авторизации. Повторите попытку позднее');
            }

            return '';
          }
        );
      }
    });
  };

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: auth,
  });

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Авторизация
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Логин"
          type="username"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
        <TextField
          variant="outlined"
          margin="normal"
          label="Пароль"
          fullWidth
          type="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Авторизоваться
        </Button>
        {/* <Button fullWidth color="default" onClick={resetUserPassword}>
          Сбросить пароль
        </Button> */}
      </form>
    </div>
  );
};

export default SignIn;
