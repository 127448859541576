import React, { useState, useEffect } from 'react';
import Api from 'api/Api';
import Button from 'components/ui/Button';
import {
  exportReport,
  getHumanDateFormat,
  formatAmount,
  getClearPhoneNumber,
  getObjectElement,
} from 'functions/helpers';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import TableCustom from 'components/ui/Table';
import InputMask from 'components/ui/InputMaskCustom';
import swal from 'sweetalert';
import SearchTypeSelect from 'components/ui/SearchTypeSelect';

const SEARCH_TYPES = {
  BY_PHONE: 1,
  BY_SN: 2,
  BY_IIN: 3,
};

const tableHead = [
  { label: 'Оператор', field: 'Operator' },
  { label: 'ИИН', field: 'IIN' },
  { label: 'Транзакция', field: 'Trans' },
  { label: 'Телефон', field: 'Phone' },
  { label: 'Сервис', field: 'Service' },
  { label: 'Сумма', field: 'Amount' },
  { label: 'Комиссия', field: 'Comission' },
  { label: 'Дата начала', field: 'Datestart' },
  { label: 'Дата завершения', field: 'Dateend' },
  { label: 'Комментарий оператора', field: 'Comment' },
];

const searchOptions = [
  {
    title: 'Показать все',
    mask: '',
    id: 'all',
    label: '',
  },
  {
    title: 'По номеру телефона',
    mask: '8 (999) 999 99 99',
    id: 'phoneNumber',
    label: 'Номер телефона',
  },
  {
    title: 'По номеру транзакции',
    mask: '9'.repeat(20),
    id: 'sn',
    label: 'Номер транзакции',
  },
  {
    title: 'По ИИН',
    mask: '9'.repeat(12),
    id: 'iin',
    label: 'ИИН',
  },
];

const pageTitle = 'Проблемные платежи по налогам и штрафам, обработанные';

const OnepayTroubles = () => {
  const inputPropsDef = {
    type: 'tel',
    id: null,
    mask: '8 (999) 999 99 99',
    placeholder: 'Выберите способ поиска',
  };

  const services = useSelector((state) => state.main.services);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [iin, setIin] = useState('');
  const [searchType, setSearchType] = useState(0);
  const [Date_from, setDateFrom] = useState(
    new Date(moment().subtract(3, 'days'))
  );
  const [Date_to, setDateTo] = useState(new Date());
  const [reports, setReports] = useState([]);
  const [paramTrans, setParamTrans] = useState('');
  const [inputProps, setInputProps] = useState(inputPropsDef);

  useEffect(() => {
    registerLocale('ru', ru);
  }, []);

  useEffect(() => {
    let inpProp = {};
    const searchInfo = getObjectElement(searchOptions, Number(searchType));

    inpProp.mask = searchInfo.mask;
    inpProp.id = searchInfo.id;
    inpProp.placeholder = `Поиск ${searchInfo.title.toLowerCase()}`;

    setInputProps(inpProp);
  }, [searchType]);

  //один формат даты для дейтпикера, второй для запроса в базу
  const handleChangeDate_from = (date) => {
    setDateFrom(date);
  };

  const handleChangeDate_to = (date) => {
    setDateTo(date);
  };

  const getReport = () => {
    if (
      (searchType === SEARCH_TYPES.BY_PHONE && !phoneNumber) ||
      (searchType === SEARCH_TYPES.BY_SN && !paramTrans) ||
      (searchType === SEARCH_TYPES.BY_IIN && !iin)
    ) {
      return swal(
        '',
        `${
          getObjectElement(searchOptions, Number(searchType)).label
        } обязателен к заполнению`,
        'warning'
      );
    }

    let DateFrom = moment(Date_from).format('YYYY-MM-DD HH:mm:00');
    let DateTo = moment(Date_to).format('YYYY-MM-DD HH:mm:00');
    let params = {
      Date_from: DateFrom,
      Date_to: DateTo,
      sn: paramTrans,
      account: getClearPhoneNumber(phoneNumber),
      iin,
      searchType,
    };

    Api.getReport('paymentProblemProceed', JSON.stringify(params)).then(
      (response) => {
        if (response !== undefined) {
          if (response === null) setReports([]);
          else setReports(response);
        }
      }
    );
  };

  const getService = (id) => {
    return services.map((val) => {
      let result = '';
      if (val.value === id) {
        result = val.label;
      }
      return result;
    });
  };

  const clearField = (field, value) => {
    if (field === 'phoneNumber') {
      return value
        .replace('(', '')
        .replace(')', '')
        .replace('8', '')
        .replace(/\s+/g, '');
    }

    return value.trim();
  };

  const updateSearhParam = (e) => {
    switch (e.target.id) {
      case 'phoneNumber':
        setPhoneNumber(clearField(e.target.id, e.target.value));
        break;
      case 'iin':
        setIin(clearField(e.target.id, e.target.value));
        break;
      case 'sn':
        setParamTrans(clearField(e.target.id, e.target.value));
        break;
      default:
        break;
    }
  };

  const getTableRows = () => {
    let rows = [];
    if (reports !== undefined) {
      reports.map((val) => {
        return rows.push({
          Operator: val.IDSysUser.String,
          IIN: val.Account.String,
          Trans: val.SN.String,
          Phone: val.Phone.String,
          Service: getService(Number(val.Service.String)),
          Amount: formatAmount(val.Amount.String) + ' тг',
          Comission: formatAmount(val.Comission.String) + ' тг',
          Datestart: getHumanDateFormat(val.DateBegin.String),
          Dateend: getHumanDateFormat(val.DateEnd.String),
          Comment: val.Comment.String,
        });
      });
    }
    return rows;
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item sm={10} xs={12}>
          <Typography variant="h5">{pageTitle}</Typography>
        </Grid>

        <Grid item sm={2} xs={12}>
          <Button
            color="primary"
            fullWidth
            onClick={() => exportReport(reports, 'onepayTroubles')}
            disabled={!reports.length}
          >
            Экспорт в Excel
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={3} xs={12}>
          <SearchTypeSelect
            options={searchOptions}
            searchType={searchType}
            setSearchType={setSearchType}
          />
        </Grid>
        <Grid item xs={12} sm={3} align="top">
          {getObjectElement(searchOptions, Number(searchType)).id !== 'all' && (
            <div className="customInp">
              <InputMask
                {...inputProps}
                name="name"
                callback={updateSearhParam}
              />
            </div>
          )}
        </Grid>
        <Grid item sm={2} xs={6}>
          <div className="customInp">
            <DatePicker
              selected={Date_from}
              onChange={handleChangeDate_from}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              dateFormat="dd MMMM yyyy., HH:mm"
              timeCaption="Время"
              locale={ru}
            />
          </div>
        </Grid>
        <Grid item sm={2} xs={6}>
          <div className="customInp">
            <DatePicker
              selected={Date_to}
              onChange={handleChangeDate_to}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              dateFormat="dd MMMM yyyy., HH:mm"
              timeCaption="Время"
              locale={ru}
            />
          </div>
        </Grid>

        <Grid item sm={2} xs={12}>
          <Button
            color="primary"
            className="noMarg"
            onClick={getReport}
            fullWidth
          >
            Показать
          </Button>
        </Grid>
      </Grid>
      <div className="searchResult">
        {reports.length > 0 && (
          <TableCustom
            head={tableHead}
            columns={getTableRows()}
            pageSize={30}
            showPaging={true}
          />
        )}
      </div>
    </>
  );
};

export default OnepayTroubles;
