import React, { useState } from 'react';
import Api from 'api/Api';
import Button from 'components/ui/Button';
import PostTable from './postTable';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setPostList } from 'features/mainSlice';
import CustomTextField from 'components/ui/TextField';

const MyPost = () => {
  const dispatch = useDispatch();
  const [cellphone, setCellPhone] = useState('');
  const [count, setCount] = useState(10);
  const myPostList = useSelector((state) => state.main.myPostList);
  const myPostListAuto = useSelector((state) => state.main.myPostListAuto);
  const clientData = useSelector((state) => state.main.clientData);

  const setData = (e) => {
    switch (e.target.name) {
      case 'cellphone':
        setCellPhone(e.target.value);
        break;
      case 'count':
        setCount(e.target.value);
        break;
      default:
        break;
    }
  };
  const search = () => {
    let data = { Search: cellphone, Top: count };
    Api.getInvoicesList(data).then((response) => {
      if (response.Invoices != null) {
        dispatch(setPostList(response.Invoices));
      } else {
        dispatch(setPostList([]));
      }
    });
  };

  return (
    <>
      <Typography variant="h1">Mypost</Typography>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={6}>
          <CustomTextField
            label="Номер телефона"
            value={cellphone}
            name="cellphone"
            fullWidth
            variant="filled"
            onChange={setData}
            type="text"
          />
        </Grid>
        <Grid item sm={4} xs={6}>
          <CustomTextField
            label="Количество"
            value={count}
            name="count"
            onChange={setData}
            fullWidth
            variant="filled"
            type="number"
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Button color="primary" fullWidth onClick={search} className="noMarg">
            Найти
          </Button>
        </Grid>
      </Grid>
      <div>
        {myPostList !== null && myPostList.length > 0 && (
          <PostTable
            list={myPostList}
            title={'Посылок найдено: ' + myPostList.length}
          />
        )}
        {myPostListAuto !== null && myPostListAuto.length ? (
          <PostTable
            list={myPostListAuto}
            title={
              'Номер телефона: ' +
              clientData.Cellphone +
              '. Последние посылки: ' +
              myPostListAuto.length
            }
          />
        ) : (
          <div />
        )}
      </div>
    </>
  );
};

export default MyPost;
