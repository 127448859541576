import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

let transParams = {
  Date_from: moment().subtract(3, 'days').format('YYYY-MM-DD 00:00:00'),
  Date_to: moment().format('YYYY-MM-DD 23:59:59'),
  Agent: 1,
  Sub_on: 1,
  Service: 0,
  Terminal: 0,
  Number: '',
  Transaction: '',
  Status: '',
  Limit: 10,
};

let onepayParams = {
  Date_from: moment().subtract(3, 'days').format('YYYY-MM-DD 00:00:00'),
  Date_to: moment().format('YYYY-MM-DD 23:59:59'),
  Service: 0,
  Login: '',
  Transaction: '',
  Email: '',
  Phone: '',
  Limit: 10,
  Status: '',
};

const personInfo = {
  ActivDate: '',
  Balance: '',
  CellPhone: '',
  ID: 0,
  Name: '',
  Patronymic: '',
  PaymentsLimit: '',
  RegDate: '',
  ReplenishmentLimit: '',
  Surname: '',
  typeOfUser: '',
  Login: '',
  Email: '',
  Status: '',
};

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    wiki: {},
    services: [],
    activeSection: '/',
    showProgress: false,
    transParams,
    onepayParams,
    transListAP: [],
    transListLK: [],
    transListAuto: [],
    onepay_list: [],
    onepay_list_auto: [],
    terminals: [],
    myPostList: [],
    myPostListAuto: [],
    clientData: { Cellphone: '', Email: '' },
    cellphone: '7776951717',
    commentOnepay: '',
    lastSearchedData: { searchType: '', value: '' },
    personInfo,
    updTroubles: true,
    directorsList: [],
    isSearchTransactionByAccount: false,
    agentsCuratorsList: [],
    fiscalSearchParams: {},
    fiscalSearchData: [],
  },
  reducers: {
    setWiki: (state, action) => {
      state.wiki = Object.assign({}, action.payload);
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
    setTransListAuto: (state, action) => {
      state.transListAuto = action.payload;
    },
    setTransList: (state, action) => {
      state.transListLK = action.payload.transListLK;
      state.transListAP = action.payload.transListAP
        ? action.payload.transListAP.sort((a, b) => {
            return new Date(b.DateIn) - new Date(a.DateIn);
          })
        : [];
      state.transAndromedaUsers = action.payload.transAndromedaUsers;
    },
    setTransSearchParams: (state, action) => {
      state.transParams = Object.assign({}, action.payload);
    },
    cleanTransListAuto: (state) => {
      state.transListAuto = [];
    },
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
    setPersonInfo: (state, action) => {
      state.personInfo = action.payload;
    },
    saveSearchedPersonReqData: (state, action) => {
      state.lastSearchedData = action.payload;
    },
    toggleProgressbar: (state, action) => {
      state.showProgress = action.payload;
    },
    setClientData: (state, action) => {
      state.clientData = Object.assign({}, action.payload);
    },
    setTermList: (state, action) => {
      state.terminals = action.payload;
    },
    clearPersonInfo: (state, action) => {
      state.personInfo = {};
    },
    setPostList: (state, action) => {
      state.myPostList = action.payload;
    },
    setPostListAuto: (state, action) => {
      state.myPostListAuto = action.payload;
    },
    setOnepayList: (state, action) => {
      state.onepay_list = action.payload;
    },
    setOnepayListAuto: (state, action) => {
      state.onepay_list_auto = action.payload;
    },
    setOnepayParams: (state, action) => {
      state.onepayParams = action.payload;
    },
    setUpdateTroubles: (state, action) => {
      state.updTroubles = action.payload;
    },
    setDirectorsList: (state, action) => {
      state.directorsList = action.payload;
    },
    setIsSearchTransactionByAccount: (state, action) => {
      state.isSearchTransactionByAccount = action.payload;
    },
    setAgentsCuratorsList: (state, action) => {
      state.agentsCuratorsList = action.payload;
    },
    setFiscalSearchParams: (state, action) => {
      state.fiscalSearchParams = action.payload;
    },
    setFiscalSearchData: (state, action) => {
      state.fiscalSearchData = action.payload;
    },
  },
});

export const {
  setWiki,
  setServices,
  setTransListAuto,
  setTransList,
  setTransSearchParams,
  cleanTransListAuto,
  setActiveSection,
  setClientData,
  setTermList,
  setPersonInfo,
  clearPersonInfo,
  setPostList,
  setPostListAuto,
  setOnepayList,
  setOnepayListAuto,
  setOnepayParams,
  saveSearchedPersonReqData,
  setUpdateTroubles,
  setDirectorsList,
  setIsSearchTransactionByAccount,
  setAgentsCuratorsList,
  setFiscalSearchParams,
  setFiscalSearchData,
} = mainSlice.actions;

export default mainSlice.reducer;
