import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

import TextField from 'components/ui/TextField';
import Button from 'components/ui/Button';
import DatePicker from 'components/ui/DatePicker';
import { SearchTransactions } from 'functions/kassa24';
import { setIsSearchTransactionByAccount } from 'features/mainSlice';

import ServicesSelect from './servicesSelect';

const PAYMENT_STATUSES = [
  { value: '', label: 'Все' },
  { value: '0', label: 'Проведенные' },
  { value: '2', label: 'Не проведенные' },
  { value: '1', label: 'Помеченные на отмену' },
];

const SearchTransaction = () => {
  const dispatch = useDispatch();
  const transParams = useSelector((state) => state.main.transParams);
  const params = Object.assign({}, transParams);
  const [paramTerminal, setParamTerminal] = useState(params.Terminal || '');
  const [paramTrans, setParamTrans] = useState(params.Transaction);
  const [paramNumber, setParamNumber] = useState(params.Number);
  const [paramCount, setParamCount] = useState(params.Limit);
  const [dateFrom, setDateFrom] = useState(
    new Date(moment().subtract(3, 'days').format('YYYY-MM-DD 00:00:00'))
  );
  const [dateTo, setDateTo] = useState(new Date());
  const [status, setStatus] = useState(params.Status);
  const [service, setService] = useState(params.Service);

  // поиск транзакций
  const searchTrans = () => {
    let data = params;
    data.Terminal = Number(paramTerminal);
    data.Number = paramNumber;
    data.Transaction = paramTrans;
    data.Limit = paramCount;
    data.Date_from = moment(dateFrom).format('YYYY-MM-DD HH:mm:ss');
    data.Date_to = moment(dateTo).format('YYYY-MM-DD HH:mm:ss');
    data.Status = status;
    data.Service = Number(service);

    SearchTransactions(false, [{ ...data, IDPayments: '' }], true, dispatch);
    if (paramNumber > 0) {
      dispatch(setIsSearchTransactionByAccount(true));
    } else {
      dispatch(setIsSearchTransactionByAccount(false));
    }
  };

  const FORM_FIELDS = [
    {
      value: paramNumber,
      label: 'Номер счета',
      onInput: setParamNumber,
      type: 'text',
      name: 'Number',
    },
    {
      value: paramTrans,
      label: 'Транзакция',
      onInput: setParamTrans,
      type: 'text',
      name: 'Transaction',
    },
    {
      label: '',
      value: dateFrom,
      onInput: setDateFrom,
      type: 'date',
      name: 'dateFrom',
    },
    {
      label: '',
      value: dateTo,
      onInput: setDateTo,
      type: 'date',
      name: 'dateTo',
    },
    {
      value: paramTerminal,
      label: 'Терминал',
      onInput: setParamTerminal,
      type: 'text',
      name: 'Terminal',
    },
    {
      value: paramCount,
      label: 'Количество',
      onInput: setParamCount,
      type: 'number',
      name: 'Limit',
    },
  ];

  return (
    <>
      <ServicesSelect setData={setService} />
      <div className="formFields">
        {FORM_FIELDS.map((item) => (
          <div key={item.name}>
            {item.type === 'date' ? (
              <DatePicker selected={item.value} onChange={item.onInput} />
            ) : (
              <TextField
                value={item.value}
                name={item.name}
                onChange={(e) =>
                  item.onInput(
                    item.type === 'number'
                      ? Number(e.target.value)
                      : e.target.value
                  )
                }
                label={item.label}
                type={item.type}
                fullWidth
                variant="filled"
              />
            )}
          </div>
        ))}

        <div className="customSelect">
          <FormControl variant="filled">
            <InputLabel id="demo-simple-select-outlined-label">
              Статус платежа
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              variant="filled"
              value={status}
              type="select"
              label="Статус платежа"
              onChange={(e) => setStatus(e.target.value)}
            >
              {PAYMENT_STATUSES.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <Button color="primary" onClick={searchTrans} className="noMarg">
          Найти
        </Button>
      </div>
    </>
  );
};

export default SearchTransaction;
