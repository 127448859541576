import React, { useCallback } from 'react';
import SearchForm from './searchForm';
import TableCustom from 'components/ui/Table';
import useStyles from '../useStyles';
import moment from 'moment';
import { useSelector } from 'react-redux';

const ITEM_COUNT_ON_PAGE = 30;
const TABLE_HEADER = [
  { label: 'Время', field: 'Timestamp', width: '200px' },
  { label: 'Ккм', field: 'Kkm', width: '200px' },
  { label: 'Информация платежа', field: 'payInfo' },
  { label: 'Ответ', field: 'payResult' },
];

const UserFiscalOperations = () => {
  const classes = useStyles();
  const { fiscalSearchData } = useSelector((state) => state.main);

  const getCommissionValue = useCallback((payInfo) => {
    if (payInfo.Positions?.[1]) {
      return payInfo.Positions?.[1]['Price'];
    }

    if (payInfo?.Documents[0]?.Positions?.[1]) {
      return payInfo?.Documents[0]?.Positions?.[1]['Price'];
    }
    return 0;
  }, []);

  const getTableRows = () => {
    let rows = [];
    if (fiscalSearchData !== 'undefined') {
      fiscalSearchData.forEach((log) => {
        const logSource = log._source;
        const payInfo = JSON.parse(logSource.receiveMessage);

        let payResult = '';
        try {
          payResult = JSON.parse(logSource.sendMessage);
        } catch {
          const payResDataStatusPos = logSource.sendMessage.indexOf('"Status');
          const payResDataStatus = logSource.sendMessage.slice(
            payResDataStatusPos + 10,
            payResDataStatusPos + 13
          );
          const payResDataMessagePos =
            logSource.sendMessage.indexOf('"Message');
          const payResDataPos = logSource.sendMessage.indexOf('Data');
          const payResDataMessage = logSource.sendMessage
            .slice(payResDataMessagePos + 12, payResDataPos - 5)
            .replace(/"/g, '');

          payResult = {
            Status: Number(payResDataStatus),
            Message: payResDataMessage,
          };
        }
        rows.push({
          Timestamp: moment(logSource.timestamp).format('DD.MM.YYYY HH:mm:ss'),
          Kkm: <div className={classes.tdMinWidth}>{logSource.kkm}</div>,
          payInfo: (
            <div>
              <p>Номер транзакции: {payInfo.Sn || payInfo?.Documents[0].SN}</p>
              <p>
                Сумма платежа:{' '}
                {payInfo.Positions?.[0]['Price'] ||
                  payInfo?.Documents[0].Positions?.[0]['Price']}
              </p>
              <p>Комиссия: {getCommissionValue(payInfo)}</p>
            </div>
          ),
          payResult: (
            <div>
              {payResult['Status'] === 200 ? (
                <span className="styleSuccess">Успешен</span>
              ) : (
                <span className="styleErr">Ошибка</span>
              )}
              <p>Статус: {payResult['Status']}</p>
              <p>Сообщение: {payResult.Message}</p>
            </div>
          ),
        });
      });
    }
    return rows;
  };

  return (
    <>
      <SearchForm />
      <h3>Транзакции</h3>
      {fiscalSearchData.length ? (
        <div className={classes.table}>
          <TableCustom
            head={TABLE_HEADER}
            columns={getTableRows()}
            showPaging={true}
            pageSize={ITEM_COUNT_ON_PAGE}
          />
        </div>
      ) : (
        'Ничего не найдено'
      )}
    </>
  );
};
export default UserFiscalOperations;
