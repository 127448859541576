import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { getObjectElement } from 'functions/helpers';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const SearchTypeSelect = ({ options, searchType, setSearchType }) => {
  const handleSetSearchType = useCallback(
    (e) => {
      setSearchType(Number(e.currentTarget.dataset.index));
    },
    [setSearchType]
  );

  return (
    <div className="customSelect">
      <FormControl variant="filled">
        <InputLabel id="demo-simple-select-outlined-label">
          Тип поиска
        </InputLabel>
        <Select
          required
          variant="filled"
          label="Выберите тип поиска"
          defaultValue={getObjectElement(options, Number(searchType)).id}
          onChange={handleSetSearchType}
        >
          {options.map((option, i) => {
            return (
              <MenuItem key={option.id} value={option.id} data-index={i}>
                {option.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

SearchTypeSelect.propTypes = {
  options: PropTypes.array.isRequired,
  searchType: PropTypes.string.isRequired,
  setSearchType: PropTypes.func.isRequired,
};

export default SearchTypeSelect;
