import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import InputMask from 'components/ui/InputMaskCustom';
import Button from 'components/ui/Button';
import { getObjectElement } from 'functions/helpers';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ActivationModalBody = ({
  formik,
  isItUserActivation,
  showProgress,
  files,
  fields,
  setFilesState,
}) => {
  const classes = useStyles();

  const setFiles = (e) => {
    const fileTarget = e.target.files;
    const file = fileTarget[0];
    const name = e.target.name;

    setFilesState({
      ...files,
      [name]: file,
    });

    formik.handleChange(e);
  };

  return (
    <Grid
      spacing={isItUserActivation ? 1 : 5}
      container
      justifyContent="center"
      flexDirection="column"
    >
      <Grid
        container
        item
        sm={isItUserActivation ? 12 : 5}
        xs={isItUserActivation ? 12 : 5}
      >
        <Backdrop className={classes.backdrop} open={showProgress}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="stepTitle">
          Шаг 1: Заполните данные пользователя для создания задачи в Bitrix
        </div>
        {fields.map(({ name, label, mask, type }) => {
          const touched = getObjectElement(formik.touched, name);
          const errors = getObjectElement(formik.errors, name);

          return (
            type === 'mask' && (
              <Grid item={true} sm={12} key={name}>
                <div className="customInp customInpMask">
                  <InputMask
                    type="text"
                    name={name}
                    mask={mask}
                    placeholder={label}
                    defaultValue={getObjectElement(formik.values, name)}
                    styles={{ border: '1px solid #e1e4e8' }}
                    className={touched && Boolean(errors) ? 'emptyInput' : ''}
                    callback={formik.handleChange}
                  />
                </div>

                {touched && errors && <div className="styleErr">{errors} </div>}
              </Grid>
            )
          );
        })}
      </Grid>

      <Grid
        container
        item
        sm={isItUserActivation ? 12 : 6}
        xs={isItUserActivation ? 12 : 6}
      >
        <div className="stepTitle">
          Шаг 2: Прикрепите заявление, фото с удостоверением личности,
          удостоверение личности с двух сторон
        </div>
        <div className="filesGrid" style={{ border: '1px dashed #000' }}>
          {fields.map((field, i) => {
            const touched = getObjectElement(formik.touched, field.name);
            const errors = getObjectElement(formik.errors, field.name);

            return (
              field.type === 'file' && (
                <div className="gridHalf" key={field.name}>
                  <div className={errors ? 'styleErr' : ''}>{field.label}</div>
                  <div>
                    <TextField
                      className={errors ? 'emptyInput' : ''}
                      type="file"
                      name={field.name}
                      placeholder={field.label}
                      onChange={setFiles}
                      error={touched && Boolean(errors)}
                      helperText={touched && errors}
                    />
                  </div>
                </div>
              )
            );
          })}
        </div>
      </Grid>
      {!isItUserActivation && (
        <Grid item sm={11} xs={11}>
          <Divider />
          <Button color="primary" type="submit">
            Поставить задачу
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

ActivationModalBody.propTypes = {
  formik: PropTypes.shape().isRequired,
  files: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setFilesState: PropTypes.func.isRequired,
  showProgress: PropTypes.bool.isRequired,
  isItUserActivation: PropTypes.bool.isRequired,
};

export default ActivationModalBody;
