import React from 'react';

import { convertAmount } from 'functions/helpers';

export const getOperationTitle = ({
  IsSatty,
  Description,
  RecipientCellPhone = '-',
}) => {
  let operationTitle = Description;

  if (Description === 'Пополнение' && IsSatty) {
    operationTitle = 'Пополнение с СЖ';
  }

  if (RecipientCellPhone && Description === 'Платеж') {
    operationTitle = (
      <div>
        Перевод средств на кошелек. <br />
        Номер телефона: {RecipientCellPhone}
        . <br />
      </div>
    );
  }

  return operationTitle;
};

export const getCommissionValue = (commission) =>
  !commission ? '0.00' : convertAmount(commission);
