import React, { useEffect, useState } from 'react';
import Api from 'api/Api';
import swal from 'sweetalert';
import { setPersonInfo, saveSearchedPersonReqData } from 'features/mainSlice';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import Button from 'components/ui/Button';
import InputMask from 'components/ui/InputMaskCustom';
import { getObjectElement } from 'functions/helpers';
import SearchTypeSelect from 'components/ui/SearchTypeSelect';

const searchOptions = [
  {
    title: 'По номеру телефона',
    mask: '8 (999) 999 99 99',
    id: 'phoneNumber',
  },
  { title: 'По номеру счета', mask: '9999999999', id: 'accountNumber' },
  {
    title: 'По email',
    mask: '********************************',
    id: 'email',
  },
  { title: 'По ИИН', mask: '999999999999', id: 'iin' },
  {
    title: 'По ФИО (часто не заполнено)',
    mask: 'a'.repeat(80),
    id: 'fio',
  },
];

const SearchPerson = () => {
  const dispatch = useDispatch();
  const inputPropsDef = {
    type: 'tel',
    id: null,
    mask: 'WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW',
    placeholder: 'Выберите способ поиска',
  };
  const [searchType, setSearchType] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [email, setEmail] = useState('');
  const [iin, setIin] = useState('');
  const [fio, setFio] = useState('');
  const [inputProps, setInputProps] = useState(inputPropsDef);

  const clearField = (field, value) => {
    if (field === 'phoneNumber') {
      return value
        .replace('(', '')
        .replace(')', '')
        .replace('8', '')
        .replace(/\s+/g, '');
    }

    return value.trim();
  };

  const getStateValue = (id) => {
    switch (id) {
      case 'phoneNumber':
        return phoneNumber;
      case 'accountNumber':
        return accountNumber;
      case 'email':
        return email;
      case 'iin':
        return iin;
      case 'fio':
        return fio;
      default:
        return '';
    }
  };

  const searchPerson = () => {
    const { id } = getObjectElement(searchOptions, Number(searchType));
    const value = getStateValue(id);

    if (value !== '') {
      const data = {
        searchType,
        value: value,
      };

      Api.searchPerson(data)
        .then((response) => {
          if (response !== null && typeof response !== 'undefined') {
            dispatch(saveSearchedPersonReqData(data));
            dispatch(setPersonInfo(response));
          } else {
            swal('', 'Пользователь не найден', 'warning');
          }
        })
        .catch(() =>
          swal('Ошибка!', 'Не удалось получить информацию о клиенте', 'error')
        );
    } else {
      swal('Ошибка!', 'Поле не заполнено!', 'error');
    }
  };

  const updateSearhParam = (e) => {
    switch (e.target.id) {
      case 'phoneNumber':
        setPhoneNumber(clearField(e.target.id, e.target.value));
        break;
      case 'accountNumber':
        setAccountNumber(clearField(e.target.id, e.target.value));
        break;
      case 'email':
        setEmail(clearField(e.target.id, e.target.value));
        break;
      case 'iin':
        setIin(clearField(e.target.id, e.target.value));
        break;
      case 'fio':
        setFio(clearField(e.target.id, e.target.value));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const inpProps = {};
    const searchInfo = getObjectElement(searchOptions, Number(searchType));

    if (searchInfo.id === 'fio' && searchInfo.id === 'email') {
      inpProps.type = 'text';
    }

    inpProps.mask = searchInfo.mask;
    inpProps.id = searchInfo.id;
    inpProps.placeholder = `Поиск ${searchInfo.title.toLowerCase()}`;

    if (searchType === 4) {
      inpProps.placeholder = 'Фамилия Имя Отчество (порядок важен)';
    }

    setInputProps(inpProps);
  }, [searchType]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3} align="top">
        <SearchTypeSelect
          options={searchOptions}
          searchType={searchType}
          setSearchType={setSearchType}
        />
      </Grid>
      <Grid item xs={12} sm={6} align="top">
        <div className="customInp">
          <InputMask {...inputProps} name="name" callback={updateSearhParam} />
        </div>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button
          color="primary"
          onClick={searchPerson}
          className="noMarg fullWidth"
        >
          Найти
        </Button>
      </Grid>
    </Grid>
  );
};
export default SearchPerson;
