import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Grid,
  Card,
  CardActions,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TextField from 'components/ui/TextField';
import Button from 'components/ui/Button';
import Api from 'api/Api';
import Editor from './Editor';
import { getObjectElement, getParsedHtmlContent } from 'functions/helpers';

const EditFaq = ({ getWikiHandler }) => {
  const wiki = useSelector((state) => state.main.wiki);
  const [section, setSection] = useState('');
  const [wikiTypes, setWikiTypes] = useState([]);
  const [editedWikiData, setEditedWikiData] = useState({});
  const [editedTitle, setEditedTitle] = useState('');
  const [editedText, setEditedText] = useState('');
  const [isItCreateRow, setIsItCreateRow] = useState(false);

  useEffect(() => {
    getWikiHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const types = Object.keys(wiki);

    if (types.length) {
      setWikiTypes(types);

      if (!section) {
        setSection(types[0]);
      }
    }
  }, [wiki, section]);

  const editWiki = (data) => {
    setEditedWikiData(data);
    setEditedTitle(data.title);
    setEditedText(data.text);
  };

  const updateWikiData = () => {
    if (editedTitle && editedText) {
      const form = {
        id: '0',
        data: {
          ...editedWikiData,
          wikiType: section,
          title: editedTitle,
          text: editedText,
        },
      };

      if (!isItCreateRow) {
        form.id = editedWikiData.ID;
      }

      Api.saveWiki(JSON.stringify(form), 'save').then((response) => {
        if (response && response.data === 'ok') {
          swal({
            title: 'OK!',
            text: `Информация успешно ${
              isItCreateRow ? 'сохранена' : 'обновлена'
            }`,
            icon: 'success',
          });
        }
      });

      setTimeout(() => getWikiHandler(), 200);
      setEditedWikiData({});
      setIsItCreateRow(false);
    } else {
      swal('Ошибка!', 'Заполните все поля', 'error');
    }
  };

  const removeRow = (id, title) => {
    swal({
      title: 'Удаление записи',
      text: `Вы уверены что хотите удалить запись ${title}?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        if (id) {
          swal({
            title: 'OK!',
            text: `Запись успешно удалена`,
            icon: 'success',
          });

          Api.saveWiki(JSON.stringify({ id }), 'remove').then((response) => {
            console.log(response);
          });

          getWikiHandler();
        }
      }
    });
  };

  if (typeof editedWikiData.ID !== 'undefined' || isItCreateRow) {
    return (
      <Grid style={{ padding: 0 }} container>
        <Grid container>
          <Grid item sm={4} xs={4}>
            <Button
              color="secondary"
              onClick={() => {
                setEditedWikiData({});
                setIsItCreateRow(false);
              }}
            >
              Вернуться назад
            </Button>{' '}
            <Button color="primary" onClick={updateWikiData}>
              Сохранить
            </Button>
          </Grid>
        </Grid>
        <Grid style={{ padding: '30px 0' }} container>
          <Grid item sm={12} xs={12} style={{ padding: '10px 0' }}>
            <TextField
              onChange={(e) => setEditedTitle(e.target.value.trim())}
              defaultValue={editedTitle}
              required
              fullWidth
              label="Заголовок"
              autoComplete="off"
              variant="filled"
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Editor data={editedText} onChange={setEditedText} />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid container>
        <Grid item sm={3} xs={3} className="customSelect">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Выберите раздел
            </InputLabel>
            <Select
              variant="filled"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={section}
              defaultValue={wikiTypes[0]}
              onChange={(e) => setSection(e.target.value)}
            >
              {wikiTypes.map((wikiType) => (
                <MenuItem value={wikiType} key={wikiType}>
                  {wikiType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4} xs={4}>
          <Button
            color="primary"
            onClick={() => setIsItCreateRow(true)}
            style={{ margin: '0 20px' }}
          >
            Добавить запись
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <Grid item sm={12} xs={12} align="bottom" style={{ padding: '20px 0' }}>
          {getObjectElement(wiki, String(section)) &&
          getObjectElement(wiki, String(section)).length ? (
            getObjectElement(wiki, String(section)).map((data) => (
              <Card key={data.ID} style={{ margin: '10px 0' }}>
                <CardActions>
                  <div style={{ padding: '1rem' }}>
                    <Typography gutterBottom variant="h6" component="h2">
                      {data.title}
                    </Typography>
                    <Typography variant="body1">
                      {getParsedHtmlContent(data.text)}
                    </Typography>
                  </div>
                </CardActions>
                <CardActions>
                  <IconButton
                    aria-label="Удалить"
                    onClick={() => removeRow(data.ID, data.title)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Редактировать"
                    onClick={() => editWiki(data)}
                  >
                    <EditIcon />
                  </IconButton>
                </CardActions>
              </Card>
            ))
          ) : (
            <Typography variant="body1">
              В этом разделе пока нет записей
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

EditFaq.propTypes = {
  getWikiHandler: PropTypes.func.isRequired,
};

export default EditFaq;
