import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import { getFromLocalStorage } from 'functions/helpers';

import AuthImage from 'assets/images/phone.jpg';
import { goToMainPage } from 'functions/route';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${AuthImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  progress: {
    width: '100%',
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const showProgress = useSelector((state) => state.ui.showProgress);

  useEffect(() => {
    const token = getFromLocalStorage('token');

    if (token && window.location.pathname === '/') {
      goToMainPage();
    }
    // eslint-disable-next-line
}, []);


  return (
    <>
      {showProgress && <LinearProgress className={classes.progress} />}
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default LoginLayout;
