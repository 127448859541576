import axios from 'axios';
import XMLWriter from 'xml-writer';
import md5 from 'md5';
import { getFromLocalStorage, getObjectElement } from 'functions/helpers';
import { ELMA_USERNAME, ELMA_PASSWORD } from 'constants/main';

class ApiHelper {
  static createElmaRequest(token, data) {
    let xw = new XMLWriter();
    xw.startDocument('1.0', 'utf-8');
    xw.startElement('soap:Envelope');
    xw.writeAttribute('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance');
    xw.writeAttribute('xmlns:xsd', 'http://www.w3.org/2001/XMLSchema');
    xw.writeAttribute(
      'xmlns:soap',
      'http://schemas.xmlsoap.org/soap/envelope/'
    );

    xw.startElement('soap:Body');
    xw.startElement('Run');
    xw.writeAttribute('xmlns', 'https://www.elma-bpm.ru/WFPWebService/');

    xw.writeElement('userName', ELMA_USERNAME);
    xw.writeElement('password', ELMA_PASSWORD);
    xw.writeElement('token', token);

    xw.startElement('data');
    xw.startElement('Items');

    for (let i in data) {
      xw.startElement('WebDataItem');
      xw.writeElement('Name', i);
      xw.writeElement('Value', getObjectElement(data, String(i)));
      xw.endElement('WebDataItem');
    }

    return xw.toString();
  }

  static createMyPostRequest(data, type) {
    let xw = new XMLWriter();
    xw.startDocument('1.0', 'UTF-8');
    xw.startElement('Request');
    xw.writeElement('Type', type);
    xw.writeElement('User', 'K24CallCenter');
    xw.writeElement('Psw', md5('B9rjsi1FGdM0P6H'));

    for (let i in data) {
      xw.writeElement(i, getObjectElement(data, String(i)));
    }
    return xw.toString();
  }

  static get(url, data) {
    return this.getRequest(url, data, 'get');
  }

  static post(url, data) {
    return this.getRequest(url, data, 'post');
  }

  static getRequest = (url, data, type) => {
    let reqBody = {
      method: type,
      url,
      withCredentials: true,
      crossDomain: true,
      headers: {
        authorization: 'Bearer ' + getFromLocalStorage('token'),
      },
    };

    if (type === 'post') reqBody.data = data;
    else if (type === 'get') reqBody.params = data;

    return axios(reqBody)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(`helper getRequest error`, error);
      });
  };
}

export default ApiHelper;
