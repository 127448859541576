import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Api from 'api/Api';
import { useSelector, useDispatch } from 'react-redux';
import { setUpdateTroubles } from 'features/mainSlice';

const Timer = ({ setLink }) => {
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const updTroubles = useSelector((state) => state.main.updTroubles);

  useEffect(() => {
    const getReport = () => {
      Api.getReport('onePayTroublesCount', JSON.stringify({})).then(
        (response) => {
          dispatch(setUpdateTroubles(false));
          if (response !== undefined) {
            setCount(response.count);
          }
        }
      );
    };
    if (updTroubles) {
      getReport();
    }
  }, [updTroubles, dispatch]);

  return (
    <button
      type="button"
      className={`headLinkTimer ${
        count > 0 ? 'headLinkTimerWithTroubles' : 'headLinkTimerWithoutTroubles'
      }`}
      onClick={setLink}
    >
      {count || '!'}
    </button>
  );
};

Timer.propTypes = {
  setLink: PropTypes.func.isRequired,
};

export default Timer;
