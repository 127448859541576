import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';

const Editor = ({ data, onChange }) => {
  const editor = useRef(null);

  return (
    <JoditEditor
      ref={editor}
      value={data || ''}
      tabIndex={0}
      onBlur={(newContent) => onChange(newContent)}
    />
  );
};

Editor.propTypes = {
  data: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Editor;
