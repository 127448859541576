import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'features/userSlice';
import uiReducer from 'features/uiSlice';
import mainReducer from 'features/mainSlice';
import AxiosInterceptor from 'axios/interceptor';

const store = configureStore({
  reducer: {
    user: userReducer,
    ui: uiReducer,
    main: mainReducer,
  },
});

AxiosInterceptor(store);

export default store;
