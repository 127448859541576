import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';

import { getObjectElement } from 'functions/helpers';

const SearchForm = (props) => {
  const { searchAgentsCuratorsList } = props;
  const [searchType, setSearchType] = useState(0);
  const [searchText, setSearchText] = useState('');
  const { agentsCuratorsList } = useSelector((state) => state.main);

  const searchOptions = useMemo(() => {
    return [
      { title: 'Агенты', id: 'agent' },
      {
        title: 'Кураторы',
        id: 'curator',
      },
    ];
  }, []);

  const searchAgentsCuratorsListHandler = useCallback(
    (searchQuery, searchTypeParam) => {
      let searchResult = [];

      if (searchQuery.length >= 3) {
        if (searchTypeParam === 0) {
          const searchQueryEdited = searchQuery.toLowerCase();
          searchResult = agentsCuratorsList.filter(
            (user) =>
              String(user.IDAgents).includes(searchQueryEdited) ||
              user.AgentName.toLowerCase().includes(searchQueryEdited)
          );
        } else if (searchTypeParam === 1) {
          const searchQueryEdited = searchQuery.toLowerCase();
          searchResult = agentsCuratorsList.filter(
            (user) =>
              user.CuratorLogin.toLowerCase().includes(searchQueryEdited) ||
              user.FullName.toLowerCase().includes(searchQueryEdited)
          );
        }
      } else {
        searchResult = agentsCuratorsList;
      }

      searchAgentsCuratorsList(searchQuery, searchResult);
    },
    [searchAgentsCuratorsList, agentsCuratorsList]
  );

  const setSearchTypeHandler = useCallback(
    (type) => {
      setSearchType(type);
      if (searchText) {
        searchAgentsCuratorsListHandler(searchText, type);
      }
    },
    [searchText, searchAgentsCuratorsListHandler]
  );

  const setSearchQuery = useCallback(
    (e) => {
      const searchQuery = e.target.value;
      setSearchText(searchQuery);
      searchAgentsCuratorsListHandler(searchQuery, searchType);
    },
    [searchAgentsCuratorsListHandler, searchType]
  );

  return (
    <div style={{ marginTop: 30 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} align="top">
          <div className="customSelect">
            <FormControl variant="filled">
              <InputLabel id="demo-simple-select-outlined-label">
                Тип поиска
              </InputLabel>
              <Select
                required
                variant="filled"
                label="Выберите тип поиска"
                defaultValue={
                  getObjectElement(searchOptions, Number(searchType)).id
                }
                onChange={(e) => {
                  setSearchTypeHandler(Number(e.currentTarget.dataset.index));
                }}
              >
                {searchOptions.map((option, i) => {
                  return (
                    <MenuItem key={option.id} value={option.id} data-index={i}>
                      {option.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={8} align="top">
          <div className="customInp customInpT">
            <TextField
              name="searchQuery"
              onChange={setSearchQuery}
              label={
                searchType === 0
                  ? 'Введите номер или имя агента'
                  : 'Введите логин или имя куратора'
              }
              type="text"
              fullWidth
              variant="filled"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SearchForm.propTypes = {
  searchAgentsCuratorsList: PropTypes.func.isRequired,
};

export default SearchForm;
