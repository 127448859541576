import React, { useState, useEffect } from 'react';
import Api from 'api/Api';
import Button from 'components/ui/Button';
import { Grid, Typography } from '@material-ui/core';

import TableCustom from 'components/ui/Table';
import { exportReport } from 'functions/helpers';
import ReportSearch from 'components/ReportSearch';

const tableHead = [
  { field: 'Id', label: 'Номер провайдера IDProviders' },
  { field: 'Name', label: ' Наименование провайдера Name (ActivatorClass)' },
  { field: 'Balance', label: 'Доступные средства Balans' },
];

const ReportProvider = () => {
  const [report, setReports] = useState([]);
  const [tableData, setTableCols] = useState([]);
  const [filteredReport, setFilteredReport] = useState([]);

  useEffect(() => {
    getApiReports();
  }, []);

  useEffect(() => {
    let columns = [];
    filteredReport.map((item) => {
      return columns.push({
        Id: item.Id,
        Name: item.Name,
        Balance: format(item.Balance),
      });
    });

    setTableCols(columns);
  }, [filteredReport]);

  const getApiReports = () => {
    Api.getReport('prov').then((response) => {
      if (response !== undefined) {
        setReports(response);
        setFilteredReport(response);
      }
    });
  };

  const format = (num) => {
    let nums = num.split('.');
    let number = 1 * nums[0];
    return number.toLocaleString('ru');
  };

  const onReportSearch = (searchedData) => {
    setFilteredReport(searchedData);
  };

  return (
    <div>
      <Grid container spacing={3} alignItems="center">
        <Grid item sm={6} xs={12}>
          <Typography variant="h5">Отчет: провайдеры, баланс</Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <ReportSearch
            placeholder="Поиск по названию"
            onReportSearch={onReportSearch}
            fieldsSearch={['Name', 'Id']}
            itemsList={report}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Button
            color="primary"
            fullWidth
            onClick={() => exportReport(filteredReport, 'prov')}
            disabled={!filteredReport.length}
          >
            Экспорт в Excel
          </Button>
        </Grid>
      </Grid>

      {filteredReport.length > 0 && (
        <div className="searchResult">
          <TableCustom
            head={tableHead}
            columns={tableData}
            pageSize={30}
            showPaging={true}
          />
        </div>
      )}
      {report.length > 0 &&
        filteredReport.length === 0 &&
        'Ничего не найдено. Попробуйте изменить параметры запроса и повторить поиск.'}
    </div>
  );
};

export default ReportProvider;
