import {
  USER_ADMIN,
  USER_AUDITOR,
  USER_B2B,
  USER_GUEST,
  USER_MANAGER,
  USER_OPERATOR,
  USER_SUPERVISOR,
  USER_OPERATOR_ADVANCED,
} from 'constants/user';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useUser = () => {
  const [userRoles, setUserRoles] = useState([]);
  const userInfo = useSelector((state) => state.user.userInfo || {});

  useEffect(() => {
    const roles = [];
    if (userInfo.isAdmin) {
      roles.push(USER_ADMIN);
    }

    if (userInfo.isAuditor) {
      roles.push(USER_AUDITOR);
    }

    if (userInfo.isB2b) {
      roles.push(USER_B2B);
    }

    if (userInfo.isGuest) {
      roles.push(USER_GUEST);
    }

    if (userInfo.isManager) {
      roles.push(USER_MANAGER);
    }

    if (userInfo.isOperator) {
      roles.push(USER_OPERATOR);
    }

    if (userInfo.isSupervisor) {
      roles.push(USER_SUPERVISOR);
    }

    if (userInfo.isOperatorAdvanced) {
      roles.push(USER_OPERATOR_ADVANCED);
    }

    setUserRoles(roles);
  }, [userInfo]);

  return {
    userRoles,
  };
};

export default useUser;
