import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import TabPanel from 'components/ui/TabPanel';

import { MYPOST_TASK_TYPES } from 'constants/bitrixTask';

const SELECT_DEFAULT_VALUE = MYPOST_TASK_TYPES[0].id;

const Provider = ({ sendBitrixTask, title }) => {
  const [mypostSelectValue, setMypostSelectValue] =
    useState(SELECT_DEFAULT_VALUE);

  const handleMypostSelectChange = (event) => {
    setMypostSelectValue(event.target.value);
  };

  const mypostSelectedValueLabel = useMemo(() => {
    return MYPOST_TASK_TYPES.find(({ id }) => id === mypostSelectValue)?.label;
  }, [mypostSelectValue]);

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12}>
          <Typography variant="h1" align="center">
            Bitrix: {title} : {mypostSelectedValueLabel}
          </Typography>
        </Grid>
      </Grid>
      <Grid sm={10} xs={12} className="contentCenter">
        <Grid container spacing={3}>
          <Grid sm={12} xs={12} item>
            <div className="customInp customSelect">
              <FormControl>
                <InputLabel id="demo-simple-select-outlined-label">
                  Выберите тип задачи:
                </InputLabel>
                <Select
                  defaultValue={SELECT_DEFAULT_VALUE}
                  fullWidth
                  onChange={handleMypostSelectChange}
                >
                  {MYPOST_TASK_TYPES.map(({ label, id }) => (
                    <MenuItem value={id} key={id}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {MYPOST_TASK_TYPES.map(({ id, Component }) => (
              <TabPanel index={id} value={mypostSelectValue} key={id}>
                <Component sendBitrixTask={sendBitrixTask} />
              </TabPanel>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Provider.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Provider;
