import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Button from 'components/ui/Button';
import TextField from 'components/ui/TextField';
import { getObjectElement } from 'functions/helpers';

const ReportSearch = ({
  placeholder,
  onReportSearch,
  fieldsSearch,
  itemsList,
}) => {
  const [searchText, setSearchText] = useState('');

  const getCorrectFieldValue = (item, field) => {
    const value = getObjectElement(item, field);

    return value.toLowerCase();
  };

  const reportSearchHandler = () => {
    let filterReports = itemsList;

    if (searchText !== '') {
      let resultData = [];

      fieldsSearch.forEach((field) => {
        filterReports = itemsList.filter(
          (item) =>
            getCorrectFieldValue(item, field).indexOf(
              searchText.toLowerCase()
            ) > -1
        );

        resultData = resultData.concat(resultData, filterReports);
      });

      filterReports = resultData;
    }

    onReportSearch(filterReports);
  };

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={8}>
        <TextField
          type="search"
          name="search"
          placeholder={placeholder}
          onChange={(e) => setSearchText(e.target.value)}
          variant="filled"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <Button color="primary" fullWidth onClick={reportSearchHandler}>
          Искать
        </Button>
      </Grid>
    </Grid>
  );
};

ReportSearch.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onReportSearch: PropTypes.func.isRequired,
  fieldsSearch: PropTypes.array.isRequired,
  itemsList: PropTypes.array.isRequired,
};

export default ReportSearch;
