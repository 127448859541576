import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import Button from 'components/ui/Button';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const Dropdown = ({ title, menuItems, isMobile }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [webOpenAnchorEl, setWebOpenAnchorEl] = useState(null);

  const menu = useMemo(
    () =>
      menuItems.map((item) => (
        <MenuItem key={item.id}>
          <Link
            className="headLink"
            to={item.link}
            onClick={() => setWebOpenAnchorEl(null)}
          >
            {item.name}
          </Link>
        </MenuItem>
      )),
    [menuItems]
  );

  return (
    <div>
      <Button
        onClick={
          isMobile
            ? () => setMobileOpen(!mobileOpen)
            : (e) => setWebOpenAnchorEl(e.currentTarget)
        }
        aria-haspopup="true"
        color="default"
        className="headLink dropdown"
      >
        {title}
        {Boolean(webOpenAnchorEl) || mobileOpen ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        )}
      </Button>

      {isMobile ? (
        <Collapse in={mobileOpen} className="subMenu">
          {menu}
        </Collapse>
      ) : (
        <Menu
          open={Boolean(webOpenAnchorEl)}
          onClose={() => setWebOpenAnchorEl(null)}
          anchorEl={webOpenAnchorEl}
          keepMounted
        >
          {menu}
        </Menu>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default Dropdown;
