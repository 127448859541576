import { createSlice } from '@reduxjs/toolkit';
import { clearLocalStorage } from 'functions/helpers';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: {},
    isAuth: false,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    logout: (state) => {
      clearLocalStorage();

      state.isAuth = false;
      state.userInfo = {};
    },
  },
});

export const { setUserInfo, setIsAuth, logout } = userSlice.actions;

export default userSlice.reducer;
