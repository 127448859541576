import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import moment from 'moment';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from '@material-ui/core';

import InputMask from 'components/ui/InputMaskCustom';
import DatePicker from 'components/ui/DatePicker';
import Button from 'components/ui/Button';
import Alert from 'components/ui/Alert';
import {
  getClearPhoneNumber,
  addTwoDaysBeforeAndAfterDate,
  getObjectElement,
} from 'functions/helpers';
import Api from 'api/Api';

const selectOptions = [
  { title: 'По номеру транзакции', mask: '9'.repeat(20), id: 'sn' },
  { title: 'По номеру телефона', mask: '8 (999) 999 99 99', id: 'account' },
];

const KaspiSearchForm = ({ setKaspiData }) => {
  const [account, setAccount] = useState('');
  const [sn, setSn] = useState('');
  const [dateForDatepicker, setDateForDatepicker] = useState(new Date());
  const [dateFromValue, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
  const [dateToValue, setDateTo] = useState(moment().format('YYYY-MM-DD'));
  const [searchType, setSearchType] = useState(0);
  const defaultSearchType = 0;

  useEffect(() => {
    setDate(new Date());
  }, []);

  const setDate = (dateForDP) => {
    const { dateFrom, dateTo } = addTwoDaysBeforeAndAfterDate(dateForDP);
    setDateForDatepicker(dateForDP);
    setDateFrom(dateFrom.format('YYYY-MM-DD'));
    setDateTo(dateTo.format('YYYY-MM-DD'));
  };

  const searchTrans = () => {
    if ((!searchType && !sn) || (searchType && !account)) {
      return swal(
        '',
        `${
          searchType ? 'Номер телефона' : 'Номер транзакции'
        } обязателен к заполнению`,
        'warning'
      );
    }

    if ((!searchType && sn) || (searchType && account)) {
      Api.searchKaspiTransaction({
        sn,
        account: getClearPhoneNumber(account),
        dateFrom: dateFromValue,
        dateTo: dateToValue,
      }).then((response) => {
        searchTransSuccess(response);
      });
    }
  };

  const searchTransSuccess = (response) => {
    if (typeof response !== 'undefined') {
      const { result } = response;

      if (result !== null) {
        setKaspiData(result);
      } else {
        swal('', 'Не найдено транзакций за выбранный период', 'warning');
        setKaspiData([]);
      }
    }
  };

  const handleSetSearch = (e) => {
    setSearchType(e.target.value);
    setSn('');
    setAccount('');
  };

  const handleSetData = (e) => {
    if (e.target.id === 'sn') setSn(e.target.value.trim());
    else if (e.target.id === 'account') setAccount(e.target.value.trim());
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} xs={12}>
        <Alert
          text="Поиск будет осуществляться за 6 дней, +3 и -3 дня к выбранной дате"
          type="info"
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <div className="customSelect">
          <FormControl variant="filled">
            <InputLabel id="demo-simple-select-outlined-label">
              Тип поиска
            </InputLabel>
            <Select
              label="Выберите тип поиска"
              defaultValue={defaultSearchType}
              onChange={(e) => handleSetSearch(e)}
            >
              {selectOptions.map((option, i) => {
                return (
                  <MenuItem key={option.id} value={i}>
                    {option.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item sm={3} align="bottom" xs={6}>
        <div className="customInp ">
          <InputMask
            type="tel"
            name="account"
            callback={(e) => handleSetData(e)}
            placeholder={searchType ? 'Номер телефона' : 'Номер транзакции'}
            mask={getObjectElement(selectOptions, searchType).mask}
            id={getObjectElement(selectOptions, searchType).id}
          />
        </div>
      </Grid>
      <Grid item sm={3} align="bottom" xs={6}>
        <DatePicker
          selected={dateForDatepicker}
          onChange={setDate}
          dateFormat="dd.MM.yyyy"
        />
      </Grid>
      <Grid item sm={3} align="bottom" xs={12}>
        <Button color="primary" onClick={searchTrans} className="noMarg">
          Найти транзакции
        </Button>
      </Grid>
    </Grid>
  );
};

KaspiSearchForm.propTypes = {
  setKaspiData: PropTypes.func.isRequired,
};

export default KaspiSearchForm;
