import {
  USER_AUDITOR,
  USER_B2B,
  USER_GUEST,
  USER_MANAGER,
  USER_OPERATOR,
  USER_OPERATOR_ADVANCED,
} from './user';

export const KASSA24_TABS = [
  { label: 'Поиск	транзакций', id: '1' },
  { label: 'Поиск терминалов', deniedForRoles: [USER_AUDITOR], id: '2' },
  { label: 'Поиск клиентов ЛК', id: '3' },
  { label: 'Kaspi', deniedForRoles: [USER_AUDITOR], id: '4' },
  { label: 'Cash Out', deniedForRoles: [USER_AUDITOR], id: '5' },
  { label: 'Сатты Жулдыз', deniedForRoles: [USER_AUDITOR], id: '6' },
];

export const FISCAL_TABS = [
  { label: 'Поиск транзакций', id: '1', deniedForRoles: [USER_AUDITOR] },
  {
    label: 'Логи',
    id: '2',
    deniedForRoles: [
      USER_AUDITOR,
      USER_B2B,
      USER_GUEST,
      USER_MANAGER,
      USER_OPERATOR,
      USER_OPERATOR_ADVANCED,
    ],
  },
];
