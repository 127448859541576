import React, { useCallback, useState } from 'react';
import moment from 'moment';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import FileCopy from '@material-ui/icons/FileCopy';

import TableCustom from 'components/ui/Table';
import SearchForm from './searchForm';
import useStyles from '../useStyles';
import { jsonBeautifier } from 'functions/helpers';

const tableHead = [
  { label: 'timestamp', field: 'Timestamp' },
  { label: 'kkm', field: 'Kkm' },
  { label: 'znm', field: 'Znm' },
  { label: 'sendMessage', field: 'SendMessage' },
  { label: 'receiveMessage', field: 'ReceiveMessage' },
];

const AdminFiscalOperations = () => {
  const classes = useStyles();
  const [resultTable, setResult] = useState([]);

  const itemsOnPage = 10;
  const copyText = useCallback(
    (text) => () => {
      navigator.clipboard.writeText(text);
    },
    []
  );

  const getTableRows = () => {
    let rows = [];
    if (resultTable !== 'undefined') {
      resultTable.forEach((log, key) => {
        const logSource = log._source;
        rows.push({
          Timestamp: moment(logSource.timestamp).format('HH:mm:ss DD.MM.YYYY '),
          Message: logSource.message,
          SendMessage: (
            <button
              className={classes.tdMaxWidth}
              onClick={copyText(logSource.sendMessage)}
              type="button"
            >
              <SyntaxHighlighter
                wrapLongLines={true}
                language="javascript"
                style={dark}
              >
                {logSource.sendMessage}
              </SyntaxHighlighter>
              <div className={classes.copyText}>
                <FileCopy />
              </div>
            </button>
          ),
          ReceiveMessage: (
            <button
              className={classes.tdMaxWidth}
              onClick={copyText(logSource.receiveMessage)}
            >
              <SyntaxHighlighter
                wrapLongLines={true}
                language="javascript"
                style={dark}
              >
                {jsonBeautifier(logSource.receiveMessage)}
              </SyntaxHighlighter>
              <div className={classes.copyText}>
                <FileCopy />
              </div>
            </button>
          ),
          Kkm: logSource.kkm,
          Znm: logSource.znm,
        });
      });
    }
    return rows;
  };

  return (
    <>
      <SearchForm setTableData={setResult} />
      <h3>Фискальные операции</h3>
      {resultTable.length ? (
        <div className={classes.table}>
          <TableCustom
            head={tableHead}
            columns={getTableRows()}
            showPaging={true}
            pageSize={itemsOnPage}
          />
        </div>
      ) : (
        'Ничего не найдено'
      )}
    </>
  );
};

export default AdminFiscalOperations;
