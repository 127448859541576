import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/ui/Dropdown';

const menuItems = [
  { name: 'Редактировать FAQ', link: '/wikiEdit', id: 'wikiEdit' },
];

const MenuControl = ({ isMobile }) => (
  <Dropdown title="Управление" menuItems={menuItems} isMobile={isMobile} />
);

MenuControl.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default MenuControl;
