import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from 'components/ui/Button';
import Api from 'api/Api';
import { getFromLocalStorage } from 'functions/helpers';

const ManagingSections = ({ getWikiHandler }) => {
  const wiki = useSelector((state) => state.main.wiki);
  const [wikiTypes, setWikiTypes] = useState([]);

  useEffect(() => {
    const types = Object.keys(wiki);

    if (types.length) {
      setWikiTypes(types);
    }
  }, [wiki]);

  const removeWikiType = (name) => {
    swal({
      title: 'Подтвердите действие',
      text: `Вы уверены что хотите удалить раздел ${name}?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((sure) => {
      if (sure) {
        Api.deleteWikiType({ Title: name }).then((result) => {
          if (typeof result !== 'undefined') {
            getWikiHandler();

            swal('ОК!', `Раздел успешно удален`, 'success');

            Api.log({
              logType: 'removeWikiType',
              userid: Number(getFromLocalStorage('userId')),
              data: { wikiType: name },
              timestamp: (Date.now() / 1000) | 0,
            });
          }
        });
      }
    });
  };

  const createWikiType = () => {
    swal({
      title: 'Создание раздела в FAQ',
      text: 'Укажите название раздела:',
      content: 'input',
      button: {
        text: 'Создать',
        closeModal: false,
      },
    }).then((name) => {
      if (name) {
        Api.createWikiType({ title: name })
          .then((response) => {
            if (response !== null) {
              Api.log({
                logType: 'createWikiType',
                userid: Number(getFromLocalStorage('userId')),
                data: { wikiType: name },
                timestamp: (Date.now() / 1000) | 0,
              });

              getWikiHandler();

              swal('ОК!', `Раздел ${name} успешно создан`, 'success');
            }
          })
          .catch(() => swal('Ошибка!', 'Не удалось создать раздел', 'error'));
      }
      return swal.close();
    });
  };

  return (
    <Grid style={{ padding: 0 }}>
      <Grid container>
        <Button color="primary" onClick={createWikiType}>
          Создать раздел
        </Button>
      </Grid>
      <Grid>
        <Grid item sm={4} xs={4}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontSize: 14, padding: 10, fontWeight: 600 }}
                  >
                    Название раздела
                  </TableCell>
                  <TableCell
                    style={{ fontSize: 14, padding: 10, fontWeight: 600 }}
                  >
                    Удалить
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wikiTypes.map((wikiType, i) => (
                  <TableRow key={wikiType}>
                    <TableCell style={{ fontSize: 14, padding: 10 }}>
                      {wikiType}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label="Удалить"
                        onClick={() => removeWikiType(wikiType)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

ManagingSections.propTypes = {
  getWikiHandler: PropTypes.func.isRequired,
};

export default ManagingSections;
