import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import store from 'store';
import routes from 'routes';
import history from 'appHistory';
import { SENTRY_DSN } from 'constants/main';
import { getFromLocalStorage } from 'functions/helpers';

import 'index.css';

if (!window.location.href.includes('localhost')) {
  Sentry.init({
    normalizeDepth: 10,
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  });

  Sentry.configureScope((scope) => {
    scope.setUser({
      userId: getFromLocalStorage('userId'),
      userLogin: getFromLocalStorage('login'),
      name: getFromLocalStorage('name'),
    });
  });
}

const App = () => <Provider store={store}>{routes}</Provider>;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
