import React from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'functions/helpers';
import swal from 'sweetalert';
import { MYPOST_BITRIX_TASK_TYPES } from 'constants/bitrixTask';
import BitrixTaskForm from 'components/BitrixTaskForm';

const bitixFormsFields = [
  {
    label: 'Ответственный отдел',
    name: 'responsible',
    disabled: true,
    value: 'My Post',
    req: false,
  },
  {
    label: 'Наименование сервиса (Орифлейм и т.д.)',
    name: 'serviceName',
    req: false,
  },
  { label: 'Номер заказа', name: 'orderNumber', req: false },
  { label: 'Номер телефона клиента', name: 'clientPhone', req: false },
  { label: 'Комментарии', name: 'comment', req: false, type: 'textarea' },
];

const MyPostTask = ({ sendBitrixTask, title }) => {
  const initialValues = {
    title: '',
    description: '',
    companyName: '',
    agentNumber: '',
  };

  const sendBitrixTaskFunc = ({
    serviceName,
    orderNumber,
    clientPhone,
    comment,
  }) => {
    const data = {
      serviceName,
      orderNumber,
      clientPhone,
      comment,
      userId: Number(getFromLocalStorage('userId')),
    };

    if (!serviceName && !orderNumber && !clientPhone && !comment)
      swal('Ошибка!', 'Заполните хотя бы одно поле', 'error');
    else sendBitrixTask(data, MYPOST_BITRIX_TASK_TYPES.OTHER);
  };

  return (
    <BitrixTaskForm
      fields={bitixFormsFields}
      initialState={initialValues}
      sendBitrixTask={sendBitrixTaskFunc}
    />
  );
};

MyPostTask.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default MyPostTask;
