import React, { useState, useEffect } from 'react';
import { Typography, Tab, Tabs } from '@material-ui/core';

import { useLocation } from 'react-router-dom';

import { a11yProps, getObjectElement } from 'functions/helpers';
import TabPanel from 'components/ui/TabPanel';

import Api from 'api/Api';

import SnackbarCustom from 'components/ui/Snackbar';

import FaqItem from './faqItem';

let tabPanelKey = 0;
let tabKey = 0;

const Info = () => {
  const [types, setTypes] = useState([]);
  const [wiki, setWiki] = useState({});
  const [value, setValue] = useState(0);
  const [wasRequested, setWasRequested] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { search } = useLocation();
  const tabID = new URLSearchParams(search).get('tab');

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const setWikiTypes = (wikiList) => {
      const typesList = Object.keys(wikiList);

      if (!types.length) {
        setTypes(typesList);
      }
    };

    if (!Object.keys(wiki).length && !wasRequested) {
      Api.getWiki().then((wikiList) => {
        setWasRequested(true);
        setWiki(wikiList);
        setWikiTypes(wikiList);
      });
    }
  }, [wiki, types, wasRequested]);

  useEffect(() => {
    if (tabID) {
      setValue(Number(tabID));
    }
  }, [tabID]);

  return (
    <div>
      {showAlert && (
        <>
          <SnackbarCustom
            text="Ссылка скопирована"
            type="info"
            onClose={() => setShowAlert(false)}
            open={showAlert}
          />
        </>
      )}
      <Tabs
        value={value}
        onChange={handleChange}
        className="customTabs"
        scrollButtons="auto"
        variant="scrollable"
        indicatorColor="primary"
      >
        {types.map((item, i) => (
          <Tab key={tabKey++} label={item} {...a11yProps(i)} />
        ))}
      </Tabs>
      {types.map((item, i) => (
        <TabPanel value={value} index={i} key={tabPanelKey++}>
          <div className="infoResult">
            <Typography variant="h1">{item}</Typography>
            {getObjectElement(wiki, String(item)) !== undefined &&
              getObjectElement(wiki, String(item)).map((faqItem, j) => (
                <FaqItem
                  item={faqItem}
                  key={faqItem.ID}
                  requestTabID={tabID}
                  selectedTabID={value}
                  setShowAlert={setShowAlert}
                  faqTabKey={i}
                  faqKey={j}
                />
              ))}
          </div>
        </TabPanel>
      ))}
    </div>
  );
};

export default Info;
