import swal from 'sweetalert';
import React from 'react';

import Api from 'api/Api';
import {
  setTransListAuto,
  setTransList,
  setTransSearchParams,
  cleanTransListAuto,
} from 'features/mainSlice';
import {
  LIMITS,
  PERSON_STATUSES,
  AITU_PAY_OFFER_STATUSES,
  AITU_PAY_MIGRATION_STATUSES,
} from 'constants/person';

import {
  getObjectElement,
  getFromLocalStorage,
  formatMinutes,
  convertAmount,
  formatLkDate,
} from './helpers';

export const SearchTransactions = (autoList, params, setParams, dispatch) => {
  //очистить данные
  dispatch(setTransList({ transListLK: [], transListAP: [] }));
  if (autoList) {
    dispatch(cleanTransListAuto());
  }

  for (let i in params) {
    const paramsItem = getObjectElement(params, i);
    Api.getTrans(paramsItem)
      .then((response) => {
        if (response != null) {
          if (autoList) {
            dispatch(setTransListAuto(response));
          } else {
            dispatch(setTransList(response));
          }
        }
      })
      .catch();

    if (setParams) {
      dispatch(setTransSearchParams(paramsItem));
    }
  }
};

export const cancelKaspiPayment = (Sn, idPayments) => {
  swal({
    title: 'Внимание!',
    text: `Вы уверены что хотите снять платёж с проведения? \nНомер транзакции: ${Sn}`,
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  }).then((sure) => {
    if (sure) {
      const sendLog = (logType) => {
        Api.log({
          logType,
          userid: 1 * getFromLocalStorage('userId'),
          data: { sn: Sn, idPayments },
          timestamp: (Date.now() / 1000) | 0,
        });
      };

      sendLog('cancelPaymentTrying');

      Api.cancelPayment({ sn: Sn, idPayments }).then((result) => {
        if (typeof result !== 'undefined') {
          const { isCancelled } = result;

          if (isCancelled) {
            swal('ОК!', `Платеж ${Sn} снят с проведения`, 'success');
            sendLog('cancelPaymentSuccess');
          }
        }
      });
    }
  });
};

const getActionvationPersonTaskTitle = (isItUserActivation) =>
  isItUserActivation
    ? 'Активация пользователя в ЛК'
    : 'Идентификация пользователя в ЛК';

export const sendPersonActivationTaskInBitrix = (params, setShowProgress) => {
  setShowProgress(true);
  // скрываю модалку
  const { values, personInfo, props, files } = params;
  const { CellPhone, Login } = personInfo;
  const { doc1, doc2, doc3, doc4 } = files;
  const { fullname, iin } = values;
  const data = {
    iin,
    fullName: fullname,
    login: Login,
    cellPhone: CellPhone,
    userId: Number(getFromLocalStorage('userId')),
    taskTitle: getActionvationPersonTaskTitle(props.isItUserActivation),
  };

  Api.setTaskInBitrix(data, 'activateThePerson')
    .then((response) => {
      // задача создана, кидаем запрос на добавление файлов
      if (
        typeof response !== 'undefined' &&
        typeof response.taskId !== 'undefined'
      ) {
        Api.log({
          logType: props.isItUserActivation
            ? 'ActivateThePersonTask'
            : 'PersonIdentification',
          userid: 1 * getFromLocalStorage('userId'),
          data: { taskId: response.taskId, Login, CellPhone, iin, fullname },
          timestamp: (Date.now() / 1000) | 0,
        });

        const formData = new FormData();
        formData.append('doc1', doc1);
        formData.append('doc2', doc2);
        formData.append('doc3', doc3);
        formData.append('doc4', doc4);
        formData.append('taskId', response.taskId);

        Api.uploadFilesToTask(formData)
          .then((result) => {
            setShowProgress(false);
            props.setShowActivateModal(false);

            swal('ОК!', 'Задача в битриксе создана', 'success');

            if (typeof result !== 'undefined') {
              Api.log({
                logType: 'AttachFile',
                userid: 1 * getFromLocalStorage('userId'),
                data: {
                  taskId: response.taskId,
                  Login,
                  CellPhone,
                  iin,
                  fullname,
                },
                timestamp: (Date.now() / 1000) | 0,
              });
            }
          })
          .catch(() => {
            setShowProgress(false);
            props.setShowActivateModal(false);
          });
      }
    })
    .catch(() => {
      setShowProgress(false);
      props.setShowActivateModal(false);
    });
};

export const searchTransactionsSattyZhuldyz = (params, setData) => {
  const { uuid, ref } = params;
  if (!uuid && !ref) {
    return swal('', `Одно из полей обязательны к заполнению`, 'warning');
  }

  if (ref || uuid) {
    Api.searchSattyZhyldyz({
      UUID: uuid,
      Ref: ref,
    }).then((response) => setOperationsInState(response, setData));
  }
};

const setOperationsInState = (response, setData) => {
  if (typeof response !== 'undefined') {
    const { result } = response;

    if (result !== null) {
      setData(result);
    } else {
      swal(
        '',
        'Не найдено выплат по введенным данным. Возможно клиент не пробовал выводить средства',
        'warning'
      );
      setData([]);
    }
  }
};

export const sendTransactionReceiptApi = (params) => {
  const { query, ListData } = params;
  Api.sendCheck(JSON.stringify(query))
    .then((res) => {
      Api.log({
        logType: 'sendCheck',
        userid: Number(getFromLocalStorage('userId')),
        data: query,
        timestamp: (Date.now() / 1000) | 0,
      });
      if (res?.isSended) {
        swal({
          title: 'Дубликат чека отправлен!',
          text: 'Отправить сообщение технику?',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        }).then((send) => {
          if (send) {
            //формируем запрос в телеграм технику
            let queryObj = {
              idTerminal: Number(ListData.TerminalId),
              idAuthor: Number(getFromLocalStorage('userId')),
              loginAuthor: getFromLocalStorage('login'),
              description:
                'В терминале ' + ListData.TerminalId + ' закончилась бумага',
            };

            Api.troubleReport(JSON.stringify(queryObj));
          }
        });
      }
    })
    .catch((e) => {
      console.log(e);
      //чек не отправился
      swal('Ошибка! Попробуйте еще раз', e.response.data.message, 'error');
    });
};

export const showTransactionReceipt = (
  params,
  setShowModalCheck,
  setCheckImage
) => {
  const { index, list } = params;
  //берем параметры платежа по индексу из массива платежей
  let ListData = getObjectElement(list, index);
  let extraInfo = 1;
  let idservice = Number(ListData.ServiceId);

  //формируем запрос
  let query = {
    IdSysUser: Number(ListData.IDSysUser),
    SN: ListData.TransId,
    ExtraInfo: extraInfo,
    IDService: idservice,
    TerminalId: ListData.TerminalId,
  };

  Api.showCheck(query).then((res) => {
    if (res?.checkImage && res.checkImage !== '') {
      setShowModalCheck(true);
      setCheckImage(res.checkImage);
    } else {
      swal('Ошибка!', 'Чек по транзакции не найден', 'error');
    }
  });
};

export const sendTerminalReport = (params) => {
  const { id, userInfo } = params;

  let query = {
    idTerminal: 1 * id,
    idAuthor: 1 * userInfo.id,
    loginAuthor: userInfo.name,
    description: 'Терминал ' + id + ' давно не пинговался, нужно проверить',
  };

  swal({
    title: 'Вы уверены?',
    text: 'Отправить сообщение о проблемах на терминале  ' + id + ' ?',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  }).then((send) => {
    if (send) {
      Api.troubleReport(JSON.stringify(query))
        .then(() => {
          swal('OK!', 'Отправлено', 'success');
        })
        .catch();
    }
  });
};

//окрашивать строку в красный, если терминал давно не пинговался
export const getTerminalState = (date) => {
  if (date === '') {
    return true;
  }
  let d = new Date();
  let fDate = new Date(date);
  let diff = (d - fDate) / 1000 / 60;
  return diff > 5;
};

export const getTerminalTableParamValue = (paramName, paramsValue) => {
  const {
    DPing,
    WithoutLink,
    ActivePoint,
    BlockReason,
    TerminalType,
    VersionPo,
    HourWork,
  } = paramsValue;

  switch (paramName) {
    case 'dping':
      if (DPing.String !== null) {
        return (
          <span>
            {getTerminalState(DPing.String)}
            {DPing.String.substring(0, 10)}
            &nbsp;
            {DPing.String.substring(11, 19)}
            {WithoutLink.Int64 ? (
              <p>
                Терминал не выходил на связь последние{' '}
                {formatMinutes(WithoutLink.Int64)} минут
              </p>
            ) : (
              '-'
            )}
          </span>
        );
      }
      return <></>;
    case 'status':
      return ActivePoint.Bool ? (
        <b className="styleSuctext"> Активен</b>
      ) : (
        <b className="styleErr"> Заблокирован. {BlockReason.String}</b>
      );
    case 'terminalType':
      return typeof TerminalType !== 'undefined' ? TerminalType : '-';
    case 'poVersion':
      return VersionPo.Valid ? VersionPo.String : 'Не указана';
    case 'hourwork':
      return getTerminalTableParamValueHourWork(HourWork);
    default:
      return <></>;
  }
};

const getTerminalTableParamValueHourWork = (HourWork) => {
  if (typeof HourWork?.String !== 'undefined') {
    return HourWork.Valid ? HourWork.String : 'Не указан';
  } else {
    return HourWork;
  }
};

export const searchPersonApi =
  (lastSearchedData, setPersonInfo) => (dispatch) => {
    Api.searchPerson(lastSearchedData)
      .then((response) => {
        if (response !== null && typeof response !== 'undefined') {
          dispatch(setPersonInfo(response));
        } else {
          swal('', 'Пользователь не найден', 'warning');
        }
      })
      .catch(() =>
        swal('Ошибка!', 'Не удалось получить информацию о клиенте', 'error')
      );
  };

export const blockPersonApi =
  (personInfo, setPersonInfo, setChangeStatus) => (dispatch) => {
    swal({
      title: 'Блокировка пользователя в ЛК',
      text: 'Укажите причину блокировки:',
      content: 'input',
      button: {
        text: 'Заблокировать',
        closeModal: false,
      },
    }).then((reason) => {
      if (reason) {
        const { ID } = personInfo;
        if (typeof ID !== 'undefined' && ID > 0) {
          Api.blockPerson({ id: ID, reason })
            .then((response) => {
              if (response !== null) {
                dispatch(searchPersonApi(personInfo, setPersonInfo));

                Api.log({
                  logType: 'blockThePerson',
                  userid: 1 * getFromLocalStorage('userId'),
                  data: { personId: ID },
                  timestamp: (Date.now() / 1000) | 0,
                });

                setChangeStatus(PERSON_STATUSES.BLOCKED);
                swal('ОК!', 'Пользователь заблокирован', 'success');
              }
            })
            .catch(() =>
              swal('Ошибка!', 'Не удалось заблокировать пользователя', 'error')
            );
        } else {
          swal('Ошибка!', 'Не удалось распознать ID пользователя', 'error');
        }
      }
      return swal.close();
    });
  };

const getLostLimitPerMonth = ({ limit, PaymentsLimit, typeOfUser }) =>
  limit !== '-'
    ? Number(
        getObjectElement(LIMITS, String(typeOfUser)).month.replace(/\s+/g, '')
      ) - Number(PaymentsLimit.replace(/\s+/g, ''))
    : 0;

const getLostLimitForReplenishmentsPerMonth = ({
  limit,
  typeOfUser,
  ReplenishmentLimit,
}) =>
  limit !== '-'
    ? Number(
        getObjectElement(LIMITS, String(typeOfUser)).month.replace(/\s+/g, '')
      ) - Number(ReplenishmentLimit.replace(/\s+/g, ''))
    : 0;

export const getPersonPaymentsLimit = ({ type, personInfo }) => {
  const lostLimitPerMonth = getLostLimitPerMonthParam({
    personInfo,
    typeOfUser: personInfo.typeOfUser,
  });
  const limitParams = {
    limit: lostLimitPerMonth,
    typeOfUser: personInfo.typeOfUser,
    PaymentsLimit: personInfo.PaymentsLimit,
    ReplenishmentLimit: personInfo.ReplenishmentLimit,
  };

  if (type === 'payments') {
    if (personInfo.ID) return getLostLimitPerMonth(limitParams);
    return 0;
  } else if (type === 'replenish') {
    if (personInfo.ID)
      return getLostLimitForReplenishmentsPerMonth(limitParams);
    return 0;
  }
};

export const getAituPayStatus = ({ Offer, Migration }) => {
  if (Offer === AITU_PAY_OFFER_STATUSES.ACCEPTED_THE_OFFER) {
    return 'Принял оффер';
  }

  if (Migration === AITU_PAY_MIGRATION_STATUSES.SET_FOR_MIGRATION) {
    return 'Поставлен на миграцию';
  }

  if (Migration === AITU_PAY_MIGRATION_STATUSES.MIGRATED) {
    return 'Мигрировал';
  }

  return '';
};

const getLostLimitPerMonthParam = ({ personInfo, typeOfUser }) =>
  personInfo.ID
    ? getObjectElement(LIMITS, String(typeOfUser)).month.replace(/\s+/g, '')
    : '';

const getReasonForBlocking = (reason) => (reason ? `(${reason})` : '');

export const getUserStatusTitle = (userStatus, ReasonForBlocking) =>
  userStatus === PERSON_STATUSES.BLOCKED
    ? `Пользователь заблокирован ${getReasonForBlocking(ReasonForBlocking)}`
    : 'Пользователь активен';

export const showPersonInfoLimitAmount = (limit) =>
  limit > 0 ? `${convertAmount(limit)} тг` : '-';

export const getPersonInfoTableValue = (type, params) => {
  const {
    status,
    DateOfBlocking,
    IdentificationRequestDate,
    identificationRowColor,
    IdentificationProcessingDate,
    Balance,
    PaymentsLimit,
    ReplenishmentLimit,
  } = params;

  switch (type) {
    case 'status':
      return (
        status === PERSON_STATUSES.BLOCKED && (
          <div>Дата блокировки: {formatLkDate(DateOfBlocking)}</div>
        )
      );
    case 'identificationDate':
      return (
        IdentificationRequestDate && (
          <div
            className=" mdc-typography--caption"
            style={{ color: identificationRowColor }}
          >
            Дата подачи заявки на идентификацию:{' '}
            {formatLkDate(IdentificationRequestDate)}
          </div>
        )
      );
    case 'identificationProcessingDate':
      return (
        IdentificationProcessingDate && (
          <div
            className=" mdc-typography--caption"
            style={{ color: identificationRowColor }}
          >
            Дата обработки заявки на идентификацию:{' '}
            {formatLkDate(IdentificationProcessingDate)}
          </div>
        )
      );
    case 'balance':
      return Balance !== '' ? <>{convertAmount(Balance)}</> : <>0</>;
    case 'payments':
      return PaymentsLimit !== '' ? (
        <>{convertAmount(Number(PaymentsLimit))}</>
      ) : (
        <>0</>
      );
    case 'replenishmentLimit':
      return ReplenishmentLimit !== '' ? (
        <>{convertAmount(Number(ReplenishmentLimit))}</>
      ) : (
        <>0</>
      );
    default:
      return <></>;
  }
};
