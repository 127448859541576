import React from 'react';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputMask from 'components/ui/InputMaskCustom';
import Api from 'api/Api';
import Button from 'components/ui/Button';
import { setClientData } from 'features/mainSlice';
import { getClearPhoneNumber, getObjectElement } from 'functions/helpers';

const personParams = [
  { value: 'Cellphone', label: 'Номер телефона' },
  { value: 'Email', label: 'E-mail' },
];

const PersonCard = () => {
  const dispatch = useDispatch();
  const clientData = useSelector((state) => state.main.clientData);

  const saveData = () => {
    const { Email, Cellphone } = clientData;

    if (Email !== '') {
      Api.savePerson({ email: Email, Cellphone })
        .then(() => {
          swal('ОК!', 'Данные сохранены', 'success');
        })
        .catch('Ошибка', 'Не удалось сохранить данные о клиенте', 'error');
    } else {
      swal('Ошибка!', 'Email обязателен для заполнения', 'error');
    }
  };

  const setData = (e) => {
    let personData = {
      ...clientData,
      [e.target.name]:
        e.target.name === 'Cellphone'
          ? getClearPhoneNumber(e.target.value)
          : e.target.value,
    };

    dispatch(setClientData(personData));
  };

  return (
    <div>
      <Typography variant="h5">Информация о клиенте</Typography>
      <div>
        <div>
          {personParams.map(({ value, label, i }) => (
            <div key={i} className="customInp">
              <div className="inpLabel">{label}</div>
              {value === 'Cellphone' ? (
                <div key={i} className="customInp">
                  <InputMask
                    type="tel"
                    id=""
                    name="Cellphone"
                    mask="8 (999) 999 99 99"
                    placeholder=""
                    callback={setData}
                  />
                </div>
              ) : (
                <TextField
                  value={getObjectElement(clientData, String(value))}
                  name={value}
                  onInput={setData}
                  fullWidth
                />
              )}
            </div>
          ))}

          <Button
            className="mdc-theme--secondary-bg"
            onClick={saveData}
            color="primary"
            fullWidth
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PersonCard;
