import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Header from 'components/Header';
import { getFromLocalStorage } from 'functions/helpers';
import { setServices } from 'features/mainSlice';
import { setIsAuth, setUserInfo } from 'features/userSlice';
import Api from 'api/Api';
import history from 'appHistory';

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);

  useEffect(() => {
    const token = getFromLocalStorage('token');

    if (token) {
      if (!userInfo.userId) {
        Api.getCurrentUserInfo().then((response) => {
          if (response && typeof response.data !== 'undefined') {
            dispatch(setUserInfo(response.data));
            dispatch(setIsAuth(true));
          }
        });
      }

      Api.getServices()
        .then((response) => {
          if (response) {
            dispatch(setServices(response));
          }
        })
        .catch();
    } else {
      history.push('/');
    }
  }, [userInfo.userId, dispatch]);

  return (
    <div>
      <Grid container component="main" className="mainContent">
        <Grid item xs={12} elevation={6}>
          <Header />
          <div className="pageContent">{children}</div>
        </Grid>
      </Grid>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default MainLayout;
