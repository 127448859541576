import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import useStyles from './useStyles';

const CustomTextField = (props) => {
  const classes = useStyles();
  const inputProps = { ...props };

  if (props.type === 'textarea') {
    inputProps.multiline = true;
    inputProps.minRows = 4;
    inputProps.maxRows = 10;
  }

  return <TextField {...inputProps} className={classes.customInput} />;
};

CustomTextField.defaultProps = {
  type: 'text',
};

CustomTextField.propTypes = {
  type: PropTypes.string,
};

export default CustomTextField;
