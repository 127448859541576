// Screens
import Info from 'screens/Info';
import News from 'screens/News';
import ElmaTask from 'screens/Elma';
import OnePay from 'screens/OnePay';
import MyPost from 'screens/MyPost';
import Profile from 'screens/Profile';
import Kassa24 from 'screens/Kassa24';
import WikiEdit from 'screens/WikiEdit';
import BitrixTask from 'screens/Bitrix';
import Promotions from 'screens/Promotions';
import ReportElma from 'screens/Reports/ReportElma';
import ReportRepay from 'screens/Reports/ReportRepay';
import ReportProvider from 'screens/Reports/ReportProvider';
import ReportProviderStatus from 'screens/Reports/ReportProviderStatus';
import OnepayTroublesRaw from 'screens/Reports/OnepayTroublesRaw';
import OnepayTroubles from 'screens/Reports/OnepayTroubles';
import Dealers from 'screens/Reports/Dealers';
import OwnNetwork from 'screens/Reports/OwnNetwork';
import BlackList from 'screens/Reports/BlackList';
import Troubles from 'screens/Troubles';
import ChangePassword from 'screens/ChangePassword';
import { USER_AUDITOR } from './user';
import AgentsCurators from 'screens/Reports/AgentsCurators/';
import Fiscal from 'screens/Fiscal';

export const COMPONENT_WITH_MAIN = [
  {
    path: '/personal',
    component: Profile,
  },
  {
    path: '/kassa24',
    component: Kassa24,
  },
  {
    path: '/fiscal',
    component: Fiscal,
  },
  {
    path: '/mypost',
    component: MyPost,
  },
  {
    path: '/onepay',
    component: OnePay,
  },
  {
    path: '/promotions',
    component: Promotions,
  },
  {
    path: '/news',
    component: News,
  },
  {
    path: '/info',
    component: Info,
  },
  {
    path: '/elmaTask',
    component: ElmaTask,
  },
  {
    path: '/bitrixTask',
    component: BitrixTask,
  },
  {
    path: '/wikiEdit',
    component: WikiEdit,
  },
  { path: '/report/agentsCurators', component: AgentsCurators },
  {
    path: '/report/prov',
    component: ReportProvider,
  },
  {
    path: '/report/provStatus',
    component: ReportProviderStatus,
  },
  {
    path: '/report/elma',
    component: ReportElma,
  },
  {
    path: '/report/repay',
    component: ReportRepay,
  },
  {
    path: '/report/onepayTroublesRaw',
    component: OnepayTroublesRaw,
  },
  {
    path: '/report/onepayTroubles',
    component: OnepayTroubles,
  },
  {
    path: '/report/dealers',
    component: Dealers,
  },
  {
    path: '/report/ownNetwork',
    component: OwnNetwork,
  },
  {
    path: '/report/blackList',
    component: BlackList,
  },
  {
    path: '/troubles',
    component: Troubles,
  },
  {
    path: '/changePassword',
    component: ChangePassword,
  },
];

export const MAIN_MENU = [
  { name: 'Kassa24', link: '/kassa24', class: 'kassa24' },
  {
    name: 'Fiscal 2.0',
    link: '/fiscal',
    class: 'fiscal',
    deniedForRoles: [USER_AUDITOR],
  },
  {
    name: 'MyPost',
    link: '/mypost',
    class: 'mypost',
    deniedForRoles: [USER_AUDITOR],
  },
  {
    name: 'OnePay',
    link: '/onepay',
    class: 'onepay',
    deniedForRoles: [USER_AUDITOR],
  },
  {
    name: 'Акции',
    link: '/promotions',
    class: 'action',
    deniedForRoles: [USER_AUDITOR],
  },
  {
    name: 'Elma',
    link: '/elmaTask',
    class: 'elma',
    deniedForRoles: [USER_AUDITOR],
  },
  {
    name: 'Bitrix',
    link: '/bitrixTask',
    class: 'bitrix',
    deniedForRoles: [USER_AUDITOR],
  },
  {
    name: 'Новости',
    link: '/news',
    class: 'news',
    deniedForRoles: [USER_AUDITOR],
  },
  {
    name: 'FAQ',
    link: '/info',
    class: 'info',
    deniedForRoles: [USER_AUDITOR],
  },
  {
    name: '!',
    link: '/troubles',
    timer: true,
    class: 'timer',
    deniedForRoles: [USER_AUDITOR],
  },
];

export const REPORTS_MENU_DENIED_ROLES = [USER_AUDITOR];
