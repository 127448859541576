import React, { useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { DialogContentText } from '@material-ui/core';

import Modal from 'components/ui/Modal';
import Button from 'components/ui/Button';
import { getFieldsYup } from 'functions/helpers';
import { sendPersonActivationTaskInBitrix } from 'functions/kassa24';

import ActivationModalBody from './ActivationModalBody';

const fields = [
  {
    name: 'fullname',
    req: true,
    type: 'mask',
    mask: 'a'.repeat(80),
    label: 'Введите ФИО',
  },
  {
    name: 'iin',
    req: true,
    type: 'mask',
    mask: '9'.repeat(12),
    label: 'Введите ИИН',
    min: 12,
  },
  { name: 'doc1', req: true, label: 'Заявление', type: 'file' },
  {
    name: 'doc2',
    req: true,
    label: 'Фото с удостоверением личности',
    type: 'file',
  },
  {
    name: 'doc3',
    req: true,
    label: ' Удостоверение с лицевой стороны',
    type: 'file',
  },
  {
    name: 'doc4',
    req: true,
    label: ' Удостоверение с обратной стороны',
    type: 'file',
  },
];

const ActivationPerson = (props) => {
  const [showProgress, setShowProgress] = useState(false);
  const initialValues = {
    fullname: props.fullName ? props.fullName : '',
    iin: '',
    doc1: '',
    doc2: '',
    doc3: '',
    doc4: '',
  };

  const [files, setFilesState] = useState({
    doc1: '',
    doc2: '',
    doc3: '',
    doc4: '',
  });

  const personInfo = useSelector((state) => state.main.personInfo);

  const createBitrixTask = (values) => {
    const params = { values, personInfo, props, files };
    sendPersonActivationTaskInBitrix(setShowProgress, params);
  };

  const validationSchema = yup.object(getFieldsYup(fields));

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: createBitrixTask,
  });

  const activationModalBodyProps = {
    formik,
    fields,
    files,
    setFilesState,
    showProgress,
    isItUserActivation: props.isItUserActivation,
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {props.isItUserActivation ? (
          <Modal
            maxWidth="md"
            onClose={() => props.setShowActivateModal(false)}
            open={true}
            scroll="paper"
            dividers={true}
            dialogTitle="Активация пользователя (Создание задачи в Bitrix)"
            dialogActions={
              <>
                <Button
                  onClick={() => props.setShowActivateModal(false)}
                  color="secondary"
                >
                  Отмена
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  onClick={formik.handleSubmit}
                >
                  Создать задачу
                </Button>
              </>
            }
          >
            <div>
              <DialogContentText>
                <ActivationModalBody {...activationModalBodyProps} />
              </DialogContentText>
            </div>
          </Modal>
        ) : (
          <div>
            <ActivationModalBody {...activationModalBodyProps} />
          </div>
        )}
      </form>
    </>
  );
};

ActivationPerson.propTypes = {
  isItUserActivation: PropTypes.bool,
  fullName: PropTypes.string,
  setShowActivateModal: PropTypes.func,
};

export default ActivationPerson;
