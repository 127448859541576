import React from 'react';
import { useSelector } from 'react-redux';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
} from '@material-ui/core';

import {
  getTerminalTableParamValue,
  sendTerminalReport,
  getTerminalState,
} from 'functions/kassa24';

const tableHead = [
  'Номер терминала',
  'Имя',
  'Адрес',
  'Агент',
  'Последний платеж',
  'Последний пинг',
  'Информация',
  '',
];
let headKey = 0;

const TerminalsTable = () => {
  const userInfo = useSelector((state) => state.user.userInfo || {});
  const terminals = useSelector((state) => state.main.terminals);

  //отправить сообщение технику о проблемах с терминалом
  const sendReport = (id) => {
    sendTerminalReport({ userInfo, id });
  };

  return (
    <>
      {terminals && terminals.length > 0 ? (
        <div>
          <h3>Терминалы</h3>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHead.map((item) => {
                    return <TableCell key={headKey++}>{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {terminals.map(
                  ({
                    DPing,
                    IDTerminal,
                    Name,
                    HourWork,
                    AgentName,
                    AgentAddress,
                    LastPay,
                    Errors,
                    TerminalType,
                    WithoutLink,
                    ActivePoint,
                    BlockReason,
                    VersionPo,
                  }) => (
                    <TableRow
                      key={IDTerminal}
                      className={
                        getTerminalState(DPing.String) ? 'styleErrBackgr' : ''
                      }
                    >
                      <TableCell>{IDTerminal}</TableCell>
                      <TableCell>{Name}</TableCell>
                      <TableCell>
                        {getTerminalTableParamValue('hourwork', { HourWork })}
                      </TableCell>
                      <TableCell>
                        <p>{AgentName}</p>
                        <p>{AgentAddress}</p>
                      </TableCell>
                      <TableCell>
                        {LastPay.substring(0, 10)}
                        &nbsp;
                        {LastPay.substring(11, 19)}
                      </TableCell>
                      <TableCell>
                        {getTerminalTableParamValue('dping', {
                          DPing,
                          WithoutLink,
                        })}
                      </TableCell>
                      <TableCell>
                        <p>
                          Статус:
                          {getTerminalTableParamValue('status', {
                            ActivePoint,
                            BlockReason,
                          })}
                        </p>
                        {Errors?.map((err) => (
                          <p key={err.code}>
                            {err.type}:{' '}
                            <b
                              className={
                                Number(err.code) === 0
                                  ? 'styleSuctext'
                                  : 'styleErr'
                              }
                            >
                              {err.text}
                            </b>
                          </p>
                        ))}
                        <p>
                          Тип терминала :{' '}
                          <b>
                            {getTerminalTableParamValue('terminalType', {
                              TerminalType,
                            })}
                          </b>
                        </p>
                        <p>
                          Версия ПО:{' '}
                          <b>
                            {getTerminalTableParamValue('poVersion', {
                              VersionPo,
                            })}
                          </b>
                        </p>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => sendReport(IDTerminal)}>
                          <ReceiptIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <>По заданным параметрам терминалов не найдено</>
      )}
    </>
  );
};

export default TerminalsTable;
