import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { getObjectElement } from 'functions/helpers';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 8,
    color: 'white',
    height: 50,
    margin: '10px 0',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  fullWidth: {
    height: 50,
    width: '100%',
    margin: '10px 0',
  },
  primary: {
    '&:hover': {
      backgroundColor: '#00a0e9',
      boxShadow: '0 2px 8px rgba(0,0,0,.3)',
    },
    backgroundColor: '#00a0e9',
  },
  secondary: {
    '&:hover': {
      backgroundColor: '#c54d4d',
      boxShadow: '0 2px 12px rgba(0,0,0,.3)',
    },
    backgroundColor: '#ec5a5a',
  },
  default: {
    color: '#333333',
  },
  blueOutlined: {
    color: '#00a0e9',
    borderColor: '#00a0e9',
    '&:hover': {
      backgroundColor: '#00a0e9',
      boxShadow: '0 2px 8px rgba(0,0,0,.3)',
      color: '#ffffff',
    },
  },
}));

const MyButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={`
        ${classes.button} ${getObjectElement(classes, props.color)}
        ${props.fullWidth ? classes.fullWidth : ''} ${props.className}`}
    >
      {props.children}
    </Button>
  );
};

MyButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  color: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
};

MyButton.defaultProps = {
  fullWidth: false,
  size: 'medium',
};

export default MyButton;
