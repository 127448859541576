import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import Api from 'api/Api';
import PropTypes from 'prop-types';
import {
  getFromLocalStorage,
  formatAmount,
  getObjectElement,
  setObjectElement,
} from 'functions/helpers';
import Button from 'components/ui/Button';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import WorkIcon from '@material-ui/icons/Work';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
} from '@material-ui/core';

import {
  RECREATED,
  PAYMENT_STATUSES,
  BCK_SERVICES_FOR_SHOW_PHONE,
} from 'constants/main';
import Alert from 'components/ui/Alert';

import RepayModal from './repayModal';
import ShowCheckModal from './showCheckModal';
import {
  sendTransactionReceiptApi,
  showTransactionReceipt,
} from 'functions/kassa24';

const TERMINAL_FOR_LK = [27574, 165937];

const BITRIX_URL = 'https://spektr.bitrix24.kz/';

const TransactionsTable = ({ list, title }) => {
  const [repayModalIsOpened, setRepayModalIsOpened] = useState(false);
  const [repayModalData, setRepayModalData] = useState({});
  const [showModalCheck, setShowModalCheck] = useState(false);
  const [isCheckedCheckbox, setIsCheckedCheckbox] = useState(false);
  const [checkedTransactions, setCheckedTransactions] = useState([]);
  const [checkboxesStatus, setCheckboxesStatus] = useState({});
  const [checkImage, setCheckImage] = useState('');
  const clientData = useSelector((state) => state.main.clientData);
  const userInfo = useSelector((state) => state.user.userInfo || {});
  const isSearchTransactionByAccount = useSelector(
    (state) => state.main.isSearchTransactionByAccount
  );
  const transAndromedaUsers = useSelector(
    (state) => state.main.transAndromedaUsers
  );

  //отправка чека
  const sendCheck = (index) => {
    if (clientData.Email === undefined || clientData.Email === '') {
      swal('Ошибка!', 'Заполните Email', 'error');
    } else {
      //берем параметры платежа по индексу из массива платежей
      let ListData = getObjectElement(list, Number(index));
      let extraInfo = 1;
      let idservice = Number(ListData.ServiceId);

      //формируем запрос
      let query = {
        IdSysUser: Number(ListData.IDSysUser),
        SN: ListData.TransId,
        ExtraInfo: extraInfo,
        IDService: idservice,
        Email: clientData.Email.trim(),
        TerminalId: ListData.TerminalId,
      };

      swal({
        title: 'Вы уверены?',
        text: 'Клиенту будет отправлен дубликат чека на ' + query.Email,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((sure) => {
        if (sure) {
          sendTransactionReceiptApi({ query, ListData });
        }
      });
    }
  };

  const showCheck = (index) => {
    showTransactionReceipt({ index, list }, setShowModalCheck, setCheckImage);
  };

  const agentsElmaTask = (e) => {
    let index =
      e.target.dataset.index === undefined
        ? e.target.parentNode.dataset.index
        : e.target.dataset.index;
    let ListData = getObjectElement(list, index);
    let text =
      'Недостаточный баланс Дилера /' +
      ListData.AgName.String +
      ', ' +
      ListData.AgAddress.String;
    let form = {
      NekiyTekst: text,
      TipZadachi: 2,
      SrokVypolneniya: '',
    };

    swal({
      title: 'Поставить задачу?',
      text: 'Задача на ОРП. ' + text,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((send) => {
      if (send) {
        Api.sendElmaTask('b455f375-6515-4715-89a8-8c388e820afc', form)
          .then((response) => {
            if (response !== undefined) {
              swal('ОК!', 'отправлено', 'success');
              Api.log({
                logType: 'elmaTask',
                userid: Number(getFromLocalStorage('userId')),
                data: {
                  token: 'b455f375-6515-4715-89a8-8c388e820afc',
                  params: form,
                },
                timestamp: (Date.now() / 1000) | 0,
              });
            }
          })
          .catch();
      }
    });
  };

  //вызвать модалку для перепроведения платежа
  const repayModalToggle = () => setRepayModalIsOpened(!repayModalIsOpened);

  // добавить данные о платеже для перепроведения
  const showRepayModal = (index) => {
    const transactionInfo = getObjectElement(list, index);
    setRepayModalData(transactionInfo);
    repayModalToggle();
  };

  const getTotalSum = (listValue) => {
    let res = 0;
    listValue.map((trans) => (res += Number(trans.Amount)));
    return formatAmount(res);
  };

  const serviceFormat = (text, type) => {
    if (text !== '') {
      let add = '';
      switch (type) {
        case 'com':
          add = '\n Комиссия:  ';
          if (text === 'denied') {
            return add + 'Запрещена';
          } else {
            let sp = text.split('=');
            switch (sp[0]) {
              case 'maxPr':
                text = 'Максимум ' + sp[1] + '%';
                break;
              case 'max':
                text = 'Максимум ' + sp[1] + 'тг';
                break;
              case 'minPr':
                text = 'Минимум ' + sp[1] + '%';
                break;
              case 'min':
                text = 'Минимум ' + sp[1] + 'тг';
                break;
              default:
                text = '';
            }
            return add + text;
          }

        case 'reg':
          add = '\n Регион:  ';
          return add + text;
        case 'info':
          add = '\n Информация о сервисе:  ';
          return add + text;
        case 'min':
          add = '\n Минимальная сумма платежа:  ';
          return add + formatAmount(Number(text)) + 'тг';
        case 'max':
          add = '\n Максимальная сумма платежа:  ';
          return add + formatAmount(Number(text)) + 'тг';
        default:
          return;
      }
    }
    return '';
  };

  const showServiceDetails = (index) => {
    let rawProps = getObjectElement(list, index);
    const {
      PayType,
      ServiceId,
      Constraint,
      SubService,
      Amount0,
      Amount1,
      Amount2,
      ServiceName,
    } = rawProps;

    // Отображение технической информации в зависимости от схемы оплат
    let provSnInfo = '';
    if (Number(PayType) === 0) {
      // простая схема
      const simpleServices = [
        476 /* Tele2 */, 1078 /* 4G */, 3421 /* ALTEL 4G */,
        3429 /* TELE2 (БК) */, 3425 /* ALTEL 4G (БК) */,
      ];
      if (simpleServices.includes(Number(ServiceId))) {
        if (rawProps.ProvSN.String.length) {
          let xplodData = rawProps.ProvSN.String.split(';');
          const reference = `Reference: ${xplodData[1]}`;

          provSnInfo = `
					RNN: ${xplodData[0]}
					MSISDN: ${rawProps.Account}
					${getReference(reference, xplodData)}
					`;
        }
      }
    }

    let alsekoInfo = '';
    if (Number(PayType) === 1) {
      // Alseko
      alsekoInfo = `
			Номер инвойса: ${Constraint.String}
			Код сервиса: ${SubService.String}
			Оплачено: ${Number(Amount0.String).toFixed(2)}
			Послед. показ. счетчика: ${Number(Amount1.String).toFixed(2)}
			Количество: ${Number(Amount2.String).toFixed(2)}
			`;
    }

    let currencyInfo = '';
    if (Number(PayType) === 2) {
      // Валютная (wmz)
      currencyInfo = `
			Количество ${ServiceName}: ${Number(Amount0.String).toFixed(2)}
			Курс: ${Constraint.String}
			`;
    }

    let otisInfo = '';
    if (Number(PayType) === 4) {
      // выбор контракта (Отис)
      otisInfo = `
			Номер договора: ${Constraint.String}
			`;
    }

    let oskemenInfo = '';
    if (Number(PayType) === 14) {
      // С вводом счетчиков (Оскемен Водоканал)
      oskemenInfo = `
			Код счетчика: ${Constraint.String}
			Последние показания счетчика: ${Amount1.String}
			`;
    }

    let ktjInfo = '';
    if (Number(PayType) === 15) {
      // КТЖ
      const splitConstraint = Constraint.String.split(';');
      ktjInfo = `
			Номер билета: ${splitConstraint[0]}
			ID билета: ${splitConstraint[1]}
			EXPRESS ID: ${splitConstraint[2]}
			Стоимость билета без комиссии: ${Number(Amount0.String).toFixed(2)} тг.
			`;
    }

    let ktInfo = '';
    if (Number(PayType) === 16) {
      // Казактелеком
      const ktConstraint = Constraint.String.split('-');
      ktInfo = `
			Код региона: ${ktConstraint[0]}
			Номер лицевого счета: ${ktConstraint[1]}
			`;
    }

    swal(
      rawProps.ServiceName,
      serviceFormat(rawProps.ServiceDescription.String, 'info') +
        serviceFormat(rawProps.Region.String, 'reg') +
        serviceFormat(rawProps.ServiceComLimit.String, 'com') +
        serviceFormat(rawProps.ServiceMinAmount.String, 'min') +
        serviceFormat(rawProps.ServiceMaxAmount.String, 'max') +
        provSnInfo +
        alsekoInfo +
        currencyInfo +
        otisInfo +
        oskemenInfo +
        ktjInfo +
        ktInfo,
      'info'
    );
  };

  const getReference = (reference, xplodData) => {
    if (xplodData.length) return reference;
    return '';
  };

  const createBitrixTask = (sn, comment, idPayments, idSysUser, terminalId) => {
    const data = {
      sn,
      count: 5,
      idSysUser,
      idPayments: idPayments.toString(),
      terminalId,
      userId: Number(getFromLocalStorage('userId')),
      comment: comment !== null ? comment : '',
    };

    Api.setTaskInBitrix(data, 'problemPayment')
      .then((response) => {
        if (response !== undefined) {
          swal('ОК!', 'Задача в битриксе создана', 'success');
          Api.log({
            logType: 'bitrixTask',
            userid: Number(getFromLocalStorage('userId')),
            data: { sn, count: 5 },
            timestamp: (Date.now() / 1000) | 0,
          });
        }
      })
      .catch();
  };

  const goToCreateBitrixTask = (sn, idPayments, idSysUser, terminalId) => {
    Api.searchProblemPaymentLog({ idPayments: idPayments.toString() }).then(
      (response) => {
        if (typeof response !== 'undefined') {
          const { project, task } = response;

          if (project && task) {
            const url = `${BITRIX_URL}workgroups/group/${project}/tasks/task/view/${task}/`;

            swal({
              title: 'Задача уже создана',
              text: `Ссылка на задачу: ${url}`,
              icon: 'warning',
              buttons: ['Закрыть', 'Перейти к задаче'],
              dangerMode: true,
            }).then((sure) => {
              goToBitrixTask(sure, url);
            });
          } else {
            swal({
              title: 'Создание задачи в Bitrix',
              text: 'Добавьте пожалуйста свой комментарий к задаче:',
              content: 'input',
              button: {
                text: 'Создать задачу',
                closeModal: false,
              },
            }).then((comment) => {
              if (comment) {
                return createBitrixTask(
                  sn,
                  comment,
                  idPayments,
                  idSysUser,
                  terminalId
                );
              }
              return swal.close();
            });
          }
        }
      }
    );
  };

  const goToBitrixTask = (sure, url) => {
    if (sure) {
      window.location = url;
    }
  };

  const getLkInfo = (sn) => {
    Api.getLkInfo({ sn })
      .then((response) => {
        if (typeof response !== 'undefined') {
          const { Login, CellPhone, Email, PayWithBalance } = response;
          if (typeof Login !== 'undefined') {
            swal(
              'Информация о клиенте:',
              `
						Логин: ${Login.String || 'Не указан'}
						Номер телефона: ${CellPhone.String || 'Не указан'}
						Email: ${Email.String || 'Не указан'}
						${
              PayWithBalance
                ? 'Оплата произведена с баланса ЛК'
                : 'Оплата произведена с банковской карты'
            }
						`,
              'info'
            );
          }
        }
      })
      .catch();
  };

  const cancelPayment = (sn, serviceName, idPayments) => {
    swal({
      title: 'Внимание!',
      text: `Вы уверены что хотите снять платёж с проведения? \nНомер транзакции: ${sn} \nСервис: ${serviceName}`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((sure) => {
      if (sure) {
        const sendLog = (logType) => {
          Api.log({
            logType,
            userid: Number(getFromLocalStorage('userId')),
            data: { sn, idPayments },
            timestamp: (Date.now() / 1000) | 0,
          });
        };

        sendLog('cancelPaymentTrying');

        Api.cancelPayment({ sn, idPayments }).then((result) => {
          if (typeof result !== 'undefined') {
            const { isCancelled } = result;

            if (isCancelled) {
              swal('ОК!', `Платеж ${sn} снят с проведения`, 'success');
              sendLog('cancelPaymentSuccess');
            }
          }
        });
      }
    });
  };

  const getRepayPaymentUserFio = (status, paymentAgainUser, sn) => {
    if (Number(status) === RECREATED) {
      if (paymentAgainUser.includes('Andromeda'))
        return <b> {getObjectElement(transAndromedaUsers, sn)}</b>;
      else return <b> ({paymentAgainUser})</b>;
    }
    return '';
  };

  const changeCheckboxHandler = (e, index) => {
    let transactions = checkedTransactions;
    if (e.target.checked === true) {
      setObjectElement(transactions, transactions.length, index);
    } else {
      transactions = transactions.filter((key) => key !== index);
    }

    if (transactions.length > 1) {
      setIsCheckedCheckbox(true);
    } else {
      setIsCheckedCheckbox(false);
    }

    setCheckedTransactions(transactions);
    setCheckboxesStatus({ ...checkboxesStatus, [index]: e.target.checked });
  };

  const sendListReceipts = () => {
    if (clientData.Email === undefined || clientData.Email === '') {
      swal('Ошибка!', 'Заполните Email', 'error');
    } else {
      //берем параметры платежа по индексу из массива платежей
      let SNList = [];
      let IDServicesList = [];
      let TerminalsList = [];
      let extraInfo = 1;

      checkedTransactions.forEach((item, key) => {
        let ListData = getObjectElement(list, Number(item));
        let idservice = Number(ListData.ServiceId);
        setObjectElement(SNList, key, ListData.TransId);
        setObjectElement(IDServicesList, key, idservice);
        setObjectElement(TerminalsList, key, ListData.TerminalId);
      });

      //формируем запрос
      let query = {
        IdSysUser: Number(getObjectElement(list, 0).IDSysUser),
        SN: SNList,
        ExtraInfo: extraInfo,
        IDService: IDServicesList,
        Email: clientData.Email.trim(),
        TerminalId: TerminalsList,
      };

      swal({
        title: 'Вы уверены?',
        text: 'Клиенту будет отправлены дубликаты чеков на ' + query.Email,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((sure) => {
        if (sure) {
          Api.sendListReceipts(JSON.stringify(query))
            .then((result) => {
              //send log
              Api.log({
                logType: 'sendListCheck',
                userid: Number(getFromLocalStorage('userId')),
                data: query,
                timestamp: (Date.now() / 1000) | 0,
              });

              if (!result.error) {
                swal('ОК!', 'Дубликаты чеков отправлены!', 'success');
                setCheckedTransactions([]);
                setCheckboxesStatus({});
                setIsCheckedCheckbox(false);
              } else {
                swal(
                  'Ошибка!',
                  'Дубликаты чеков не отправлены! Попробуйте еще раз',
                  'error'
                );
              }
            })
            .catch((e) => {
              //чек не отправился
              swal(
                'Ошибка! Попробуйте еще раз',
                e.response.data.message,
                'error'
              );
            });
        }
      });
    }
  };

  const checkIsBckService = (constraint, serviceId) => {
    const splitConstraint = constraint.String.split('|');
    if (BCK_SERVICES_FOR_SHOW_PHONE.includes(Number(serviceId))) {
      return <span>{splitConstraint[splitConstraint.length - 1]}</span>;
    }
    return <></>;
  };

  return (
    <>
      {list.length > 0 && (
        <div style={{ marginTop: 30 }}>
          {showModalCheck && (
            <ShowCheckModal onClose={setShowModalCheck} image={checkImage} />
          )}

          {repayModalIsOpened &&
            typeof repayModalData.TransId !== 'undefined' && (
              <RepayModal
                toggleModal={repayModalToggle}
                repayModalData={repayModalData}
              />
            )}
          <h3>{title}</h3>
          {isCheckedCheckbox && (
            <Button
              color="primary"
              onClick={sendListReceipts}
              style={{ float: 'right', marginTop: '-60px' }}
            >
              Отправить чеки
            </Button>
          )}
          {isSearchTransactionByAccount && list.length > 1 && (
            <div style={{ marginBottom: 20 }}>
              <Alert
                text="Вы можете выбрать несколько транзакций для отправки чеков на почту.
              Для этого отметьте нужные транзакции, введите email и нажмите на кнопку 'Отправить чеки'"
                type="info"
              />
            </div>
          )}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {isSearchTransactionByAccount && list.length > 1 && (
                    <TableCell />
                  )}
                  <TableCell>
                    Время и сумма{' '}
                    <span className="styleGrey">
                      (общая - {getTotalSum(list)}тг)
                    </span>
                  </TableCell>
                  <TableCell>Номер счета и чека</TableCell>
                  <TableCell>Терминал</TableCell>
                  <TableCell>Сервис</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {list != null &&
                  list.length > 0 &&
                  list.map(
                    (
                      {
                        DateC,
                        DateIn,
                        Amount,
                        Account,
                        TransId,
                        TerminalId,
                        ServiceName,
                        IDSysUser,
                        ServiceId,
                        ProvName,
                        DateOut,
                        Error_code,
                        PostStatus,
                        Commission,
                        Error_name,
                        AgBalance,
                        Comment,
                        PaymentCanBeCancelled,
                        IDPayments,
                        RSN,
                        WhoMadeThePaymentAgain,
                        Constraint,
                      },
                      i
                    ) => (
                      <TableRow className=" row" key={TransId}>
                        {isSearchTransactionByAccount && list.length > 1 && (
                          <TableCell>
                            <Checkbox
                              checked={!!getObjectElement(checkboxesStatus, i)}
                              onChange={(e) => changeCheckboxHandler(e, i)}
                            />
                          </TableCell>
                        )}
                        <TableCell className=" col-md-3">
                          <div>
                            {DateC}{' '}
                            <span className="styleGrey">(терминал)</span>
                          </div>
                          <div>
                            {DateIn}{' '}
                            <span className="styleGrey">(система)</span>
                          </div>
                          <div>
                            <b>{formatAmount(Number(Amount))} тг</b>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>{Account}</div>
                          <div className="styleGrey">{TransId}</div>
                        </TableCell>
                        <TableCell>
                          <span>{TerminalId}</span>
                        </TableCell>
                        <TableCell>
                          <span>{ServiceName}</span>
                          <div className="flexFullCenterRow">
                            <span className="styleGrey">{ServiceId}</span>
                            <IconButton
                              data-index={i}
                              onClick={() => showServiceDetails(i)}
                            >
                              <HelpIcon />
                            </IconButton>
                          </div>
                          {ProvName}
                          <div>
                            <Button
                              size="small"
                              color="primary"
                              data-index={i}
                              onClick={() =>
                                goToCreateBitrixTask(
                                  TransId,
                                  IDPayments,
                                  IDSysUser,
                                  TerminalId
                                )
                              }
                            >
                              Запросить причину зависания
                            </Button>
                          </div>
                        </TableCell>
                        <TableCell width="20%">
                          {DateOut.String === '' &&
                            Error_code.String === '0' && (
                              <span>Проводится</span>
                            )}

                          {PostStatus.String && (
                            <span className="styleStatus">
                              {getObjectElement(
                                PAYMENT_STATUSES,
                                Number(PostStatus.String)
                              )}
                              {getRepayPaymentUserFio(
                                PostStatus.String,
                                WhoMadeThePaymentAgain.String,
                                TransId
                              )}
                            </span>
                          )}

                          {DateOut.String.charAt(0) === '2' && (
                            <span>
                              <span>
                                {DateOut.String.substring(8, 10)}.
                                {DateOut.String.substring(5, 7)}.
                                {DateOut.String.substring(0, 4)}
                                &nbsp;
                                {DateOut.String.substring(11, 19)}
                              </span>
                              <br />
                              <span>
                                Зачислено {formatAmount(Amount - Commission)} тг
                              </span>
                              <br />
                              <span className="styleGrey">
                                {' '}
                                - {Number(Commission)} тг комиссия
                              </span>
                              <br />
                            </span>
                          )}

                          {DateOut.String !== '' &&
                            DateOut.String.charAt(0) !== '2' && (
                              <div className="styleErr">
                                <span>{Error_code.String}</span>
                                <br />
                                <span>{Error_name.String}</span>
                                <br />
                                {Error_code.String === '220' && (
                                  <span>
                                    {formatAmount(AgBalance.String)}
                                    <IconButton
                                      data-index={i}
                                      onClick={agentsElmaTask}
                                    >
                                      <WorkIcon />
                                    </IconButton>
                                  </span>
                                )}
                              </div>
                            )}

                          {(userInfo.isB2b ||
                            userInfo.isManager ||
                            userInfo.isSupervisor ||
                            userInfo.isOperatorAdvanced) &&
                            PaymentCanBeCancelled && (
                              <Button
                                color="primary"
                                data-index={i}
                                onClick={() =>
                                  cancelPayment(
                                    TransId,
                                    ServiceName,
                                    IDPayments
                                  )
                                }
                              >
                                Снять с проведения
                              </Button>
                            )}
                          {PostStatus.String === 'r' && (
                            <div className="styleInfo">Произведен возврат</div>
                          )}
                          <span>{Comment.String}</span>
                          {checkIsBckService(Constraint, ServiceId)}
                        </TableCell>

                        <TableCell className="styleButtonBlock col-md-1">
                          <div>
                            <IconButton
                              data-index={i}
                              onClick={() => sendCheck(i)}
                              title="Отправка чека"
                            >
                              <ReceiptIcon />
                            </IconButton>
                            <IconButton
                              data-index={i}
                              onClick={() => showCheck(i)}
                              title="Просмотр чека"
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {DateOut.String &&
                              !RSN.String &&
                              DateOut.String.charAt(0) !== '2' &&
                              PostStatus.String !== '5' &&
                              PostStatus.String !== 'r' && (
                                <span>
                                  {(userInfo.isOperator ||
                                    userInfo.isManager) && (
                                    <IconButton
                                      data-index={i}
                                      onClick={() => showRepayModal(i)}
                                    >
                                      <ShuffleIcon />
                                    </IconButton>
                                  )}
                                </span>
                              )}
                            {TERMINAL_FOR_LK.includes(Number(TerminalId)) && (
                              <IconButton onClick={() => getLkInfo(TransId)}>
                                <InfoIcon />
                              </IconButton>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

TransactionsTable.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
};

export default TransactionsTable;
