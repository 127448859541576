import React from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'functions/helpers';
import BitrixTaskForm from 'screens/Bitrix/OwnNetwork/BitrixTaskForm';
import { OWN_NETWORK_BITRIX_TASK_TYPES } from 'constants/bitrixTask';

const bitixFormsFields = [
  { label: 'Город *', name: 'city', req: true },
  {
    label: 'Ответственный директор',
    name: 'responsibleId',
    required: true,
    type: 'select',
  },
  { label: 'Адрес', name: 'address', req: false },
  { label: 'Место установки', name: 'installPlace', req: false },
  { label: 'Номер телефона *', name: 'phone', req: true },
  { label: 'Комментарий', name: 'comment', req: false, type: 'textarea' },
];

const InstallTerminal = ({ sendBitrixTask }) => {
  const initialState = {
    city: '',
    address: '',
    installPlace: '',
    phone: '',
    comment: '',
    responsibleId: '',
  };

  const sendBitrixTaskFunc = ({
    city,
    address,
    installPlace,
    phone,
    comment,
    responsibleId,
  }) => {
    const data = {
      city,
      address,
      installPlace,
      phone,
      comment,
      userId: Number(getFromLocalStorage('userId')),
      responsibleId,
    };

    sendBitrixTask(data, OWN_NETWORK_BITRIX_TASK_TYPES.INSTALL_TERMINAL);
  };

  return (
    <BitrixTaskForm
      fields={bitixFormsFields}
      initialState={initialState}
      sendBitrixTask={sendBitrixTaskFunc}
    />
  );
};

InstallTerminal.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
};

export default InstallTerminal;
