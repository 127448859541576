import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/ui/Dropdown';

const menuItems = [
  {
    name: 'Кураторы агентов',
    link: '/report/agentsCurators',
    id: 'agentsCurators',
  },
  { name: 'Провайдеры, баланс', link: '/report/prov', id: 'reportProv' },
  {
    name: 'Провайдеры, статус',
    link: '/report/provStatus',
    id: 'reportProvStatus',
  },
  { name: 'ELMA', link: '/report/elma', id: 'reportElma' },
  {
    name: 'Перепроведения',
    link: '/report/repay',
    admin: true,
    manager: true,
    id: 'reportRepay',
  },
  {
    name: 'Платежи по налогам и штрафам, необработанные',
    link: '/report/onepayTroublesRaw',
    id: 'reportTroublesRaw',
  },
  {
    name: 'Платежи по налогам и штрафам, обработанные',
    link: '/report/onepayTroubles',
    admin: true,
    manager: true,
    id: 'reportTroubles',
  },
  {
    name: 'Дилеры (доступные средства и платежи)',
    link: '/report/dealers',
    id: 'reportDealers',
  },
  {
    name: 'Собственная сеть',
    link: '/report/ownNetwork',
    id: 'reportOwnNetwork',
  },
  {
    name: ' Управление черным списком',
    link: '/report/blackList',
    id: 'reportBlackList',
  },
];

const MenuReports = ({ isMobile }) => (
  <Dropdown title="Отчеты" menuItems={menuItems} isMobile={isMobile} />
);

MenuReports.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default MenuReports;
