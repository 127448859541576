import React from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'functions/helpers';
import BitrixTaskForm from '../BitrixTaskForm';
import { OWN_NETWORK_BITRIX_TASK_TYPES } from 'constants/bitrixTask';

const bitixFormsFields = [
  { label: 'Город *', name: 'city', req: true },
  {
    label: 'Ответственный директор',
    name: 'responsibleId',
    req: true,
    type: 'select',
  },
  { label: 'Адрес', name: 'address', req: false },
  { label: 'Номер телефона *', name: 'phone', req: true },
  { label: 'Комментарий', name: 'comment', req: false, type: 'textarea' },
];

const RentNotPaid = ({ sendBitrixTask }) => {
  const initialState = {
    city: '',
    address: '',
    phone: '',
    comment: '',
    responsibleId: '',
  };

  const sendBitrixTaskFunc = ({
    city,
    address,
    phone,
    comment,
    responsibleId,
  }) => {
    const data = {
      city,
      address,
      phone,
      comment,
      userId: Number(getFromLocalStorage('userId')),
      responsibleId,
    };
    sendBitrixTask(data, OWN_NETWORK_BITRIX_TASK_TYPES.RENT_NOT_PAID);
  };

  return (
    <BitrixTaskForm
      fields={bitixFormsFields}
      initialState={initialState}
      sendBitrixTask={sendBitrixTaskFunc}
    />
  );
};

RentNotPaid.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
};

export default RentNotPaid;
