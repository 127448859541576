import React, { useState } from 'react';
import Api from 'api/Api';
import { Typography, Grid } from '@material-ui/core';
import {
  getHumanDateFromTimestamp,
  getObjectElement,
  setObjectElement,
} from 'functions/helpers';
import Select from 'react-select';
import TableCustom from 'components/ui/Table';

const BITRIX_URL = 'https://spektr.bitrix24.kz/';
const TASK_TYPES = [
  { value: 'terminalDoesntWork', label: 'Не работает терминал' },
  { value: 'installTerminal', label: 'Установить терминал' },
  { value: 'rentNotPaid', label: 'Не оплачена аренда' },
  { value: 'ownNetworkOther', label: 'Другое' },
];

const tableData = [
  {
    type: 'terminalDoesntWork',
    head: [
      { label: 'Терминал', field: 'IdTerminal' },
      { label: 'Адрес', field: 'Address' },
      { label: 'Комментарий', field: 'Comment' },
      { label: 'Номер телефона', field: 'Phone' },
      { label: 'Наименование точки', field: 'PointName' },
      { label: 'Дата создания', field: 'Timestamp' },
      { label: 'Ссылка на задачу', field: 'TaskId' },
    ],
    vals: [
      { label: 'IdTerminal' },
      { label: 'Address' },
      { label: 'Comment' },
      { label: 'Phone' },
      { label: 'PointName' },
      { label: 'Timestamp', type: 'date' },
      { label: 'TaskId', type: 'link' },
    ],
  },
  {
    type: 'installTerminal',
    head: [
      { label: 'Город', field: 'City' },
      { label: 'Адрес', field: 'Address' },
      { label: 'Место установки', field: 'InstallPlace' },
      { label: 'Номер телефона', field: 'Phone' },
      { label: 'Комментарий', field: 'Comment' },
      { label: 'Дата создания', field: 'Timestamp' },
      { label: 'Ссылка на задачу', field: 'TaskId' },
    ],
    vals: [
      { label: 'City' },
      { label: 'Address' },
      { label: 'InstallPlace' },
      { label: 'Phone' },
      { label: 'Comment' },
      { label: 'Timestamp', type: 'date' },
      { label: 'TaskId', type: 'link' },
    ],
  },
  {
    type: 'rentNotPaid',
    head: [
      { label: 'Город', field: 'City' },
      { label: 'Адрес', field: 'Address' },
      { label: 'Номер телефона', field: 'Phone' },
      { label: 'Комментарий', field: 'Comment' },
      { label: 'Дата создания', field: 'Timestamp' },
      { label: 'Ссылка на задачу', field: 'TaskId' },
    ],
    vals: [
      { label: 'City' },
      { label: 'Address' },
      { label: 'Phone' },
      { label: 'Comment' },
      { label: 'Timestamp', type: 'date' },
      { label: 'TaskId', type: 'link' },
    ],
  },
  {
    type: 'ownNetworkOther',
    head: [
      { label: 'Заголовок', field: 'Title' },
      { label: 'Текст задачи', field: 'Text' },
      { label: 'Дата создания', field: 'Timestamp' },
      { label: 'Ссылка на задачу', field: 'TaskId' },
    ],
    vals: [
      { label: 'Title' },
      { label: 'Text' },
      { label: 'Timestamp', type: 'date' },
      { label: 'TaskId', type: 'link' },
    ],
  },
];

const pageTitle = 'Собственная сеть';

const OwnNetwork = () => {
  const [reports, setReports] = useState([]);
  const [selectValue, setSelectValue] = useState('');

  const setSelectedOption = (selectedOption) => {
    let select = '';
    if (selectedOption) select = selectedOption.value;

    setSelectValue(select);
    getReports(select);
  };

  const getReports = (select) => {
    Api.getOwnNetworkReport(select).then((response) => {
      if (typeof response !== 'undefined') {
        setReports(response);
      }
    });
  };

  const getRowValue = (report, item) => {
    let row = {};
    item.vals.map((val, k) => {
      let value = getObjectElement(report, val.label);
      let label = val.label;
      if (val.type === 'date') value = getHumanDateFromTimestamp(value);
      else if (val.type === 'link')
        value = (
          <a
            href={`${BITRIX_URL}workgroups/group/132/tasks/task/view/${value}/`}
            target="blank"
          >
            Перейти к задаче
          </a>
        );

      row = setObjectElement(row, label, value);
      return row;
    });
    return row;
  };

  const getTableRows = (item) => {
    let rows = [];
    if (reports !== undefined) {
      reports.map((report) => {
        let row = getRowValue(report, item);
        return rows.push(row);
      });
    }
    return rows;
  };

  return (
    <>
      <Typography variant="h5">{pageTitle}</Typography>
      <Grid sm={6}>
        <div className="customSelectAuto">
          <Select
            isClearable="true"
            isSearchable="true"
            placeholder="Выбрерите тип задачи"
            options={TASK_TYPES}
            onChange={setSelectedOption}
            noOptionsMessage={() => 'Ничего не найдено'}
          ></Select>
        </div>
      </Grid>
      {reports.length > 0 &&
        tableData.map((item, i) => {
          return (
            item.type === selectValue && (
              <TableCustom
                key={item.type}
                head={item.head}
                columns={getTableRows(item)}
                pageSize={30}
                showPaging={true}
              />
            )
          );
        })}
    </>
  );
};

export default OwnNetwork;
