import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Select from 'react-select';
import {
  Select as SelectCustom,
  Grid,
  MenuItem,
  Typography,
  FormControl,
} from '@material-ui/core';
import {
  exportReport,
  getServiceNameOnId,
  getHumanDateFromTimestamp,
  sortData,
} from 'functions/helpers';
import Api from 'api/Api';
import Button from 'components/ui/Button';
import DatePicker from 'components/ui/DatePicker';
import RepayDealers from './RepayDealers';
import { useSelector } from 'react-redux';
import RepayOperators from './RepayOperators';
import {
  SELECT_SUPERVISOR_USERS,
  SELECT_MANAGER_USERS,
  SELECT_OPERATOR_USERS,
} from 'constants/main';
import { USER_ROLE_KEYS } from 'constants/user';

const ROLES_SELECT_TYPES = [1, 3, 4];
const SELECT_ROLES_OPTIONS = [
  { key: SELECT_OPERATOR_USERS, label: 'Операторы' },
  { key: SELECT_SUPERVISOR_USERS, label: 'Супервайзеры' },
  { key: SELECT_MANAGER_USERS, label: 'Менеджеры' },
  { key: 2, label: 'Все перепроведения' },
];

const ReportRepay = () => {
  const services = useSelector((state) => state.main.services);
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectPlaceholder, setSelectPlaceholder] = useState('');
  const [Date_from, setDateFrom] = useState(
    new Date(moment().subtract(1, 'days'))
  );
  const [Date_to, setDateTo] = useState(new Date());
  const [selectedType, setType] = useState(1);
  const [reports, setReports] = useState([]);
  const [selectedOption, setOperator] = useState();
  const [filteredReport, setFilteredReports] = useState([]);

  useEffect(() => {
    if (ROLES_SELECT_TYPES.includes(selectedType) && users.length === 0)
      getUserList();

    setUserOptions(getSelectOptions());
    setSelectPlaceholder(getSelectPlaceholder());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, operatorOptions]);

  useEffect(() => {
    let report = reports;
    let data = report;

    if (
      selectedOption !== null &&
      selectedOption !== '' &&
      typeof selectedOption !== 'undefined' &&
      report
    ) {
      const userId = users.find(({ email }) => email === selectedOption).UserId;
      data = report.filter(
        ({ email, user }) => email === selectedOption || user === userId
      );
    }
    setFilteredReports(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, reports]);

  const getUserList = () => {
    Api.getOperators().then((response) => {
      if (typeof response !== 'undefined') {
        const filteredOperators = response.filter(
          ({ IsActive, Roles, email }) =>
            IsActive &&
            email !== '' &&
            typeof Roles !== 'undefined' &&
            !Roles.includes(USER_ROLE_KEYS.ADMIN) &&
            !Roles.includes(USER_ROLE_KEYS.MANAGER)
        );

        const userOptionsList = filteredOperators.map(
          ({ email, Name, lastname }) => ({
            value: email,
            label: `${Name} ${lastname}`,
          })
        );

        setOperatorOptions(sortData(userOptionsList, 'label'));
        getOptionsForManager(response);
        getOptionsForSupervisor(response);
        setUsers(response);
      }
    });
  };

  const getOptionsForManager = (responseUsers) => {
    const filteredOperators = responseUsers.filter(
      ({ IsActive, Roles }) =>
        IsActive &&
        typeof Roles !== 'undefined' &&
        Roles.includes(USER_ROLE_KEYS.MANAGER)
    );

    const managerOptionsList = filteredOperators.map(
      ({ email, Name, lastname }) => ({
        value: email,
        label: `${Name} ${lastname}`,
      })
    );

    setManagerOptions(sortData(managerOptionsList, 'label'));
  };

  const getOptionsForSupervisor = (responseUsers) => {
    const filteredOperators = responseUsers.filter(
      ({ IsActive, Roles }) =>
        IsActive &&
        typeof Roles !== 'undefined' &&
        Roles.includes(USER_ROLE_KEYS.SUPERVISOR)
    );

    const supervisorOptionsList = filteredOperators.map(
      ({ email, Name, lastname }) => ({
        value: email,
        label: `${Name} ${lastname}`,
      })
    );

    setSupervisorOptions(sortData(supervisorOptionsList, 'label'));
  };

  const selectOperator = (newValue) => {
    if (newValue) setOperator(newValue.value);
    else setOperator('');
  };

  //один формат даты для дейтпикера, второй для запроса в базу
  const handleChangeDate_from = (date) => {
    setDateFrom(date);
  };

  const handleChangeDate_to = (date) => {
    setDateTo(date);
  };

  const getReportOperation = () => {
    let DateFrom = Date_from;
    DateFrom.setSeconds(0);
    DateFrom = (Date_from / 1000) | 0;

    let DateTo = Date_to;
    DateTo.setSeconds(59);
    DateTo = (Date_to / 1000) | 0;

    let params = {
      Date_from: DateFrom,
      Date_to: DateTo,
      Operator: 0,
    };

    if (selectedOption !== null) {
      const operator = selectedOption;

      if (operator !== null && Number.isInteger(operator)) {
        params.Operator = operator;
      }
    }

    Api.getReport('repayOperators', JSON.stringify(params)).then((response) => {
      if (response !== undefined && response.data !== null) {
        setReports(response.data);
        setFilteredReports(response.data);
      }
    });
  };

  const getReport = () => {
    const params = {
      DateFrom: moment(Date_from).format('YYYY-MM-DD HH:mm:ss'),
      DateTo: moment(Date_to).format('YYYY-MM-DD HH:mm:ss'),
    };

    Api.getReport('repayGlobal', JSON.stringify(params)).then((report) => {
      if (typeof report !== 'undefined' && report !== null) {
        setReports(report);
      }
    });
  };

  const getStructure = () => {
    return filteredReport.map((item) => {
      let user = users.find(
        (val) =>
          val.UserId.toString() === item.user.toString() ||
          (val.email === item.Email && val.email !== '')
      );

      if (typeof user !== 'undefined') {
        item.name = user.Name + ' ' + user.lastname;
      } else {
        item.name = '';
      }
      item.log.serviceString = getServiceNameOnId(services, item.log.Service);
      item.serviceString = getServiceNameOnId(services, item.service);
      return item;
    });
  };

  const exportReportHandler = () => {
    let data = getStructure();
    exportReport(data, 'repay');
  };

  const handleGetReport = () => {
    if (ROLES_SELECT_TYPES.includes(selectedType)) getReportOperation();
    else if (selectedType === 2) getReport();
  };

  const handleCheckType = (e) => {
    setReports([]);
    setFilteredReports([]);
    setType(e.target.value);
  };

  const getSelectPlaceholder = () => {
    switch (selectedType) {
      case 1:
        return 'Выбрать оператора';
      case 3:
        return 'Выбрать супервайзера';
      case 4:
        return 'Выбрать менеджера';
      default:
        return 'Выбрать оператора';
    }
  };

  const getSelectOptions = () => {
    switch (selectedType) {
      case 1:
        return operatorOptions;
      case 3:
        return supervisorOptions;
      case 4:
        return managerOptions;
      default:
        return operatorOptions;
    }
  };

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item sm={10} xs={12}>
          <Typography variant="h5">Отчет: Перепроведения</Typography>
        </Grid>
        {ROLES_SELECT_TYPES.includes(selectedType) && (
          <Grid item sm={2} xs={12}>
            <Button
              color="primary"
              fullWidth
              onClick={exportReportHandler}
              disabled={!reports.length}
            >
              Экспорт в Excel
            </Button>
          </Grid>
        )}
      </Grid>
      <div className="searchResult">
        <Grid container spacing={3}>
          <Grid item sm={2} xs={6}>
            <DatePicker selected={Date_from} onChange={handleChangeDate_from} />
          </Grid>
          <Grid item sm={2} xs={6}>
            <DatePicker selected={Date_to} onChange={handleChangeDate_to} />
          </Grid>
          <Grid item sm={2} xs={12}>
            <div className="customInp customSelect">
              <FormControl variant="filled">
                <SelectCustom
                  fullWidth
                  defaultValue={1}
                  onChange={handleCheckType}
                >
                  {SELECT_ROLES_OPTIONS.map((item) => {
                    return (
                      <MenuItem key={item.key} value={item.key}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </SelectCustom>
              </FormControl>
            </div>
          </Grid>

          {ROLES_SELECT_TYPES.includes(selectedType) && (
            <Grid item sm={4} xs={12}>
              <div className="customSelectAuto">
                <Select
                  isClearable="true"
                  isSearchable="true"
                  placeholder={selectPlaceholder}
                  options={userOptions}
                  onChange={selectOperator}
                  noOptionsMessage={() => 'Ничего не найдено'}
                />
              </div>
            </Grid>
          )}
          <Grid item sm={2} xs={12}>
            <Button
              color="primary"
              className="noMarg"
              onClick={handleGetReport}
              fullWidth
            >
              Показать
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="searchResult">
        {ROLES_SELECT_TYPES.includes(selectedType) && (
          <RepayOperators
            reports={filteredReport}
            users={users}
            getServiceNameOnId={getServiceNameOnId}
            services={services}
            getHumanDateFromTimestamp={getHumanDateFromTimestamp}
            selectedType={selectedType}
          />
        )}
        {selectedType === 2 && <RepayDealers reports={reports} />}
      </div>
    </div>
  );
};

export default ReportRepay;
