import React, { useState, useEffect } from 'react';
import Api from 'api/Api';
import Button from 'components/ui/Button';
import { useSelector } from 'react-redux';
import {
  exportReport,
  formatDate,
  formatAmount,
} from '../../functions/helpers';
import { Grid, Typography } from '@material-ui/core';
import TableCustom from 'components/ui/Table';

const tableHead = [
  { label: 'Дата, сумма, комиссия', field: 'Date' },
  { label: 'Номер счета', field: 'Account' },
  { label: 'Телефон', field: 'Phone' },
  { label: 'Сервис', field: 'Service' },
  { label: 'Транзакция', field: 'Sn' },
];

const pageTitle = 'Платежи по налогам и штрафам, необработанные';

const OnepayTroublesRaw = () => {
  const services = useSelector((state) => state.main.services);
  const [report, setReports] = useState([]);

  const getReport = () => {
    Api.getReport('onePayTroubleRaw', JSON.stringify({})).then((response) => {
      if (response !== undefined && response !== null) {
        setReports(response);
      }
    });
  };

  const getService = (id) => {
    let label = services.filter((val) => val.value === Number(id))[0];
    if (label) return label.label;
  };

  const getStructureTroublesRaw = () => {
    return report.map((item) => {
      item.DateFormat = formatDate(item.Date.String);
      item.AmountFormat = formatAmount(item.Amount.String);
      item.ComissionFormat = formatAmount(item.Comission.String);
      return item;
    });
  };

  useEffect(() => {
    getReport();
  }, []);

  const getTableRows = () => {
    let rows = [];
    if (report !== undefined) {
      report.map((val) => {
        return rows.push({
          Date: (
            <>
              <b>{formatDate(val.Date.String)}</b>
              <div>{formatAmount(val.Amount.String)} тг</div>
              <div>{formatAmount(val.Comission.String)} тг</div>
            </>
          ),
          Account: val.Account.String,
          Phone: val.Phone.String,
          Service: getService(val.Service.String),
          Sn: val.SN.String,
        });
      });
    }
    return rows;
  };

  return (
    <>
      <div>
        <Grid container alignItems="center">
          <Grid item sm={10} xs={12}>
            <Typography variant="h5">
              {pageTitle}: {report.length}
            </Typography>
          </Grid>

          <Grid item sm={2} xs={12}>
            <Button
              color="primary"
              fullWidth
              onClick={() =>
                exportReport(getStructureTroublesRaw(), 'onepayTroublesRaw')
              }
              disabled={!report.length}
            >
              Экспорт в Excel
            </Button>
          </Grid>
        </Grid>
        <div className="searchResult">
          {report.length > 0 && (
            <TableCustom
              head={tableHead}
              columns={getTableRows()}
              pageSize={30}
              showPaging={true}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default OnepayTroublesRaw;
