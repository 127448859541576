import React from 'react';
import { formatAmount } from 'functions/helpers';
import PropTypes from 'prop-types';
import TableCustom from 'components/ui/Table';

import { SELECT_MANAGER_USERS, SELECT_SUPERVISOR_USERS } from 'constants/main';

const getTableHead = (selectedType) => {
  let tableHead = [];
  switch (selectedType) {
    case SELECT_MANAGER_USERS:
      tableHead.push({ label: 'Менеджер', field: 'User' });
      break;
    case SELECT_SUPERVISOR_USERS:
      tableHead.push({ label: 'Супервайзер', field: 'User' });
      break;
    default:
      tableHead.push({ label: 'Оператор', field: 'User' });
      break;
  }
  tableHead.push(
    { label: 'Транзакция', field: 'Sn' },
    { label: 'Сервис', field: 'Service' },
    { label: 'Аккаунт', field: 'Account' },
    { label: 'Сумма', field: 'Amount' },
    { label: 'Дата', field: 'Datestart' },
    { label: 'Новая транзакция', field: 'Newsn' },
    { label: 'Дата завершения', field: 'Dateend' }
  );

  return tableHead;
};

const RepayOperators = ({
  reports,
  users,
  getServiceNameOnId,
  services,
  getHumanDateFromTimestamp,
  selectedType,
}) => {
  const formatUser = (id, email) => {
    const userFiltered = users.find(
      (user) =>
        (user.Email === email && user.Email !== '') || user.UserId === id
    );
    return `${userFiltered?.Name} ${userFiltered?.lastname}`;
  };
  const tableHead = getTableHead(selectedType);

  const getTableRows = () => {
    let rows = [];

    if (reports !== undefined) {
      reports.map((val) => {
        return rows.push({
          User: formatUser(val.user, val.Email),
          Sn: val.sn && val.log.TerminalId !== '' && (
            <div>
              Сервис - {getServiceNameOnId(services, val.log.Service)}
              <br />
              Аккаунт - {val.log.Account}
              <br />
              Сумма - {formatAmount(val.log.Amount)} тг
              <br />
              Код ошибки - {val.log.ErrorCode}
              <br />
              Ошибка - {val.log.Error}
            </div>
          ),
          Service: getServiceNameOnId(services, val.service),
          Account: val.account,
          Amount: formatAmount(val.amount) + ' тг',
          Datestart: getHumanDateFromTimestamp(val.datestart),
          Newsn: val.newsn,
          Dateend: getHumanDateFromTimestamp(val.dateend),
        });
      });
    }
    return rows;
  };

  return (
    <div>
      {reports.length > 0 ? (
        <TableCustom
          head={tableHead}
          columns={getTableRows()}
          pageSize={30}
          showPaging={true}
        />
      ) : (
        'Ничего не найдено'
      )}
    </div>
  );
};

RepayOperators.propTypes = {
  reports: PropTypes.array,
  users: PropTypes.array,
  getServiceNameOnId: PropTypes.func,
  services: PropTypes.array,
  getHumanDateFromTimestamp: PropTypes.func,
  selectedType: PropTypes.number,
};

export default RepayOperators;
