import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Api from 'api/Api';
import Button from 'components/ui/Button';
import DatePicker from 'components/ui/DatePicker';
import { Typography, Grid } from '@material-ui/core';
import ServicesSelect from 'screens/Kassa24/transaction/servicesSelect';
import {
  setOnepayList,
  setOnepayListAuto,
  setOnepayParams,
} from 'features/mainSlice';
import CustomTextField from 'components/ui/TextField';
import { getObjectElement, setObjectElement } from 'functions/helpers';

const FORM_FIELDS = [
  { type: 'text', name: 'Email', label: 'E-mail' },
  { type: 'text', name: 'Phone', label: 'Телефон' },
  { type: 'text', name: 'Login', label: 'Номер счета' },
  { type: 'text', name: 'Transaction', label: 'Транзакция' },
  { type: 'number', name: 'Limit', label: 'Количество' },
];

const SearchTransOnepay = () => {
  const dispatch = useDispatch();
  const onepayParams = useSelector((state) => state.main.onepayParams);
  const [params, setParams] = useState(onepayParams);
  const dateFrom = new Date(moment().subtract(3, 'days'));
  const dateTo = new Date();

  //один формат даты для дейтпикера, второй для запроса в базу
  const handleChangeDate_from = (date) => {
    let paramsState = { ...params };
    paramsState.Date_from = moment(date).format('YYYY-MM-DD 00:00:00');
    setParams(paramsState);
  };

  const handleChangeDate_to = (date) => {
    let paramsState = { ...params };
    paramsState.Date_to = moment(date).format('YYYY-MM-DD 23:59:59');
    setParams(paramsState);
  };

  //выбор сервиса из выпадающего списка
  const selectService = (service) => {
    let paramsState = { ...params };

    if (!service) {
      paramsState.Service = 0;
    } else {
      paramsState.Service = service;
    }
    setParams(paramsState);
  };

  const setData = (e) => {
    let paramsState = { ...params };
    let name = e.target.name;
    if (e.target.type === 'number')
      paramsState = setObjectElement(paramsState, name, Number(e.target.value));
    else setObjectElement(paramsState, name, e.target.value);

    setParams(paramsState);
  };

  const searchTransOnepayFunc = (autoList, paramsList, setParamsFunc) => {
    //очистить
    dispatch(setOnepayList([]));
    if (autoList) {
      dispatch(setOnepayListAuto([]));
    }

    for (let i in paramsList) {
      let paramsListItem = getObjectElement(paramsList, i);
      Api.getTransOnepay(paramsListItem)
        .then((response) => {
          if (response != null) {
            if (autoList) {
              dispatch(setOnepayListAuto(response));
            } else {
              dispatch(setOnepayList(response));
            }
          }
        })
        .catch();

      if (setParamsFunc) {
        dispatch(setOnepayParams(paramsListItem));
      }
    }
  };

  // поиск транзакций
  const searchTrans = () => {
    searchTransOnepayFunc(false, [params], true);
  };

  return (
    <>
      <Typography variant="h1">OnePay</Typography>
      <Grid container spacing={3}>
        {FORM_FIELDS.map((item) => {
          return (
            <Grid item sm={3} key={item.name} xs={12}>
              <CustomTextField
                type={item.type}
                value={getObjectElement(params, item.name)}
                name={item.name}
                onInput={setData}
                label={item.label}
                fullWidth
                variant="filled"
              />
            </Grid>
          );
        })}

        <Grid item sm={2} xs={6}>
          <DatePicker
            selected={dateFrom}
            onChange={handleChangeDate_from}
            dateFormat="dd/mm/yyyy"
          />
        </Grid>
        <Grid item sm={2} xs={6}>
          <DatePicker
            selected={dateTo}
            onChange={handleChangeDate_to}
            dateFormat="dd/mm/yyyy"
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ServicesSelect setData={selectService} />
        </Grid>
        <Grid item sm={1} xs={12}>
          <Button
            color="primary"
            className="noMarg"
            onClick={searchTrans}
            fullWidth
          >
            Найти
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchTransOnepay;
