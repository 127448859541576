import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveSection } from 'features/mainSlice';
import { logout } from 'features/userSlice';
import MenuIcon from '@material-ui/icons/Menu';
import { Hidden, Drawer, LinearProgress } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonaCard from 'screens/PersonCard';
import Timer from 'components/Timer';
import Api from 'api/Api';
import history from 'appHistory';
import { Link } from 'react-router-dom';
import MenuControl from 'components/MenuControl';
import MenuReports from 'components/MenuReports';
import { getFromLocalStorage, setMainLocationByRole } from 'functions/helpers';
import { useUser } from 'hooks';
import { MAIN_MENU } from 'constants/route';
import useMenu from 'hooks/useMenu';

const Header = () => {
  const pageUrl = window.location.href;
  const dispatch = useDispatch();
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false);
  const [openPersonCard, setOpenPersonCard] = useState(false);
  const activeSection = window.location.pathname;
  const userInfo = useSelector((state) => state.user.userInfo || {});
  const showProgress = useSelector((state) => state.ui.showProgress);
  const { userRoles } = useUser();
  const { mainMenu, isShowReportsMenu } = useMenu();
  const headClass = MAIN_MENU.filter((item) => item.link === activeSection);

  useEffect(() => {
    const filteredMenu = mainMenu.filter(
      (menu) => menu.link === history.location.pathname
    );

    if (!filteredMenu[0]) {
      setMainLocationByRole(userRoles);
    }
  }, [mainMenu, userRoles]);

  const toggleOpenPersonCard = useCallback(
    () => setOpenPersonCard(!openPersonCard),
    [openPersonCard]
  );

  const toggleSetOpenMenuDrawer = useCallback(
    () => setOpenMenuDrawer(!openMenuDrawer),
    [openMenuDrawer]
  );

  const setLink = useCallback(
    (item) => () => {
      if (item.class) dispatch(setActiveSection(item.class));
      history.push(item['link']);
    },
    [dispatch]
  );

  const handleLogoutClick = () => {
    Api.log({
      logType: 'logout',
      userid: Number(getFromLocalStorage('userId')),
      data: {
        userLogin: getFromLocalStorage('login'),
        name: getFromLocalStorage('name'),
      },
      timestamp: (Date.now() / 1000) | 0,
    }).then(() => {
      dispatch(logout());
      history.push('/');
    });
  };

  const getActiveMenu = (link) => pageUrl.indexOf(link) !== -1;

  const checkIsActive = (item) => {
    if (item.link.includes(activeSection) && activeSection !== '/')
      return 'headLinkActiveMob';
    return '';
  };

  const checkIsActiveUpd = (item) => {
    if (getActiveMenu(item['link']) && pageUrl !== '/') return 'headLinkActive';
    return '';
  };

  return (
    <div
      className={`header ${
        activeSection !== '/personal' && headClass.length && headClass[0].class
      }`}
    >
      <Hidden only={['lg', 'xl']}>
        <Drawer
          open={openMenuDrawer}
          anchor="left"
          onClose={toggleSetOpenMenuDrawer}
        >
          <nav className="mobileNav">
            {mainMenu.map((item) =>
              item.timer ? (
                <Timer key={item.class} setLink={setLink(item)} />
              ) : (
                <Link
                  key={item.class}
                  className={`headLink ${checkIsActive(item)}`}
                  to={item.link}
                >
                  {item.name}
                </Link>
              )
            )}
            {(userInfo.isManager || userInfo.isAdmin) && (
              <MenuControl isMobile />
            )}
            {isShowReportsMenu && <MenuReports isMobile />}
          </nav>
        </Drawer>
      </Hidden>

      <Drawer
        open={openPersonCard}
        anchor="right"
        onClose={toggleOpenPersonCard}
      >
        <div className="personCard">
          <PersonaCard />
        </div>
      </Drawer>

      <header>
        <div className="headerLeft">
          <Hidden only={['lg', 'xl', 'md']}>
            <div className="menuNav">
              <MenuIcon onClick={toggleSetOpenMenuDrawer} />
            </div>
          </Hidden>
          <Hidden only={['md']}>
            <h1>Call Center</h1>
          </Hidden>
          <Hidden only={['sm', 'xs']}>
            <nav>
              {mainMenu.map((item, i) =>
                item.timer ? (
                  <Timer key={item.class} setLink={setLink(item)} />
                ) : (
                  <button
                    type="button"
                    key={item.class}
                    className={`headLink ${checkIsActiveUpd(item)}`}
                    onClick={setLink(item)}
                  >
                    {item.name}
                  </button>
                )
              )}
            </nav>
          </Hidden>
        </div>

        <div className="headNavCenter">
          <nav>
            {(userInfo.isManager || userInfo.isAdmin) && (
              <MenuControl isMobile={false} />
            )}
            {isShowReportsMenu && <MenuReports isMobile={false} />}
            <div className="headIcon iconBlue">
              <PersonAddIcon onClick={toggleOpenPersonCard} />
            </div>
          </nav>
        </div>

        <nav>
          <Hidden only={['sm', 'xs', 'md']}>
            {userInfo.name}
            <br />
          </Hidden>
          <div className="headIcon iconRed iconExit">
            <ExitToAppIcon className="buttonIcon" onClick={handleLogoutClick} />
          </div>
        </nav>
      </header>

      {showProgress && <LinearProgress />}
    </div>
  );
};

export default Header;
