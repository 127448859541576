import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import { a11yProps } from 'functions/helpers';
import TabPanel from 'components/ui/TabPanel';
import ManagingSections from './ManagingSections';
import EditFaq from './EditFaq';
import { setWiki } from 'features/mainSlice';
import Api from 'api/Api';

const WikiEdit = () => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    getWikiHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWikiHandler = useCallback(() => {
    Api.getWiki()
      .then((response) => {
        dispatch(setWiki(response));
      })
      .catch();
  }, [dispatch]);

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={(_, newValue) => setCurrentTab(newValue)}
        className="customTabs"
        scrollButtons="auto"
        variant="scrollable"
        indicatorColor="primary"
      >
        <Tab label="Управление разделами FAQ" {...a11yProps(0)} />;
        <Tab label="Настройка содержимого" {...a11yProps(1)} />;
      </Tabs>
      <TabPanel value={currentTab} index={0}>
        <ManagingSections getWikiHandler={getWikiHandler} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <EditFaq getWikiHandler={getWikiHandler} />
      </TabPanel>
    </>
  );
};

export default WikiEdit;
