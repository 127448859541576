import PropTypes from 'prop-types';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from '@material-ui/core';
import React from 'react';
import CustomTextField from 'components/ui/TextField';
import {
  getClassNameForSelect,
  getFieldsYup,
  getObjectElement,
} from 'functions/helpers';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Button from 'components/ui/Button';

const BitrixTaskForm = ({ initialState, fields, sendBitrixTask }) => {
  const directorsList = useSelector((state) => state.main.directorsList);
  const validationSchema = yup.object(getFieldsYup(fields));

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: sendBitrixTask,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        {fields.map(({ label, name, type = 'text' }) => {
          const value = getObjectElement(formik.values, name);
          const touched = getObjectElement(formik.touched, name);
          const errors = getObjectElement(formik.errors, name);
          return (
            <Grid
              key={name}
              item
              xs={12}
              sm={type === 'text' || type === 'select' ? 6 : 12}
            >
              {type === 'select' ? (
                <div
                  className={`customSelect ${getClassNameForSelect(
                    formik.touched['responsibleId'],
                    formik.errors['responsibleId']
                  )}`}
                >
                  <FormControl variant="filled">
                    <InputLabel id="respId-label">{label}</InputLabel>
                    <Select
                      labelId="respId-label"
                      defaultValue=""
                      fullWidth
                      onChange={formik.handleChange}
                      name={name}
                      variant="filled"
                    >
                      {directorsList.map(
                        (item) =>
                          item.NAME.trim() && (
                            <MenuItem value={item.ID} key={item.ID}>
                              {item.NAME}
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                  <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                    {formik.touched['responsibleId'] &&
                      formik.errors['responsibleId']}
                  </div>
                </div>
              ) : (
                <CustomTextField
                  type={type}
                  name={name}
                  fullWidth
                  label={label}
                  autoComplete="off"
                  variant="filled"
                  value={value}
                  onChange={formik.handleChange}
                  error={touched && Boolean(errors)}
                  helperText={touched && errors}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={3} className="gridPadding">
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4} className="gridCenter">
          <Button color="primary" fullWidth type="submit" className="btnMarg20">
            Поставить задачу
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

BitrixTaskForm.propTypes = {
  fields: PropTypes.array.isRequired,
  initialState: PropTypes.object.isRequired,
  sendBitrixTask: PropTypes.func.isRequired,
};

export default BitrixTaskForm;
