import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';

import { useTable } from 'hooks';
import { getObjectElement } from 'functions/helpers';

const TableCustom = ({ head, columns, pageSize, showPaging }) => {
  const {
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    order,
    orderBy,
    stableSort,
    createSortHandler,
  } = useTable(pageSize);

  const getOrderDirection = (headCell) => {
    if (orderBy === headCell.field) return order;
    return 'asc';
  };

  const getMapTableRow = useCallback(
    (result) => {
      return result.map((item) => {
        return (
          <TableRow key={result.length++}>
            {head.map((val) => {
              return (
                <TableCell key={val.field}>
                  {getObjectElement(item, val.field)}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
    },
    [head]
  );

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {head.map((headCell) => (
                <TableCell
                  key={headCell.field}
                  align={headCell.align ? headCell.align : 'left'}
                  sortDirection={orderBy === headCell.field ? order : false}
                  data-id-cell={headCell.field}
                  width={headCell.width || 'auto'}
                >
                  {headCell.field === 'buttons' || headCell.sort === false ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.field}
                      direction={getOrderDirection(headCell)}
                      onClick={createSortHandler(headCell.field)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showPaging
              ? getMapTableRow(
                  stableSort(columns)
                    .reverse()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                )
              : getMapTableRow(stableSort(columns).reverse())}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Количество элементов на странице: "
        rowsPerPageOptions={[30, 60, 90]}
        component="div"
        count={columns.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} из ${count}`
        }
      />
    </>
  );
};

TableCustom.propTypes = {
  head: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  showPaging: PropTypes.bool,
};

export default TableCustom;
