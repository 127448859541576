import React from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'functions/helpers';
import BitrixTaskForm from 'components/BitrixTaskForm';

const bitixFormsFields = [
  {
    label: 'Ответственное лицо',
    name: 'responsible',
    disabled: true,
    value: 'Отдел B2B',
  },
  { label: 'Наименование ИП/ТОО *', name: 'companyName', req: true },
  { label: 'Номер агента (ID) *', name: 'agentNumber', req: true },
  { label: 'Название задачи *', name: 'taskTitle', req: true },
  { label: 'Текст задачи *', name: 'description', type: 'textarea', req: true },
];

const OtherTask = ({ sendBitrixTask, title }) => {
  const initialValues = {
    title: '',
    description: '',
    companyName: '',
    agentNumber: '',
  };

  const sendBitrixTaskFunc = ({
    taskTitle,
    description,
    companyName,
    agentNumber,
  }) => {
    const data = {
      title: taskTitle,
      description,
      companyName,
      agentNumber,
      userId: Number(getFromLocalStorage('userId')),
    };

    sendBitrixTask(data, 'otherTask');
  };

  return (
    <BitrixTaskForm
      title={title}
      fields={bitixFormsFields}
      initialState={initialValues}
      sendBitrixTask={sendBitrixTaskFunc}
    />
  );
};

OtherTask.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default OtherTask;
