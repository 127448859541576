import React, { useState, useEffect } from 'react';
import Api from 'api/Api';
import { Grid, Typography } from '@material-ui/core';

import TableCustom from 'components/ui/Table';
import { formatAmount } from 'functions/helpers';
import ReportSearch from 'components/ReportSearch';

const tableHead = [
  { label: 'Агент', field: 'Agent' },
  { label: 'Доступные средства', field: 'Balance' },
  { label: 'Количество платежей', field: 'Count' },
  { label: 'Сумма платежей', field: 'Amount' },
];

const pageTitle = ' Дилеры (доступные средства и платежи)';

const Dealers = () => {
  const [reports, setReport] = useState([]);
  const [filteredReport, setFilteredReport] = useState([]);

  useEffect(() => {
    getReports();
  }, []);

  const getReports = () => {
    Api.getDealers().then((response) => {
      let dealers = JSON.parse(response.data);
      if (dealers !== undefined) {
        setReport(dealers);
        setFilteredReport(dealers);
      }
    });
  };

  const getTableRows = () => {
    let rows = [];
    if (reports !== undefined) {
      filteredReport.map((val) => {
        return rows.push({
          Agent: val.agentName,
          Balance: formatAmount(val.balance) + ' тг',
          Count: val.count,
          Amount: formatAmount(val.sum) + ' тг',
        });
      });
    }
    return rows;
  };

  const onReportSearch = (searchedData) => {
    setFilteredReport(searchedData);
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item sm={6} xs={12}>
          <Typography variant="h5">{pageTitle}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <ReportSearch
            placeholder="Поиск по названию"
            onReportSearch={onReportSearch}
            fieldsSearch={['agentName']}
            itemsList={reports}
          />
        </Grid>
      </Grid>
      <div className="searchResult">
        {filteredReport.length > 0 && (
          <TableCustom
            head={tableHead}
            columns={getTableRows()}
            pageSize={30}
            showPaging={true}
          />
        )}
        {reports.length > 0 &&
          filteredReport.length === 0 &&
          'Ничего не найдено. Попробуйте изменить параметры запроса и повторить поиск.'}
      </div>
    </>
  );
};

export default Dealers;
