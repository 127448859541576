import React from 'react';
import { useSelector } from 'react-redux';

import SearchTransOnepay from './searchTransOnepay';
import Table from './tableResult';

const OnePay = () => {
  const onepay_list = useSelector((state) => state.main.onepay_list);
  return (
    <>
      <SearchTransOnepay />
      <div className="searchResult">
        {onepay_list !== null && onepay_list.length > 0 && (
          <Table
            list={onepay_list}
            title={'Транзакций найдено: ' + onepay_list.length}
          />
        )}
      </div>
    </>
  );
};

export default OnePay;
