import React from 'react';
import { Link, Typography, Grid } from '@material-ui/core';
import {
  getDaysBeforePassExpired,
  getFromLocalStorage,
} from 'functions/helpers';
import { MIN_DAYS_BEFORE_CHANGE_PASSWORD } from 'constants/main';

const ChangePassword = () => {
  const exp = getFromLocalStorage('passwordExpired');
  const dayBeforeExp = getDaysBeforePassExpired(exp);

  if (dayBeforeExp > MIN_DAYS_BEFORE_CHANGE_PASSWORD) {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item sm={10} xs={12}>
            <Typography variant="h5">Смена пароля</Typography>
          </Grid>
        </Grid>
        <p>Смена пароля не требуется</p>
      </>
    );
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid item sm={10} xs={12}>
          <Typography variant="h5">Смена пароля</Typography>
        </Grid>
      </Grid>

      <p>
        Срок действия пароля на вашей корпоративной учётной записи закончится
        через <b>{dayBeforeExp} дня(ей)</b>. Не забудьте своевременно сменить
        Ваш пароль.
      </p>
      <p>
        Если Вы используете компьютер, подключенный к домену kassa24.kz, пароль
        можно сменить через комбинацию клавиш <b>Ctrl+Alt+Del</b>, далее{' '}
        <b>Изменить пароль</b>.
      </p>
      <p>
        В случае если Ваш компьютер не подключен к корпоративному домену Вам
        необходимо выполнить следующие шаги:
      </p>
      <ul>
        <ol>
          1. Подключитесь к корпоративной сети локально или удаленно через
          корпоративный <b>VPN AnyConnect</b>.
        </ol>
        <ol>
          2. Перейдите по{' '}
          <b>
            <Link href="https://passcore.kassa24.kz/">ссылке</Link>
          </b>{' '}
          .
        </ol>
        <ol>
          3. Заполните все необходимые поля. Минимальные требования к паролю:{' '}
          <b>
            8 символов, включая заглавные и маленькие буквы, не менее 1 цифры и
            1 специального символа
          </b>{' '}
          .
        </ol>
      </ul>
      <p>
        Согласно &quot;Инструкции по организации парольной защиты&quot;,
        необходимо производить ротацию пароля не реже одного раза в 3 месяца на
        личных учётных записях и один раз в год на сервисных учётных записях.
      </p>
      <p>По техническим вопросам просьба обращаться в ИТ отдел.</p>
    </>
  );
};

export default ChangePassword;
