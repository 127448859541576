import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  containerDatePicker: {
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
    '& input': {
      '&:focus': {
        borderColor: '#00a0e9',
        outline: 'none',
      },
      '&:hover': {
        borderColor: '#00a0e9',
        outline: 'none',
      },
      padding: '0 10px',
      boxSizing: 'border-box',
      backgroundColor: '#fff',
      border: '1px solid #fff',
      height: 50,
      borderRadius: 5,
      width: '100%',
    },
  },
}));

const CustomDatePicker = ({ selected, onChange, dateFormat }) => {
  const classes = useStyles();

  useEffect(() => {
    registerLocale('ru', ru);
  }, []);

  return (
    <div className={classes.containerDatePicker}>
      <DatePicker
        selected={selected}
        onChange={onChange}
        showTimeSelect
        timeFormat="HH:mm"
        dateFormat={dateFormat}
        timeIntervals={60}
        timeCaption="Время"
        locale={ru}
      />
    </div>
  );
};

CustomDatePicker.propTypes = {
  selected: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  dateFormat: 'dd MMMM yyyy., HH:mm',
};

export default CustomDatePicker;
