import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Typography } from '@material-ui/core';
import Button from 'components/ui/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getFieldsYup, getObjectElement } from 'functions/helpers';
import CustomTextField from 'components/ui/TextField';

const BitrixTaskForm = ({ title, fields, initialState, sendBitrixTask }) => {
  const validationSchema = yup.object(getFieldsYup(fields));

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: sendBitrixTask,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid item sm={title ? 10 : 12} xs={12} className="contentCenter">
        <Grid container spacing={3}>
          {title && (
            <Grid item xs={12}>
              <Typography variant="h1">Bitrix: {title}</Typography>
            </Grid>
          )}
          {fields.map(({ name, disabled, value, label, type = 'text' }) => {
            const touched = getObjectElement(formik.touched, name);
            const errors = getObjectElement(formik.errors, name);
            return (
              <Grid item key={name} xs={12} sm={type === 'text' ? 6 : 12}>
                <CustomTextField
                  type={type}
                  name={name}
                  disabled={disabled}
                  defaultValue={value}
                  fullWidth
                  label={label}
                  autoComplete="off"
                  variant="filled"
                  value={getObjectElement(formik.values, name)}
                  onChange={formik.handleChange}
                  error={touched && Boolean(errors)}
                  helperText={touched && errors}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={3} className="gridPadding">
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item sm={4} className="gridCenter">
            <Button color="primary" type="submit" fullWidth>
              Поставить задачу
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

BitrixTaskForm.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array.isRequired,
  initialState: PropTypes.object.isRequired,
  sendBitrixTask: PropTypes.func.isRequired,
};

export default BitrixTaskForm;
