import React, { useState } from 'react';
import Button from 'components/ui/Button';
import {
  Grid,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Radio,
  Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TabPanel from 'components/ui/TabPanel';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  getFieldsYup,
  getObjectElement,
  setObjectElement,
} from 'functions/helpers';
import CustomTextField from 'components/ui/TextField';

const forms = [
  {
    type: 1,
    title: 'Подключение',
    token: '2c3a7c83-d2ed-4869-9632-58e5595236dc',
    elmaType: '1',
    fields: [
      { label: 'Имя', name: 'Imya', req: true },
      { label: 'Номер телефона', name: 'NomerTelefona', req: true },
      { label: 'Email', name: 'EMail', req: true },
      { label: 'Примечание', name: 'Primechanie', req: false },
    ],
  },
  {
    type: 2,
    title: 'Восстановление пароля от ПО',
    token: '5ebd38d3-7a09-47ac-910c-684185e13bf5',
    elmaType: '1',
    fields: [
      { label: 'Имя', name: 'Imya', req: true },
      { label: 'Номер телефона', name: 'NomerTelefona', req: true },
      { label: 'Email', name: 'EMail', req: true },
    ],
  },
  {
    type: 3,
    title: 'Переустановка ПО',
    token: '56fc691e-2931-4709-b1df-f0e8f01eba60',
    elmaType: '1',
    fields: [
      { label: 'Имя', name: 'Imya', req: true },
      { label: 'Номер телефона', name: 'NomerTelefona', req: true },
      { label: 'Email', name: 'EMail', req: true },
    ],
  },
  {
    type: 4,
    title: 'Запрос на фискальный отчет',
    token: '50e60c14-c4e0-46dc-8484-fd6b823c7efc',
    elmaType: '1',
    fields: [
      { label: 'Имя', name: 'Imya', req: true },
      { label: 'Номер телефона', name: 'NomerTelefona', req: true },
      { label: 'Email', name: 'EMail', req: true },
    ],
  },
  {
    type: 5,
    title: 'Блокировка',
    token: '2a029746-18cd-4f25-90a8-1cf01744d03b',
    elmaType: '1',
    fields: [
      { label: 'Имя', name: 'Imya', req: true },
      { label: 'Номер телефона', name: 'NomerTelefona', req: true },
      { label: 'Email', name: 'EMail', req: true },
      { label: 'Памятка', name: 'Pamyatka', req: true },
    ],
  },

  {
    type: 6,
    title: 'Узнать задолженность',
    token: '5961e335-5c1c-40d4-b151-47b5997d69cd',
    elmaType: '1',
    fields: [
      { label: 'Имя', name: 'Imya', req: true },
      { label: 'Номер телефона', name: 'NomerTelefona', req: true },
      { label: 'Email', name: 'EMail', req: true },
    ],
  },
  {
    type: 7,
    title: 'Новая точка',
    token: '0c1ad534-f556-48a9-bdc9-d8f938a6508d',
    elmaType: '1',
    fields: [
      { label: 'Имя', name: 'Imya', req: true },
      { label: 'Номер телефона', name: 'NomerTelefona', req: true },
      { label: 'Email', name: 'EMail', req: true },
    ],
  },
  {
    type: 8,
    title: 'Временная блокировка',
    token: '2a029746-18cd-4f25-90a8-1cf01744d03b',
    elmaType: '1',
    fields: [
      { label: 'Имя', name: 'Imya', req: true },
      { label: 'Номер телефона', name: 'NomerTelefona', req: true },
      { label: 'Email', name: 'EMail', req: true },
      { label: 'Памятка', name: 'PAmyatka', req: true },
    ],
  },
];

let mail = {
  1: {
    files: [
      './files/Договор Фискализация версия 2.1 (оферта) Fiscal24.docx',
      './files/Анкета агента-ИП.doc',
      './files/Анкета агента-ЮЛ.doc',
    ],
  },
  2: { files: ['./files/Письмо востановление пароля от ПО.docx'] },
  3: { files: ['./files/письмо переустановка и раблок. Fiscal.docx'] },
  4: { files: ['./files/Письмо фискальный отчет Fiscal.docx'] },
  5: { files: ['./files/письмо на блокировку Fiscal.docx'] },
  8: { files: ['./files/письмо на временную блокировку Fiscal.docx'] },
};

const FiscalForms = ({ sendMail, sendTask }) => {
  const [form1Type, setform1Type] = useState(1);
  const [valueSelect, setValue] = useState(1);
  const state = {
    Imya: '',
    NomerTelefona: '',
    EMail: '',
    PAmyatka: '',
    Primechanie: '',
  };

  const setRadio = (e) => {
    setform1Type(Number(e.target.value));
  };

  const sendTaskFunc = (_, { resetForm }) => {
    let form = document.getElementById('formFiscal');
    let inputs = form.getElementsByTagName('input');
    let type = 0;
    let token = '';
    let elmaType = '';

    let formFields = {};

    for (let i in inputs) {
      const inputItem = getObjectElement(inputs, i);
      if (typeof inputItem !== 'undefined') {
        const inpValue = inputItem.value;
        const inpName = inputItem.name;

        switch (inputItem.dataset.type) {
          case 'data':
            formFields = setObjectElement(formFields, inpName, inpValue);
            break;
          case 'params':
            if (inpName === 'token') {
              token = inpValue;
            } else if (inpName === 'type') {
              type = inpValue;
            } else {
              elmaType = inpValue;
            }
            break;
          default:
            break;
        }
      }
    }

    if (
      formFields.EMail !== undefined &&
      getObjectElement(mail, type) !== undefined
    ) {
      let docs = getDocs(type, mail);
      sendMail({
        Title: 'Документы Fiscal24',
        Body: 'fiscaldocs.html',
        From: 'Fiscal24 <noreply@kassa24.kz>',
        ReplyTo: 'info@fiscal24.kz',
        Attachment: docs,
        Recipient: formFields.EMail,
      });
    }
    sendTask(token, formFields, elmaType).then((response) => {
      resetFormHundler(response, resetForm);
    });
  };

  const getDocs = (type, mailList) => {
    const mailListItem = getObjectElement(mailList, type);
    let docs = mailListItem.files;
    if (type === 1) {
      docs = [
        mailListItem.files[0],
        getObjectElement(mailListItem.files, Number(form1Type)),
      ];
    }

    return docs;
  };

  const resetFormHundler = (response, resetForm) => {
    if (response !== undefined) {
      resetForm();
    }
  };

  const validationSchema = yup.object(
    getFieldsYup(getObjectElement(forms, valueSelect - 1).fields)
  );

  const formik = useFormik({
    initialValues: state,
    validationSchema: validationSchema,
    onSubmit: sendTaskFunc,
  });

  const handleChange = (event) => {
    formik.resetForm();
    setValue(event.target.value);
  };

  return (
    <div>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={10}>
          <Typography variant="h1" align="center">
            Постановка задачи:{' '}
            {forms.filter((item) => item.type === valueSelect)[0].title}
          </Typography>
        </Grid>
      </Grid>
      <Grid sm={8} xs={12} className="contentCenter" item>
        <Grid container spacing={3}>
          <Grid sm={12} xs={12} item>
            <div className="customInp customSelect">
              <FormControl>
                <Select defaultValue={1} fullWidth onChange={handleChange}>
                  {forms.map((form) => (
                    <MenuItem value={form.type} key={form.type}>
                      {form.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {forms.map((form, i) => (
              <TabPanel index={form.type} key={form.type} value={valueSelect}>
                {i === 0 && (
                  <Grid item xs={12} align="left">
                    <Radio
                      id="r1"
                      name="opts"
                      value="1"
                      onClick={setRadio}
                      checked={form1Type === 1}
                      color="primary"
                    />
                    <label htmlFor="r1">ИП</label>

                    <Radio
                      id="r2"
                      name="opts"
                      value="2"
                      onClick={setRadio}
                      checked={form1Type === 2}
                      color="primary"
                    />
                    <label htmlFor="r2">ТОО</label>
                  </Grid>
                )}

                <form id="formFiscal" onSubmit={formik.handleSubmit}>
                  <input
                    type="hidden"
                    data-type="params"
                    name="token"
                    value={form.token}
                  />
                  <input
                    type="hidden"
                    data-type="params"
                    name="type"
                    value={form.type}
                  />
                  <input
                    type="hidden"
                    data-type="params"
                    name="elmaType"
                    value={form.elmaType}
                  />
                  <Grid container spacing={3}>
                    {form.fields.map((field) => {
                      const value = getObjectElement(formik.values, field.name);
                      const touched = getObjectElement(
                        formik.touched,
                        field.name
                      );
                      const errors = getObjectElement(
                        formik.errors,
                        field.name
                      );
                      return (
                        <Grid key={field.name} item xs={12} sm={4}>
                          <CustomTextField
                            type="text"
                            name={field.name}
                            label={field.label}
                            fullWidth
                            variant="filled"
                            inputProps={{
                              'data-type': 'data',
                            }}
                            value={value}
                            onChange={formik.handleChange}
                            error={touched && Boolean(errors)}
                            helperText={touched && errors}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid container spacing={3} className="gridPadding">
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={4} className="gridCenter">
                      <Button fullWidth color="primary" type="submit">
                        Поставить задачу
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

FiscalForms.propTypes = {
  sendMail: PropTypes.func.isRequired,
  sendTask: PropTypes.func.isRequired,
};

export default FiscalForms;
