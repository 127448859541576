import MyPostTask from 'screens/Bitrix/MyPost/Other';
import PostOfficeNotWork from 'screens/Bitrix/MyPost/PostOfficeNotWork';
import InstallTerminal from 'screens/Bitrix/OwnNetwork/InstallTerminal';
import Other from 'screens/Bitrix/OwnNetwork/Other';
import RentNotPaid from 'screens/Bitrix/OwnNetwork/RentNotPaid';
import TerminalDoesntWork from 'screens/Bitrix/OwnNetwork/TerminalDoesntWork';
import BecomeProvider from 'screens/Bitrix/Provider/BecomeProvider';
import ProblemWithTheProvider from 'screens/Bitrix/Provider/ProblemWithTheProvider';

export const OWN_NETWORK_BITRIX_TASK_TYPES = {
  RENT_NOT_PAID: 'rentNotPaid',
  TERMINAL_DOESNT_WORK: 'terminalDoesntWork',
  INSTALL_TERMINAL: 'installTerminal',
  OTHER: 'ownNetworkOther',
};

export const OWN_NETWORK_TASK_TYPES = [
  {
    label: 'Не работает терминал',
    id: OWN_NETWORK_BITRIX_TASK_TYPES.TERMINAL_DOESNT_WORK,
    Component: TerminalDoesntWork,
  },
  {
    label: 'Установить терминал',
    id: OWN_NETWORK_BITRIX_TASK_TYPES.INSTALL_TERMINAL,
    Component: InstallTerminal,
  },
  {
    label: 'Не оплачена аренда',
    id: OWN_NETWORK_BITRIX_TASK_TYPES.RENT_NOT_PAID,
    Component: RentNotPaid,
  },
  {
    label: 'Другое',
    id: OWN_NETWORK_BITRIX_TASK_TYPES.OTHER,
    Component: Other,
  },
];

export const PROVIDER_BITRIX_TASK_TYPES = {
  BECOME_PROVIDER: 'becomeProvider',
  PROBLEM_WITH_PROVIDER: 'problemWithTheProvider',
};

export const PROVIDER_TASK_TYPES = [
  {
    label: 'Стать провайдером',
    id: PROVIDER_BITRIX_TASK_TYPES.BECOME_PROVIDER,
    Component: BecomeProvider,
  },
  {
    label: 'Проблема с провайдером',
    id: PROVIDER_BITRIX_TASK_TYPES.PROBLEM_WITH_PROVIDER,
    Component: ProblemWithTheProvider,
  },
];

export const MYPOST_BITRIX_TASK_TYPES = {
  POSTOFFICE_NOT_WORKING: 'postOfficeNotWorking',
  OTHER: 'myPost',
};

export const MYPOST_TASK_TYPES = [
  {
    label: 'Не работает постамат',
    id: MYPOST_BITRIX_TASK_TYPES.POSTOFFICE_NOT_WORKING,
    Component: PostOfficeNotWork,
  },
  {
    label: 'Другое',
    id: MYPOST_BITRIX_TASK_TYPES.OTHER,
    Component: MyPostTask,
  },
];
