import React from 'react';
import Button from 'components/ui/Button';
import {
  Grid,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel,
  Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getFieldsYup } from 'functions/helpers';
import CustomTextField from 'components/ui/TextField';

const OtherForm = ({ sendTask }) => {
  const SrokVypolneniya = moment()
    .add(10, 'days')
    .format('YYYY-MM-DD 23:59:59');
  const state = { NekiyTekst: '', TipZadachi: '' };
  const fields = [
    { name: 'TipZadachi', label: 'Выберите отдел', req: true },
    { name: 'NekiyTekst', label: 'Введите текст', req: true },
  ];
  const options = [
    { value: '1', label: 'Fiscal24' },
    { value: '3', label: 'Провайдеры ' },
    { value: '4', label: 'Plus24 ' },
    { value: '5', label: 'MyPost' },
    { value: '6', label: 'Zharnama24' },
  ];

  const setTask = (values, { resetForm }) => {
    let token = 'b455f375-6515-4715-89a8-8c388e820afc';
    const data = {
      NekiyTekst: values.NekiyTekst,
      TipZadachi: values.TipZadachi,
      SrokVypolneniya,
    };

    sendTask(token, data, values.TipZadachi).then((response) => {
      if (response !== undefined) {
        resetForm();
      }
    });
  };

  const validationSchema = yup.object(getFieldsYup(fields));

  const formik = useFormik({
    initialValues: state,
    validationSchema: validationSchema,
    onSubmit: setTask,
  });

  return (
    <Grid sm={5} xs={12} item className="contentCenter">
      <Typography variant="h1">Постановка задачи</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div
              className={`customSelect ${
                formik.touched['TipZadachi'] && formik.errors['TipZadachi']
                  ? 'customSelectErr'
                  : ''
              }`}
            >
              <FormControl variant="filled">
                <InputLabel id="tasktype-label">Выберите отдел</InputLabel>
                <Select
                  labelId="tasktype-label"
                  defaultValue=""
                  options={options}
                  name="TipZadachi"
                  onChange={formik.handleChange}
                  variant="filled"
                >
                  {options.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                {formik.touched['TipZadachi'] && formik.errors['TipZadachi']}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              type="textarea"
              rows="4"
              fullWidth
              label="Введите текст"
              variant="filled"
              multiline
              name="NekiyTekst"
              value={formik.values['NekiyTekst']}
              onChange={formik.handleChange}
              error={
                formik.touched['NekiyTekst'] &&
                Boolean(formik.errors['NekiyTekst'])
              }
              helperText={
                formik.touched['NekiyTekst'] && formik.errors['NekiyTekst']
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="gridPadding">
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6} className="gridCenter">
            <Button color="primary" fullWidth type="submit">
              Поставить задачу
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

OtherForm.propTypes = {
  sendTask: PropTypes.func.isRequired,
};

export default OtherForm;
