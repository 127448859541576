import swal from 'sweetalert';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from 'components/ui/Button';
import TextField from 'components/ui/TextField';
import Modal from 'components/ui/Modal';
import { RepayTransaction, mobileOperators } from 'functions/repay';

import ServicesSelectRepay from './servicesSelect';
import { getObjectElement } from 'functions/helpers';

const mobileNames = {
  1375: 'Beeline',
  1764: 'Kcell',
  1765: 'Activ',
  476: 'Tele2',
  3421: 'Altel',
};

const RepayModal = ({ toggleModal, repayModalData: oldTr }) => {
  const clientData = useSelector((state) => state.main.clientData);
  const [newTr, setNewTr] = useState({
    Sn: oldTr.TransId || 0,
    IdService: 1 * oldTr.ServiceId || 0,
    Sum: 1 * oldTr.Amount - 1 * oldTr.Commission || 0,
    Account: oldTr.Account || '',
    Commission: 0,
    ID: oldTr.IDPayments || 0,
    Email: clientData.Email || '',
  });

  const closeRepayModal = () => {
    toggleModal();
  };

  // обновление информации по новой транзакции
  const setNewTransactionData = (field, value) =>
    setNewTr({ ...newTr, [field]: value });

  //перепроведение
  const repayPayment = () => {
    swal('Запрос отправлен', 'Пожалуйста, дождитесь ответа сервера', 'success');
    setTimeout(() => RepayTransaction({ ...newTr }, oldTr), 2000);
    closeRepayModal();
  };

  return (
    <>
      <Modal open onClose={closeRepayModal} fullScreen>
        <div style={{ margin: 50 }}>
          <h2>Перепроведение платежа</h2>
          <div className="gridHalf">
            <div>
              <div className="gridSmall">
                <div className="gridSpan2">
                  <ServicesSelectRepay
                    selectedValue={oldTr.ServiceId}
                    isDisabled
                    idAgent={
                      typeof oldTr.AgId !== 'undefined'
                        ? oldTr.AgId.Int64
                        : null
                    }
                  />
                </div>
              </div>
              <div className="gridSmall">
                <b>Номер транзакции</b>
                <TextField type="number" value={oldTr.TransId} disabled />
              </div>
              <div className="gridSmall">
                <b>Сумма</b>
                <TextField type="number" value={oldTr.Amount} disabled />
              </div>
              <div className="gridSmall">
                <b>Комиссия</b>
                <TextField type="number" value={oldTr.Commission} disabled />
              </div>
              <div className="gridSmall">
                <b>Номер счета</b>
                <TextField type="text" value={oldTr.Account} disabled />
              </div>
            </div>

            <div>
              <div className="gridSmall">
                <div className="gridSpan2">
                  <ServicesSelectRepay
                    placeholder="Выберите сервис для перепроведения"
                    setData={(IdService) =>
                      setNewTransactionData('IdService', Number(IdService))
                    }
                    selectedValue={newTr.IdService}
                    isDisabled={false}
                    idAgent={
                      typeof oldTr.AgId !== 'undefined'
                        ? oldTr.AgId.Int64
                        : null
                    }
                  />
                </div>
              </div>
              <div className="gridSmall">
                <b>Номер транзакции</b>
                <TextField type="number" disabled />
              </div>
              <div className="gridSmall">
                <b>Сумма</b>
                <TextField type="number" value={newTr.Sum} disabled />
              </div>
              <div className="gridSmall">
                <b>Комиссия</b>
                <TextField type="number" value={newTr.Commission} disabled />
              </div>
              <div className="gridSmall">
                <b>Номер счета</b>
                <TextField
                  type="text"
                  value={newTr.Account}
                  name="Account"
                  onInput={(e) =>
                    setNewTransactionData('Account', e.target.value.trim())
                  }
                />
              </div>

              <div style={{ display: 'flex' }}>
                {mobileOperators.includes(Number(oldTr.ServiceId)) &&
                  mobileOperators.map(
                    (serviceId) =>
                      serviceId !== oldTr.ServiceId && (
                        <Button
                          color="default"
                          size="small"
                          className="mdc-theme--secondary-bg"
                          value={serviceId}
                          onClick={() =>
                            setNewTransactionData('IdService', serviceId)
                          }
                        >
                          {getObjectElement(mobileNames, serviceId)}
                        </Button>
                      )
                  )}
              </div>
            </div>
          </div>

          <div
            style={{
              width: '30%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              fullWidth
              color="primary"
              className="mdc-theme--secondary-bg"
              onClick={repayPayment}
            >
              Перепровести
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

RepayModal.propTypes = {
  toggleModal: PropTypes.func,
  repayModalData: PropTypes.object,
};

export default RepayModal;
