import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/icons/Link';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { Typography } from '@material-ui/core';

import { FAQ_LINK } from 'constants/main';
import { copyText, getParsedHtmlContent } from 'functions/helpers';

const FaqItem = ({
  item,
  requestTabID,
  selectedTabID,
  setShowAlert,
  faqTabKey,
  faqKey,
}) => {
  const ref = useRef(null);
  const copyQuestionLink = useCallback(
    (itemKey) => () => {
      copyText(`${FAQ_LINK}${selectedTabID}#${itemKey}`);
      setShowAlert(true);
    },
    [selectedTabID, setShowAlert]
  );

  useEffect(() => {
    if (Number(requestTabID) === Number(selectedTabID)) {
      const hash = window.location.hash;
      const hashString = hash.substring(1, hash.length);
      const divItem = document.getElementById(hashString);
      if (divItem) scrollWithTimeout(divItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabID, ref]);

  const scrollWithTimeout = (el) => {
    if (!el) return;
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
  };

  return (
    <section
      className="infoItem infoItemGridIcon"
      id={`question_${faqTabKey}_${faqKey}`}
      ref={ref}
    >
      <QuestionAnswerIcon className="iconBlue" />
      <div>
        <button
          className="btnWithoutStyles"
          onClick={copyQuestionLink(`question_${faqTabKey}_${faqKey}`)}
        >
          <Typography variant="h2" className="iconBlue noBold">
            <>
              {item.title}
              <span className="copyIcon">
                <Link color="secondary" fontSize="small" />
              </span>
            </>
          </Typography>
        </button>

        {/* eslint-disable-next-line react/no-danger */}
        {getParsedHtmlContent(item.text)}
      </div>
    </section>
  );
};

FaqItem.propTypes = {
  item: PropTypes.object.isRequired,
  requestTabID: PropTypes.string,
  selectedTabID: PropTypes.number.isRequired,
  setShowAlert: PropTypes.func,
  faqTabKey: PropTypes.number.isRequired,
  faqKey: PropTypes.number.isRequired,
};

export default FaqItem;
