import React, { useState } from 'react';
import Api from 'api/Api';
import Button from 'components/ui/Button';
import DatePicker from 'components/ui/DatePicker';
import {
  Table,
  Grid,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  TableHead,
} from '@material-ui/core';
import {
  exportReport,
  getHumanDateFromTimestamp,
  getObjectElement,
} from 'functions/helpers';
import moment from 'moment';

const tableHead = ['Отдел', 'Общее кол-во', 'Завершенных', 'Незавершенных'];
let headKey = 0;

const ReportElma = () => {
  const [reports, setReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [dateFrom, setDateFrom] = useState(
    new Date(moment().subtract(1, 'days'))
  );
  const [dateTo, setDateTo] = useState(new Date());
  const [checkedDetails, setCheckedDetails] = useState(1);

  //один формат даты для дейтпикера, второй для запроса в базу
  const handleChangeDate_from = (date) => {
    setDateFrom(date);
  };

  const handleChangeDate_to = (date) => {
    setDateTo(date);
  };

  const getReport = () => {
    let dateFromApi = new Date(dateFrom);
    dateFromApi.setSeconds(0);
    dateFromApi = (dateFromApi / 1000) | 0;

    let dateToApi = new Date(dateTo);
    dateToApi.setSeconds(59);
    dateToApi = (dateToApi / 1000) | 0;

    let params = { Date_from: dateFromApi, Date_to: dateToApi };
    Api.getReport('elma', JSON.stringify(params)).then((response) => {
      if (response !== undefined) {
        setReports(response.data);
        setUsers(response.users);
      }
    });
  };

  const showDetail = (e) => {
    setCheckedDetails(e.target.dataset.details);
  };

  const formatUser = (id) => {
    const user = users.filter(
      (val) => val.UserId.toString() === id.toString()
    )[0];
    if (typeof user !== 'undefined') {
      return user.Name + ' ' + user.lastname;
    }
  };

  const getReportByAPI = () => {
    return reports.map((item) => {
      item.details = item.details.map((detail) => {
        detail.userName = formatUser(detail.userid);
        return detail;
      });

      return item;
    });
  };

  const exportReportHandler = () => {
    const reportByApi = getReportByAPI();
    exportReport(reportByApi, 'elma');
  };

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item sm={10} xs={12}>
          <Typography variant="h5">Отчет: ELMA</Typography>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Button
            color="primary"
            fullWidth
            onClick={exportReportHandler}
            disabled={!reports.length}
          >
            Экспорт в Excel
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={3} xs={6}>
          <DatePicker selected={dateFrom} onChange={handleChangeDate_from} />
        </Grid>
        <Grid item sm={3} xs={6}>
          <DatePicker selected={dateTo} onChange={handleChangeDate_to} />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Button
            color="primary"
            className="noMarg"
            onClick={() => getReport()}
            fullWidth
          >
            Показать
          </Button>
        </Grid>
      </Grid>
      {reports.length > 0 && (
        <div className="searchResult">
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((item) => {
                        return <TableCell key={headKey++}>{item}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reports.map((val, i) => (
                      <TableRow key={val.name}>
                        {val.data.all > 0 && (
                          <TableCell>
                            <button
                              type="button"
                              className="btnWithoutStyles"
                              data-details={i}
                              onClick={showDetail}
                            >
                              {val.name}
                            </button>
                          </TableCell>
                        )}
                        {val.data.all === 0 && (
                          <TableCell>{val.name}</TableCell>
                        )}
                        <TableCell>{val.data.all}</TableCell>
                        <TableCell>{val.data.compl}</TableCell>
                        <TableCell>{val.data.none}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item sm={6} xs={12}>
              {getObjectElement(reports, Number(checkedDetails)) !==
                undefined &&
                getObjectElement(reports, Number(checkedDetails)).details
                  .length > 0 && (
                  <div className="reportDetail">
                    <Typography variant="h3">Подробная информация</Typography>
                    {Object.keys(
                      getObjectElement(reports, Number(checkedDetails)).details
                    ).map((key) => {
                      const repItem = getObjectElement(
                        reports.at,
                        checkedDetails
                      ).details;
                      const repInfo = getObjectElement(repItem, Number(key));

                      return (
                        <div
                          className={
                            repInfo.data.ElmaStatus === 1
                              ? 'elmaBlock'
                              : 'elmaBlock styleCompl'
                          }
                          key={repInfo.timestamp}
                        >
                          <b>{getHumanDateFromTimestamp(repInfo.timestamp)}</b>
                          <br />
                          <b>{formatUser(repInfo.userid)}</b>
                          {Object.keys(repInfo.data.Params).map(
                            (dataKey) =>
                              getObjectElement(
                                repInfo.data.Params,
                                Number(dataKey)
                              ) !== '' && (
                                <div key={dataKey}>
                                  {dataKey} :{' '}
                                  {getObjectElement(
                                    repInfo.data.Params,
                                    Number(dataKey)
                                  )}
                                </div>
                              )
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default ReportElma;
