import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import Api from 'api/Api';
import { Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { a11yProps } from 'functions/helpers';
import TabPanel from 'components/ui/TabPanel';
import HowToBecomeADealer from 'screens/Bitrix/HowToBecomeADealer';
import Provider from 'screens/Bitrix/Provider';
import OwnNetwork from 'screens/Bitrix/OwnNetwork';
import OtherTask from 'screens/Bitrix/OtherTask';
import UserIdentification from '../Kassa24/person/activation/ActivationPerson';
import MyPostTask from 'screens/Bitrix/MyPost';
import { setDirectorsList } from 'features/mainSlice';

export const bitrixMenu = [
  'Как стать дилером',
  'Провайдер',
  'Собственная сеть',
  'Другое',
  'Идентификация',
  'MyPost',
];
let bitrixKey = 0;

const BitrixTask = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo || {});
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const allowedRole =
    userInfo.isB2b ||
    userInfo.isManager ||
    userInfo.isSupervisor ||
    userInfo.isAdmin;

  useEffect(() => {
    getDirectorsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDirectorsList = () => {
    Api.getDirectorsList().then((response) => {
      if (typeof response !== 'undefined') {
        dispatch(setDirectorsList(response.data));
      }
    });
  };

  const sendBitrixTask = (data, type) => {
    Api.setTaskInBitrix(data, type)
      .then((response) => {
        if (typeof response !== 'undefined') {
          swal('ОК!', 'Задача в битриксе создана', 'success');
        }
      })
      .catch();
  };

  const getTabPanelIndex = (index) => (allowedRole ? index : index - 1);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        className="customTabs"
        scrollButtons="auto"
        variant="scrollable"
        indicatorColor="primary"
      >
        {bitrixMenu.map((item, i) => {
          return i === 1 ? (
            allowedRole && (
              <Tab key={bitrixKey++} label={item} {...a11yProps(i)} />
            )
          ) : (
            <Tab key={bitrixKey++} label={item} {...a11yProps(i)} />
          );
        })}
      </Tabs>
      <TabPanel value={value} index={0}>
        <HowToBecomeADealer
          title={bitrixMenu[0]}
          sendBitrixTask={sendBitrixTask}
        />
      </TabPanel>
      {allowedRole && (
        <TabPanel value={value} index={1}>
          <Provider sendBitrixTask={sendBitrixTask} title={bitrixMenu[1]} />
        </TabPanel>
      )}
      <TabPanel value={value} index={getTabPanelIndex(2)}>
        <OwnNetwork sendBitrixTask={sendBitrixTask} title={bitrixMenu[2]} />
      </TabPanel>
      <TabPanel value={value} index={getTabPanelIndex(3)}>
        <OtherTask sendBitrixTask={sendBitrixTask} title={bitrixMenu[3]} />
      </TabPanel>
      <TabPanel value={value} index={getTabPanelIndex(4)}>
        <Typography variant="h1" align="center">
          Bitrix: {bitrixMenu[4]}
        </Typography>
        <UserIdentification isItUserActivation={false} />
      </TabPanel>
      <TabPanel value={value} index={getTabPanelIndex(5)}>
        <MyPostTask sendBitrixTask={sendBitrixTask} title={bitrixMenu[5]} />
      </TabPanel>
    </>
  );
};
export default BitrixTask;
