import React, { useMemo, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

import { a11yProps, getItemsByUserRole } from 'functions/helpers';
import TabPanel from 'components/ui/TabPanel';

import SearchTransaction from './transaction/searchTransaction';
import TransactionsResult from './transaction/transactionsResult';
import SearchTerminal from './terminal/searchTerminal';
import TerminalsTable from './terminal/terminalsTable';
import SearchPerson from './person/searchPerson';
import PersonInfo from './person/personInfo';
import PersonOperations from './person/personOperations';
import KaspiTreatment from './kaspi/KaspiForm';
import CashOut from './cashOut/';
import SattyZhuldyz from './sattyZhuldyz';
import useMenu from 'hooks/useMenu';
import { useUser } from 'hooks';
import { USER_AUDITOR } from 'constants/user';

const useStyles = makeStyles(() => ({
  customTabs: {
    margin: '0 0 30px 0',
  },
}));

const Kassa24 = () => {
  const { kassa24Tabs } = useMenu();
  const { userRoles } = useUser();

  const [currentTab, setCurrentTab] = useState(0);
  const classes = useStyles();

  const tabsContent = useMemo(() => {
    return [
      {
        component: (
          <>
            <SearchTransaction />
            <TransactionsResult />
          </>
        ),
        id: 'tabs1',
      },
      {
        component: (
          <>
            <SearchTerminal />
            <TerminalsTable />
          </>
        ),
        deniedForRoles: [USER_AUDITOR],
        id: 'tabs2',
      },
      {
        component: (
          <>
            <SearchPerson />
            <PersonInfo />
            <PersonOperations />
          </>
        ),
        id: 'tabs3',
      },
      {
        component: <KaspiTreatment />,
        deniedForRoles: [USER_AUDITOR],
        id: 'tabs4',
      },
      {
        component: <CashOut />,
        deniedForRoles: [USER_AUDITOR],
        id: 'tabs5',
      },
      {
        component: <SattyZhuldyz />,
        deniedForRoles: [USER_AUDITOR],
        id: 'tabs6',
      },
    ];
  }, []);

  const tabsContentForUser = useMemo(() => {
    return getItemsByUserRole(tabsContent, userRoles);
  }, [userRoles, tabsContent]);

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={(_, newTab) => setCurrentTab(newTab)}
        scrollButtons="auto"
        variant="scrollable"
        className={classes.customTabs}
        style={{ justifyContent: 'space-between' }}
        indicatorColor="primary"
      >
        {kassa24Tabs.map((item, i) => (
          <Tab key={item.id} label={item.label} {...a11yProps(i)} />
        ))}
      </Tabs>
      {tabsContentForUser.map((content, key) => {
        return (
          <TabPanel value={currentTab} index={key} key={content.id}>
            {content.component}
          </TabPanel>
        );
      })}
    </>
  );
};

export default Kassa24;
