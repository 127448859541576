import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import ClosedIcon from '@material-ui/icons/Close';

const Modal = (props) => {
  const scrollStyle = !props.scrollableX ? 'hidden' : '';
  return (
    <Dialog {...props}>
      {props.dialogTitle ? <DialogTitle>{props.dialogTitle}</DialogTitle> : ''}
      <IconButton
        color="secondary"
        className="modalClose"
        onClick={props.onClose}
      >
        <ClosedIcon />
      </IconButton>
      <DialogContent
        dividers={props.dividers}
        style={{ overflowX: scrollStyle }}
      >
        {props.children}
      </DialogContent>
      {props.dialogActions ? (
        <DialogActions>{props.dialogActions}</DialogActions>
      ) : (
        ''
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  dividers: PropTypes.bool,
  onClose: PropTypes.func,
  dialogTitle: PropTypes.string,
  dialogActions: PropTypes.object,
  scrollableX: PropTypes.bool,
};

export default Modal;
