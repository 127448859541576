import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/ui/Modal';

const ShowCheckModal = ({ onClose, image }) => {
  const closeModalHandler = () => {
    onClose(false);
  };

  return (
    <Modal onClose={closeModalHandler} open scrollableX={false}>
      <img src={image} alt="checkImage" />
    </Modal>
  );
};

ShowCheckModal.propTypes = {
  onClose: PropTypes.func,
  image: PropTypes.string,
};

export default ShowCheckModal;
