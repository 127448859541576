import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    showProgress: false,
  },
  reducers: {
    showProgressBar: (state, action) => {
      state.showProgress = action.payload;
    },
  },
});

export const { showProgressBar } = uiSlice.actions;

export default uiSlice.reducer;
