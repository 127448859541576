import React, { useState } from 'react';
import swal from 'sweetalert';
import Api from 'api/Api';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { a11yProps, getFromLocalStorage } from 'functions/helpers';
import TabPanel from 'components/ui/TabPanel';
import MyPostForms from './FormMyPost';
import FiscalForms from './FormFiscal';
import OtherForm from './FormOther';

export const elmaMenu = ['mypost', 'fiscal24', 'другое'];
let elmaKey = 0;

const ElmaTask = () => {
  const [value, setValue] = useState(0);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const sendTask = (token, data, elmaType) => {
    swal('Запрос отправлен', 'Пожалуйста, дождитесь ответа сервера', 'success');
    return Api.sendElmaTask(token, data, elmaType)
      .then((response) => {
        if (response !== undefined) {
          swal('ОК!', 'отправлено', 'success');
          Api.log({
            logType: 'elmaTask',
            userid: Number(getFromLocalStorage('userId')),
            data: {
              token,
              elmaType,
              elmaID: Number(response.data),
              elmaStatus: 1,
              params: data,
            },
            timestamp: (Date.now() / 1000) | 0,
          });
        }
      })
      .catch();
  };

  const sendMail = (data) => {
    Api.sendMail(JSON.stringify(data));
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        className="customTabs"
        scrollButtons="auto"
        variant="scrollable"
        indicatorColor="primary"
      >
        {elmaMenu.map((item, i) => {
          return <Tab key={elmaKey++} label={item} {...a11yProps(i)} />;
        })}
      </Tabs>

      <TabPanel value={value} index={0}>
        <MyPostForms sendTask={sendTask} sendMail={sendMail} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FiscalForms sendTask={sendTask} sendMail={sendMail} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OtherForm sendTask={sendTask} />
      </TabPanel>
    </>
  );
};

export default ElmaTask;
