import { API_URL } from 'constants/api';
import ApiHelper from './api-helper';

export default class Api {
  static getTrans(data) {
    let url = API_URL + '/transaction';
    return ApiHelper.post(url, JSON.stringify(data));
  }

  static getTransOnepay(data) {
    let url = API_URL + '/transactionOnepay';
    return ApiHelper.post(url, JSON.stringify(data));
  }

  static getTerm(data) {
    let url = API_URL + '/terminal';
    return ApiHelper.post(url, data);
  }

  static getServices() {
    let url = API_URL + '/getServices';
    return ApiHelper.get(url);
  }

  static getReport(type, params) {
    let url = API_URL + '/getReport?type=' + type;
    return ApiHelper.post(url, params);
  }

  static exportReport(type, params) {
    let url = API_URL + '/exportReport?type=' + type;
    return ApiHelper.post(url, params);
  }

  static getWiki() {
    let url = API_URL + '/wiki';
    return ApiHelper.get(url);
  }

  static createWikiType(data) {
    let url = API_URL + '/createWikiType';
    return ApiHelper.post(url, data);
  }

  static deleteWikiType(data) {
    let url = API_URL + '/deleteWikiType';
    return ApiHelper.post(url, data);
  }

  static addUser(data) {
    let url = API_URL + '/addUser';
    return ApiHelper.post(url, data);
  }

  static resetPassword(data) {
    let url = API_URL + '/resetPassword';
    return ApiHelper.post(url, data);
  }

  static troubles(data) {
    let url = API_URL + '/troubles';
    return ApiHelper.post(url, data);
  }

  static saveWiki(data, type) {
    let url = API_URL + '/saveWiki?type=' + type;
    return ApiHelper.post(url, data);
  }

  static sendCheck(data) {
    let url = API_URL + '/sendCheck';
    return ApiHelper.post(url, data);
  }

  static sendListReceipts(data) {
    let url = API_URL + '/sendListCheck';
    return ApiHelper.post(url, data);
  }

  static sendCheckFromLk(data) {
    let url = API_URL + '/sendCheckFromLk';
    return ApiHelper.post(url, data);
  }

  static sendOnepayRep(data) {
    let url = API_URL + '/onepayRep';
    return ApiHelper.post(url, data);
  }

  static showCheck(data) {
    let url = API_URL + '/showCheck';
    return ApiHelper.post(url, data);
  }

  static repayTrans(data) {
    let url = API_URL + '/repay';
    return ApiHelper.post(url, data);
  }

  static troubleReport(data) {
    let url = API_URL + '/troubleReport';
    return ApiHelper.post(url, data);
  }

  static getOwnNetworkReport(type) {
    let url = API_URL + '/getOwnNetworkReport?type=' + type;
    return ApiHelper.get(url);
  }

  static getBlackListReport(data) {
    let url = API_URL + '/getBlackListReport';
    return ApiHelper.post(url, data);
  }

  static addToBlackList(data) {
    let url = API_URL + '/addToBlackList';
    return ApiHelper.post(url, data);
  }

  static removeFromBlackList(data) {
    let url = API_URL + '/removeFromBlackList';
    return ApiHelper.post(url, data);
  }

  static savePerson(data) {
    let newData = {
      request: {
        type: 150,
        body: {
          sourceType: 12, // Андромеда
          eventType: 3, //  Чек на почту
          platformType: 1, // Сайт
          serviceName: '',
          amount: 0,
          payMethodType: 4, // прочее
          cellNum: '',
          email: '',
          town: '',
          ...data,
        },
        sign: '',
      },
    };

    const url = API_URL + '/savePersonInfo';
    return ApiHelper.post(url, JSON.stringify(newData));
  }

  static log(data) {
    const url = API_URL + '/log';
    return ApiHelper.post(url, data);
  }

  static sendMail(data) {
    const url = API_URL + '/sendMail';
    return ApiHelper.post(url, data);
  }

  // static sendInvoices(data) {
  //   const url = API_URL + '/sendInvoices';
  //   return ApiHelper.post(url, data);
  // }

  static getInvoicesList(data) {
    const url = API_URL + '/getMypostInvoice';
    let request = ApiHelper.createMyPostRequest(data, 'invoice.search');
    return ApiHelper.post(url, request);
  }

  static getInvoiceDetail(data) {
    const url = API_URL + '/getMypostDetail';
    let request = ApiHelper.createMyPostRequest(data, 'invoice.detail');
    return ApiHelper.post(url, request);
  }

  static getPromotions() {
    const url = API_URL + '/promotions';
    return ApiHelper.get(url);
  }

  static getOperators() {
    let url = API_URL + '/getOperators';
    return ApiHelper.get(url);
  }

  static sendSMS(data) {
    const url = API_URL + '/sendSms';
    let request = ApiHelper.createMyPostRequest(data, 'package.sendAccessCode');
    return ApiHelper.post(url, request);
  }

  static sendElmaTask(token, data) {
    const url = API_URL + '/elmaTask';
    let request = ApiHelper.createElmaRequest(token, data);
    return ApiHelper.post(url, request);
  }

  static getNews() {
    const url = API_URL + '/news';
    return ApiHelper.get(url);
  }

  static getDealers() {
    const url = API_URL + '/getDealers';
    return ApiHelper.get(url);
  }

  static getServicesForAgent(data) {
    const url = API_URL + '/getServicesForAgent';
    return ApiHelper.post(url, data);
  }

  static setTaskInBitrix(data, type) {
    const url = API_URL + `/createBitrixTask?type=${type}`;
    return ApiHelper.post(url, data);
  }

  static getDirectorsList(data, type) {
    const url = API_URL + `/getDirectorsList`;
    return ApiHelper.post(url, data);
  }

  static searchPerson(data) {
    // Поиск в ЛК
    const url = API_URL + '/searchPerson';
    return ApiHelper.post(url, data);
  }

  static blockPerson(data) {
    const url = API_URL + '/blockPerson';
    return ApiHelper.post(url, data);
  }

  static activateThePerson(data) {
    const url = API_URL + '/activateThePerson';
    return ApiHelper.post(url, data);
  }

  static getPersonOperations(data) {
    const url = API_URL + '/getPersonOperations';
    return ApiHelper.post(url, data);
  }

  static getLkInfo(data) {
    const url = API_URL + '/getLkInfo';
    return ApiHelper.post(url, data);
  }

  static cancelPayment(data) {
    const url = API_URL + '/cancelPayment';
    return ApiHelper.post(url, data);
  }

  static searchKaspiTransaction(data) {
    const url = API_URL + '/searchKaspiTransaction';
    return ApiHelper.post(url, data);
  }

  static searchSattyZhyldyz(data) {
    const url = API_URL + '/searchSattyZhyldyz';
    return ApiHelper.post(url, data);
  }

  static searchCashOut(data) {
    const url = API_URL + '/searchCashOut';
    return ApiHelper.post(url, data);
  }

  static searchProblemPaymentLog(data) {
    const url = API_URL + '/searchProblemPaymentLog';
    return ApiHelper.post(url, data);
  }

  static uploadFilesToTask(data) {
    const url = API_URL + '/uploadFilesToTask';
    return ApiHelper.post(url, data);
  }

  static login(data) {
    const url = API_URL + '/login';
    return ApiHelper.post(url, data);
  }

  static getCurrentUserInfo(data) {
    const url = API_URL + '/getCurrentUserInfo';
    return ApiHelper.post(url, data);
  }

  static getFiscalOperations(data) {
    let url = API_URL + '/fiscalOperations';
    return ApiHelper.post(url, JSON.stringify(data));
  }

  static getAgentsCurators(data) {
    let url = API_URL + '/agentsCurators';
    return ApiHelper.get(url);
  }
}
