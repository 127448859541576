import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import Api from 'api/Api';
import MessageIcon from '@material-ui/icons/Message';
import SearchIcon from '@material-ui/icons/Search';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {
  IconButton,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Collapse,
  Typography,
} from '@material-ui/core';
import { getObjectElement, setObjectElement } from 'functions/helpers';

const tableHead = [
  'ID заказа',
  'Агент',
  'Код заказа',
  'ФИО',
  'Номер',
  'Стоимость',
  'Оплачено',
  '',
];
let headKey = 0;
let operationKey = 0;

const PostTable = ({ list, title }) => {
  const userInfo = useSelector((state) => state.user.userInfo || {});
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const postomatStatus = {
    1: 'online',
    0: 'offline',
  };

  //поиск расширенной информации о посылках
  const searchDetail = (IDInvoice) => {
    let invoiceDublicate = invoiceDetail;
    let data1 = { IDInvoice: IDInvoice };
    Api.getInvoiceDetail(data1).then((response) => {
      if (response !== undefined) {
        invoiceDublicate = setObjectElement(
          invoiceDublicate,
          IDInvoice,
          response.Invoice
        );
        setInvoiceDetail(invoiceDublicate);
      }
    });
  };

  //отправка смс
  const sendSms = (IDPackage) => {
    swal({
      title: 'Вы уверены?',
      text: 'Переотправить клиенту SMS?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((send) => {
      if (send) {
        let data = { IDPackage: IDPackage };
        Api.sendSMS(data).then(() => {
          swal('ОК!', 'SMS отправлено', 'success');
        });
      }
    });
  };

  const sendReport = (id) => {
    let query = {
      idTerminal: Number(id),
      idAuthor: userInfo.id,
      loginAuthor: userInfo.login,
      description: 'Постомат ' + id + ' не на связи',
    };

    swal({
      title: 'Постомат ' + query.idTerminal,
      text: 'Отправить сообщение технику?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((send) => {
      if (send) {
        Api.troubleReport(JSON.stringify(query)).then(() => {
          swal('OK!', 'Отправлено', 'success');
        });
      }
    });
  };

  const getInoicePackagesOps = (invId, j) => {
    const invoicePackages = getObjectElement(invoiceDetail, invId).Packages;
    return getObjectElement(invoicePackages, j).Operations;
  };
  return (
    <>
      <br />
      <h3>{title}</h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHead.map((item) => {
                return <TableCell key={headKey++}>{item}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((inv) => (
              <>
                <TableRow key={inv.ID}>
                  <TableCell>{inv.ID}</TableCell>
                  <TableCell>{inv.AgentName}</TableCell>
                  <TableCell>{inv.Code}</TableCell>
                  <TableCell>{inv.FIO}</TableCell>
                  <TableCell>{inv.NumberPhone}</TableCell>
                  <TableCell>{inv.AmountFromCustomer}</TableCell>
                  <TableCell>{inv.AmountPaidCustomer}</TableCell>
                  <TableCell>
                    <IconButton
                      variant="filled"
                      data-id={inv.ID}
                      onClick={() => searchDetail(inv.ID)}
                    >
                      <SearchIcon data-id={inv.ID} />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ padding: '0' }}
                    colSpan={tableHead.length}
                  >
                    <Collapse in timeout="auto" className="backgrLightGrey">
                      {getObjectElement(invoiceDetail, inv.ID) !==
                        undefined && (
                        <div>
                          {getObjectElement(invoiceDetail, inv.ID)
                            .Operations !== null &&
                            getObjectElement(invoiceDetail, inv.ID).Packages ===
                              null && (
                              <div>
                                <Typography
                                  variant="h5"
                                  style={{ padding: '20px 0 0 10px' }}
                                >
                                  Операции
                                </Typography>
                                <Table>
                                  {getObjectElement(
                                    invoiceDetail,
                                    inv.ID
                                  ).Operations.map((operation) => (
                                    <TableRow className="" key={operationKey++}>
                                      <TableCell>
                                        <b>{operation.Name}</b>
                                      </TableCell>

                                      <TableCell>
                                        {operation.Date.substring(0, 4)}-
                                        {operation.Date.substring(4, 6)}-
                                        {operation.Date.substring(6, 8)}
                                        &nbsp;&nbsp;
                                        {operation.Date.substring(8, 10)}:
                                        {operation.Date.substring(10, 12)}:
                                        {operation.Date.substring(12, 14)}
                                      </TableCell>

                                      <TableCell>
                                        <b>Постомат:</b>&nbsp;
                                        {operation.Division.Name}
                                      </TableCell>

                                      <TableCell>
                                        <b>Город:</b>&nbsp;
                                        {operation.Division.Town}
                                      </TableCell>

                                      <TableCell>
                                        <b>Адрес:</b>&nbsp;
                                        {operation.Division.Address}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </Table>
                              </div>
                            )}
                        </div>
                      )}
                      <div>
                        {inv?.Packages?.map((pack, j) => (
                          <div key={pack.ID}>
                            <div className="grid1fr8 ">
                              {pack.IsCanAccessCode !== 0 && (
                                <div>
                                  <IconButton
                                    data-package={pack.ID}
                                    onClick={() => sendSms(pack.ID)}
                                  >
                                    <MessageIcon data-package={pack.ID} />
                                  </IconButton>
                                </div>
                              )}
                              <div>
                                <b>ID посылки:</b>&nbsp;{pack.ID}
                              </div>
                              <div>
                                <b>Bar code:</b>&nbsp;{pack.BarCode}
                              </div>
                              <div>
                                <b>Статус:</b>&nbsp;{pack.Status}
                              </div>
                              <div>
                                <b>Постомат:</b>&nbsp;
                                {pack.Division.Name}
                              </div>
                              <div>
                                <b>Город:</b>&nbsp;{pack.Division.Town}
                              </div>
                              <div>
                                <b>Адрес:</b>&nbsp;
                                {pack.Division.Address}
                              </div>
                              <div>
                                <b>Статус постомата:</b>&nbsp;
                                <b
                                  className={
                                    pack.Division.IsOnline
                                      ? 'styleSuccText'
                                      : 'styleErr'
                                  }
                                >
                                  {getObjectElement(
                                    postomatStatus,
                                    pack.Division.IsOnline
                                  )}
                                </b>
                                {pack.Division.IsOnline === 0 && (
                                  <IconButton
                                    data-id={pack.Division.IDPostPoint}
                                    onClick={() =>
                                      sendReport(pack.Division.IDPostPoint)
                                    }
                                  >
                                    <ReceiptIcon />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                            <Typography
                              variant="h5"
                              style={{ padding: '20px 0 0 10px' }}
                            >
                              Статусы
                            </Typography>
                            {getObjectElement(invoiceDetail, inv.ID) !==
                              undefined && (
                              <div>
                                {getObjectElement(invoiceDetail, inv.ID)
                                  .Packages != null && (
                                  <Table>
                                    <TableBody>
                                      {getInoicePackagesOps(inv.ID, j) !==
                                        undefined &&
                                        getInoicePackagesOps(inv.ID, j).map(
                                          (o, k) => (
                                            <TableRow key={o.Name}>
                                              <TableCell>
                                                <b>{o.Name}</b>
                                                <p>{o.UserFio}</p>
                                                <p>{o.Comment}</p>
                                              </TableCell>
                                              <TableCell>
                                                &nbsp;&nbsp;
                                                <span>
                                                  {o.Date.substring(0, 4)}-
                                                  {o.Date.substring(4, 6)}-
                                                  {o.Date.substring(6, 8)}
                                                </span>
                                                &nbsp;&nbsp;
                                                <span>
                                                  {o.Date.substring(8, 10)}:
                                                  {o.Date.substring(10, 12)}:
                                                  {o.Date.substring(12, 14)}
                                                </span>
                                              </TableCell>
                                              <TableCell>
                                                {o.Division.Name}
                                              </TableCell>
                                              <TableCell>
                                                {o.Division.Town}
                                              </TableCell>
                                              <TableCell>
                                                {o.Division.Address}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                    </TableBody>
                                  </Table>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

PostTable.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
};

export default PostTable;
