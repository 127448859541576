import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import moment from 'moment';
import swal from 'sweetalert';

import Api from 'api/Api';
import TextField from 'components/ui/TextField';
import Button from 'components/ui/Button';
import DatePicker from 'components/ui/DatePicker';
import { getObjectElement } from 'functions/helpers';

const searchOptions = [
  {
    title: 'Номер терминала',
    id: 'terminalId',
    searchtitle: 'По номеру терминала',
  },
  { title: 'Kkm', id: 'kkm', searchtitle: 'По kkm' },
];

const SearchForm = ({ setTableData }) => {
  const inputPropsDef = searchOptions[0];

  const [searchType, setSearchType] = useState(1);

  const [searchValue, setSearchValue] = useState('');
  const [znm, setZnm] = useState('');
  const [fiscalNumber, setFiscalNumber] = useState('');

  const [dateFrom, setDateFrom] = useState(
    new Date(moment().subtract(3, 'days').format('YYYY-MM-DD 00:00:00'))
  );
  const [dateTo, setDateTo] = useState(new Date());
  const [inputProps, setInputProps] = useState(inputPropsDef);

  useEffect(() => {
    const inpProps = {};
    const searchInfo = getObjectElement(searchOptions, Number(searchType));

    inpProps.id = searchInfo.id;
    inpProps.placeholder = searchInfo.title;

    setInputProps(inpProps);
  }, [searchType]);

  const updateSearhParam = (e) => {
    setSearchValue(e.target.value);
  };

  const searchFiscalOperations = () => {
    const { title } = getObjectElement(searchOptions, Number(searchType));

    const value = searchValue;

    if (value === '') {
      swal('Ошибка!', `Поле ${title} не заполнено!`, 'error');
    } else {
      const data = { searchType, value };
      data.userType = 'admin';
      data.znm = znm;
      data.fiscalNumber = fiscalNumber;
      data.dateFrom = moment(dateFrom).format('YYYY-MM-DD HH:mm:ss');
      data.dateTo = moment(dateTo).format('YYYY-MM-DD HH:mm:ss');

      Api.getFiscalOperations(data).then((res) => {
        if (res?.data && res.data !== null) setTableData(res.data);
        else setTableData([]);
      });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid sm={4} xs={3} item>
        <div className="customSelect ">
          <FormControl variant="filled">
            <InputLabel id="demo-simple-select-outlined-label">
              Тип поиска
            </InputLabel>
            <Select
              required
              variant="filled"
              label="Выберите тип поиска"
              defaultValue={
                getObjectElement(searchOptions, Number(searchType)).id
              }
              onChange={(e) => {
                setSearchType(Number(e.currentTarget.dataset.index));
              }}
            >
              {searchOptions.map((option, i) => {
                return (
                  <MenuItem key={option.id} value={option.id} data-index={i}>
                    {option.searchtitle}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} align="top">
        <div className="customInp">
          <TextField
            {...inputProps}
            onChange={updateSearhParam}
            type="number"
            fullWidth
            variant="filled"
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={4} align="top">
        <div className="customInp">
          <TextField
            placeholder="Znm"
            id="znm"
            name="znm"
            onChange={(e) => setZnm(e.target.value)}
            type="number"
            fullWidth
            variant="filled"
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={3} align="top">
        <div className="customInp">
          <TextField
            placeholder="Фискальный номер"
            id="fiscalNumber"
            name="fiscalNumber"
            onChange={(e) => setFiscalNumber(e.target.value)}
            type="number"
            fullWidth
            variant="filled"
          />
        </div>
      </Grid>

      <Grid sm={3} xs={6} item>
        <DatePicker selected={dateFrom} onChange={setDateFrom} />
      </Grid>
      <Grid sm={3} xs={6} item>
        <DatePicker selected={dateTo} onChange={setDateTo} />
      </Grid>
      <Grid sm={3} xs={12} item>
        <Button
          color="primary"
          onClick={searchFiscalOperations}
          className="noMarg"
          fullWidth
        >
          Найти
        </Button>
      </Grid>
    </Grid>
  );
};

SearchForm.propTypes = {
  setTableData: PropTypes.func.isRequired,
};

export default SearchForm;
