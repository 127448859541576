import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import Api from 'api/Api';
import {
  formatDate,
  getFromLocalStorage,
  getObjectElement,
} from 'functions/helpers';
import Button from 'components/ui/Button';
import { Grid, Typography } from '@material-ui/core';
import { setUpdateTroubles } from 'features/mainSlice';

const Troubles = () => {
  const services = useSelector((state) => state.main.services);
  const [trans, setTrans] = useState({});
  const [comment, setComment] = useState('');
  const [currentTransKey, setCurrentTransKey] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    getTransButton();
  }, []);

  const save = () => {
    if (getObjectElement(trans, Number(currentTransKey)).IDPayments) {
      Api.troubles(
        JSON.stringify({
          userid: Number(getFromLocalStorage('userId')),
          type: 'save',
          idpayments: getObjectElement(trans, Number(currentTransKey))
            .IDPayments,
          comment,
        })
      )
        .then(() => {
          swal('OK!', 'Сохранено!', 'success');
          dispatch(setUpdateTroubles(true));
        })
        .catch();
    }
  };

  const finish = () => {
    Api.troubles(
      JSON.stringify({
        userid: Number(getFromLocalStorage('userId')),
        type: 'finish',
        idpayments: getObjectElement(trans, Number(currentTransKey)).IDPayments,
        comment,
      })
    )
      .then(() => {
        swal('OK!', 'Сохранено!', 'success');
        dispatch(setUpdateTroubles(true));
        setTrans({});
        setComment('');
      })
      .catch();
  };
  const getTransButton = () => {
    setTrans({});

    Api.troubles(
      JSON.stringify({
        userid: Number(getFromLocalStorage('userId')),
        type: 'get',
      })
    ).then((response) => {
      if (response !== undefined) {
        if (response === null) {
          setTrans([]);
          setComment('');
          setCurrentTransKey(0);
        } else {
          setTrans(response);
          setComment(response[0].Comment.String);
          setCurrentTransKey(0);
        }
      }
    });
  };

  const getService = (id) => {
    return services.map((val) => {
      return val.value === id && val.label;
    });
  };

  const format = (num) => {
    let numF = 1 * num;
    return numF.toLocaleString('ru');
  };

  const setCommentFunc = (e) => {
    setComment(e.target.value);
  };

  const next = () => {
    let currTransKey =
      currentTransKey + 1 < trans.length ? currentTransKey + 1 : 0;

    setCurrentTransKey(currTransKey);
  };

  const getTransInfo = () => {
    return getObjectElement(trans, Number(currentTransKey));
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item sm={10} xs={12}>
          <Typography variant="h5">
            Проблемные платежи по штрафам и налогам
          </Typography>
        </Grid>
      </Grid>
      {trans !== null && trans.length ? (
        <div>
          <Button color="primary" onClick={next}>
            Показать следующий платеж
          </Button>
          <div>
            <div>
              <h4>
                Дата платежа: <b>{formatDate(getTransInfo().Date.String)}</b>
              </h4>
              <h4>
                Транзакция: <b>{getTransInfo().SN.String}</b>
              </h4>
              <h4>
                Сервис:{' '}
                <b>{getService(Number(getTransInfo().Service.String))}</b>
              </h4>
              <h4>
                Телефон: <b>{getTransInfo().Phone.String}</b>
              </h4>
              <h4>
                Сумма: <b>{format(getTransInfo().Amount.String)} тг</b>
              </h4>
              <h4>
                Комиссия: <b>{format(getTransInfo().Comission.String)} тг</b>
              </h4>
            </div>
          </div>
          <div>
            <textarea
              onInput={setCommentFunc}
              rows="10"
              cols="50"
              name={'text'}
            >
              {getTransInfo().Comment.String}
            </textarea>
          </div>
          <div>
            <Button color="primary" onClick={save}>
              Сохранить
            </Button>
            <Button
              color="primary"
              onClick={finish}
              style={{ marginLeft: '15px' }}
            >
              Завершить
            </Button>
          </div>
        </div>
      ) : (
        'Проблемных платежей не найдено'
      )}
    </>
  );
};

export default Troubles;
