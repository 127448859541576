import React from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'functions/helpers';

import BitrixTaskForm from 'components/BitrixTaskForm';

const bitixFormsFields = [
  {
    label: 'Ответственное лицо',
    name: 'responsible',
    disabled: true,
    value: 'Отдел B2B',
  },
  {
    label: 'Название задачи',
    name: 'responsible',
    disabled: true,
    value: 'Как стать дилером',
    req: false,
  },
  { label: 'ФИО', name: 'fio', req: false },
  { label: 'Наименование ИП/ТОО', name: 'companyName', req: false },
  { label: 'Телефон *', name: 'phone', req: true },
  { label: 'Город *', name: 'city', req: true },
  { label: 'E-mail', name: 'email', req: false },
  { label: 'Комментарий', name: 'comment', type: 'textarea', req: false },
];

const HowToBecomeADealer = ({ sendBitrixTask, title }) => {
  const state = {
    fio: '',
    phone: '',
    email: '',
    comment: '',
    companyName: '',
    city: '',
  };

  const sendBitrixTaskFunc = ({
    fio,
    phone,
    email,
    comment,
    companyName,
    city,
  }) => {
    const data = {
      fio,
      phone,
      email,
      comment,
      companyName,
      city,
      userId: Number(getFromLocalStorage('userId')),
    };

    sendBitrixTask(data, 'howToBecomeADealer');
  };

  return (
    <BitrixTaskForm
      title={title}
      fields={bitixFormsFields}
      initialState={state}
      sendBitrixTask={sendBitrixTaskFunc}
    />
  );
};

HowToBecomeADealer.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default HowToBecomeADealer;
