/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import Api from 'api/Api';
import { setAgentsCuratorsList } from 'features/mainSlice';

import SearchForm from './SearchForm';
import AgentsTable from './AgentsTable';

const AgentsCurators = () => {
  const dispatch = useDispatch();
  const pageTitle = 'Кураторы агентов';
  const [isSearch, setIsSearch] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const showProgress = useSelector((state) => state.ui.showProgress);

  useEffect(() => {
    Api.getAgentsCurators().then((response) => {
      if (response) {
        dispatch(setAgentsCuratorsList(response));
        setFilteredTableData(response);
      } else {
        dispatch(setAgentsCuratorsList([]));
        setFilteredTableData([]);
      }
    });
  }, [dispatch]);

  const searchAgentsCuratorsList = useCallback((searchQuery, searchResult) => {
    if (searchQuery.length >= 3) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }

    setFilteredTableData(searchResult);
  }, []);

  const getTableResult = () => {
    if (showProgress) {
      return 'Идет загрузка списка кураторов';
    } else if (isSearch && !filteredTableData.length) {
      return 'По заданным параметрам поиска агенты или кураторы не найдены';
    } else if (!filteredTableData.length) {
      return 'Список кураторов пуст';
    } else return <AgentsTable tableRows={filteredTableData} />;
  };

  return (
    <>
      <Typography variant="h5">{pageTitle}</Typography>
      <SearchForm searchAgentsCuratorsList={searchAgentsCuratorsList} />
      {getTableResult()}
    </>
  );
};
export default AgentsCurators;
