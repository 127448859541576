import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    '& td': {
      verticalAlign: 'top',
    },
  },
  tdMaxWidth: {
    wordBreak: 'break-word',
    border: 0,
    backgroundColor: 'transparent',
    textAlign: 'left',
    position: 'relative',
    maxWidth: '600px',
    '&:hover div': {
      display: 'block',
    },
    '&:hover ': {
      opacity: '0.9',
      cursor: 'pointer',
    },
  },
  tdWithWrap: {
    maxWidth: '300px',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
  },
  copyText: {
    display: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '10px',
  },
  tdMinWidth: {
    width: '200px',
  },
  flexButtons: {
    display: 'flex',
    gap: '8px',
  },
}));

export default useStyles;
