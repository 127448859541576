import React from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const InputMaskCustom = ({
  mask,
  type,
  id,
  placeholder,
  callback,
  styles,
  name,
  defaultValue,
  className = '',
}) => {
  return (
    <InputMask
      mask={mask}
      maskChar={null}
      type={typeof type !== 'undefined' ? type : 'tel'}
      id={id}
      name={name}
      autoComplete="off"
      placeholder={placeholder}
      onChange={callback}
      style={styles}
      defaultValue={defaultValue}
      value={defaultValue}
      className={className}
      formatChars={{
        '*': '[A-Za-zа-яА-Я0-9@.-]',
        9: '[0-9]',
        a: '[А-яа-я ]',
      }}
    />
  );
};

InputMaskCustom.propTypes = {
  mask: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  callback: PropTypes.func,
  styles: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
};

export default InputMaskCustom;
