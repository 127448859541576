import { MAIN_MENU, REPORTS_MENU_DENIED_ROLES } from 'constants/route';
import { FISCAL_TABS, KASSA24_TABS } from 'constants/tabs';
import { getItemsByUserRole } from 'functions/helpers';
import { useMemo } from 'react';
import useUser from './useUser';

const useMenu = () => {
  const { userRoles } = useUser();

  const kassa24Tabs = useMemo(() => {
    return getItemsByUserRole(KASSA24_TABS, userRoles);
  }, [userRoles]);

  const mainMenu = useMemo(() => {
    return getItemsByUserRole(MAIN_MENU, userRoles);
  }, [userRoles]);

  const fiscalMenu = useMemo(() => {
    return getItemsByUserRole(FISCAL_TABS, userRoles);
  }, [userRoles]);

  const isShowReportsMenu = useMemo(() => {
    let allowed = false;
    userRoles.forEach((userRole) => {
      if (!REPORTS_MENU_DENIED_ROLES.includes(userRole)) {
        allowed = true;
      }
    });

    return allowed;
  }, [userRoles]);

  return {
    kassa24Tabs,
    mainMenu,
    isShowReportsMenu,
    fiscalMenu,
  };
};

export default useMenu;
