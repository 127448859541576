import React from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'functions/helpers';
import BitrixTaskForm from '../BitrixTaskForm';
import { OWN_NETWORK_BITRIX_TASK_TYPES } from 'constants/bitrixTask';

const bitrixFormsFields = [
  { label: 'Заголовок *', name: 'title', req: true, type: 'text' },
  {
    label: 'Ответственный директор',
    name: 'responsibleId',
    req: true,
    type: 'select',
  },
  { label: 'Текст *', name: 'text', req: true, type: 'textarea' },
];

const Other = ({ sendBitrixTask }) => {
  const initialState = {
    title: '',
    text: '',
    responsibleId: '',
  };

  const sendBitrixTaskFunc = ({ title, text, responsibleId }) => {
    const data = {
      title,
      text,
      userId: Number(getFromLocalStorage('userId')),
      responsibleId,
    };

    sendBitrixTask(data, OWN_NETWORK_BITRIX_TASK_TYPES.OTHER);
  };

  return (
    <BitrixTaskForm
      fields={bitrixFormsFields}
      initialState={initialState}
      sendBitrixTask={sendBitrixTaskFunc}
    />
  );
};

Other.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
};

export default Other;
