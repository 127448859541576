import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from '@material-ui/core';

import Button from 'components/ui/Button';
import { formatAmount, getHumanDateFormat } from 'functions/helpers';
import { cancelKaspiPayment } from 'functions/kassa24';

import KaspiSearchForm from './KaspiSearchForm';

const tableHead = [
  'Номер транзакции',
  'Лицевой счет',
  'Сумма',
  'Дата платежа',
  'Дата проведения',
  'Токен для Kaspi',
];
let headKey = 0;

const KaspiForm = () => {
  const [kaspiData, setKaspiData] = useState([]);
  const userInfo = useSelector((state) => state.user.userInfo || {});

  const getDateOutText = (DateOut) => !DateOut.Valid && 'В проведении';
  const getDateOutInFormat = (DateOut) =>
    DateOut.Valid &&
    DateOut.String.charAt(0) === '2' &&
    getHumanDateFormat(DateOut.String);

  return (
    <>
      <KaspiSearchForm setKaspiData={setKaspiData} />
      {kaspiData.length ? (
        <>
          <h2>Количество найденных платежей: {kaspiData.length}</h2>
          <br />
          <TableContainer>
            <Table className={'table'}>
              <TableHead>
                <TableRow>
                  {tableHead.map((item) => {
                    return <TableCell key={headKey++}>{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {kaspiData.map(
                  ({
                    SN,
                    Account,
                    Token,
                    IDPayments,
                    PaymentCanBeCancelled,
                    DateIn,
                    DateOut,
                    Amount,
                    ErrorCode,
                    ErrorName,
                  }) => {
                    return (
                      <TableRow key={SN.String}>
                        <TableCell>{SN.String}</TableCell>
                        <TableCell>{Account.String}</TableCell>
                        <TableCell>{formatAmount(Amount.String)} тг</TableCell>
                        <TableCell>
                          {getHumanDateFormat(DateIn.String)}
                        </TableCell>
                        <TableCell>
                          {getDateOutText(DateOut.Valid)}
                          {getDateOutInFormat(DateOut)}
                          {DateOut.Valid && DateOut.String.charAt(0) === '1' && (
                            <div className="styleErr">
                              <span>{ErrorCode}</span>
                              <br />
                              <span>{ErrorName}</span>
                              <br />
                            </div>
                          )}
                          <br />
                          {(userInfo.isB2b ||
                            userInfo.isManager ||
                            userInfo.isSupervisor) &&
                            PaymentCanBeCancelled && (
                              <Button
                                onclick={() =>
                                  cancelKaspiPayment(SN.String, IDPayments)
                                }
                              >
                                Снять с проведения
                              </Button>
                            )}
                        </TableCell>
                        <TableCell>{Token}</TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
    </>
  );
};

export default KaspiForm;
