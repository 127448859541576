import { getObjectElement } from 'functions/helpers';
import { useState, useCallback } from 'react';

const useTable = (defLimit = '') => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defLimit);

  const stableSort = useCallback(
    (array) => {
      const descendingComparator = (a, b, orderField) => {
        const bItem = getObjectElement(b, orderField);
        const aItem = getObjectElement(a, orderField);
        if (bItem > aItem) {
          return -1;
        }
        if (bItem < aItem) {
          return 1;
        }
        return 0;
      };

      const getComparator = (orderDir, orderField) => {
        return orderDir === 'desc'
          ? (a, b) => descendingComparator(a, b, orderField)
          : (a, b) => -descendingComparator(a, b, orderField);
      };

      const comparator = getComparator(order, orderBy);
      const stabilizedThis = array.map((el, index) => [el, index]);

      stabilizedThis.sort((a, b) => {
        const orderDir = comparator(a[0], b[0]);
        if (orderDir !== 0) return orderDir;
        return a[1] - b[1];
      });

      return stabilizedThis.map((el) => el[0]);
    },
    [order, orderBy]
  );

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  return {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    stableSort,
    handleChangeRowsPerPage,
    createSortHandler,
  };
};

export default useTable;
