import React, { useEffect, useState } from 'react';
import Api from 'api/Api';
import { strip_tags } from 'functions/helpers';
import { Typography, Grid } from '@material-ui/core';

let promoKey = 0;

const Promotions = () => {
  const [promotions, setPromotions] = useState([]);

  const checkDate = (date) => {
    const currentDate = new Date();
    const arr = date.split(' ')[0].split('-');
    const createdDate = new Date(arr[0], arr[1], arr[2]);
    const r = currentDate >= createdDate;

    return !r;
  };

  useEffect(() => {
    Api.getPromotions().then((response) => {
      let data = JSON.parse(response.data);
      if (data !== undefined && data.errCode === 0) {
        let promotionsList = [];
        data.promotions.map((val) => {
          if (checkDate(val.EndDate)) {
            promotionsList.push(val);
          }
          return promotionsList;
        });

        setPromotions(promotionsList);
      }
    });
  }, []);

  return (
    <>
      <Typography variant="h1">Акции</Typography>

      {promotions.map((val) => (
        <div className="actionItem" key={promoKey++}>
          <Grid container spacing={3} alignItems="center">
            {val.ImgUrlMini && (
              <Grid item sm={2} xs={12}>
                <img src={val.ImgUrlMini} alt="" />
              </Grid>
            )}
            <Grid item sm={val.ImgUrlMini ? 10 : 12} xs={12}>
              <Typography variant="h2" className="iconBlue">
                {val.Title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Действует до: <b>{val.EndDate}</b>
              </Typography>
              <Typography variant="body">{strip_tags(val.Content)}</Typography>
            </Grid>
          </Grid>
        </div>
      ))}
      {promotions.length === 0 && 'Список акций пуст'}
    </>
  );
};

export default Promotions;
