export const COLORS = {
  RED: '#f50000',
  GREEN: '#018786',
};

export const PERSON_STATUSES = {
  BLOCKED: 1,
  ACTIVE: 2,
};

export const AITU_PAY_OFFER_STATUSES = {
  ACCEPTED_THE_OFFER: 1,
};

export const AITU_PAY_MIGRATION_STATUSES = {
  SET_FOR_MIGRATION: 1,
  MIGRATED: 2,
};

export const LIMITS = {
  Идентифицирован: {
    month: '12 625 000',
    day: '5 050 000',
    onePayment: '1 262 500',
    balance: '12 625 000',
  },
  'Не идентифицирован': {
    month: '-',
    day: '-',
    onePayment: '135 000',
    balance: '270 000',
  },
};
