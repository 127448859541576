import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import Api from 'api/Api';
import {
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
const tableHead = [
  'Время и сумма',
  'Аккаунт',
  'Номер счета и чека',
  'Тип',
  'Сервис',
  'Статус',
];

let headKey = 0;

const TableResult = ({ list, title }) => {
  const services = useSelector((state) => state.main.services);
  const clientData = useSelector((state) => state.main.clientData);

  const getService = (id) => {
    return services.filter((val) => val.value === id)[0].label;
  };

  const sendRepay = (id) => {
    if (clientData.Email === undefined || clientData.Email === '') {
      swal('Ошибка!', 'Заполните Email', 'error');
    } else {
      swal({
        title: 'Вы уверены?',
        text:
          'Отправить на ' +
          clientData.Email +
          ' ссылку на повторную попытку платежа',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((send) => {
        if (send) {
          let query = {
            email: clientData.Email,
            id,
          };

          Api.sendOnepayRep(JSON.stringify(query)).then((response) => {
            if (response.isSended) {
              swal('Ok!', 'Письмо со ссылкой отправлено!', 'success');
            } else {
              swal('Упс!', 'Что-то не так, попробуйте еще раз', 'error');
            }
          });
        }
      });
    }
  };

  return (
    <>
      <Typography variant="h3">{title}</Typography>
      {list != null && list.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHead.map((item, i) => {
                  return <TableCell key={headKey++}>{item}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((trans) => (
                <TableRow key={trans.PaymentOrderId.String}>
                  <TableCell>
                    <div>
                      {trans.Date.String.substring(8, 10)}.
                      {trans.Date.String.substring(5, 7)}.
                      {trans.Date.String.substring(0, 4)}
                      &nbsp;
                      {trans.Date.String.substring(11, 19)}
                    </div>
                    <div>{1 * trans.Summ.String} тг</div>
                  </TableCell>
                  <TableCell>
                    <div>{trans.Email.String}</div>
                    <div>{trans.Phone.String}</div>
                  </TableCell>
                  <TableCell>
                    <div>{trans.Login.String}</div>
                    <div className={'styleGrey'}>
                      {trans.Transaction.String}
                    </div>
                  </TableCell>
                  <TableCell>{trans.Type.String}</TableCell>
                  <TableCell>
                    {getService(1 * trans.ServiceId.String)}
                  </TableCell>
                  <TableCell>
                    {(trans.PaymentStatus === 6 ||
                      trans.PaymentStatus === 4) && (
                      <div>
                        <span>{trans.Status.String}</span>
                      </div>
                    )}

                    {(trans.PaymentStatus === 0 ||
                      trans.PaymentStatus === 5 ||
                      trans.PaymentStatus === 7) && (
                      <div className="styleErr gridSmall2">
                        <div>
                          <div>{trans.Status.String}</div>
                          <div>{trans.ErrorMessage.String}</div>
                          <div>{trans.Error.String}</div>
                        </div>
                        <div>
                          <IconButton
                            onClick={() =>
                              sendRepay(trans.PaymentOrderId.String)
                            }
                          >
                            <ReceiptIcon />
                          </IconButton>
                        </div>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

TableResult.propTypes = { list: PropTypes.array, title: PropTypes.string };

export default TableResult;
