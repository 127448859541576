import React, { useMemo, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

import { a11yProps, getItemsByUserRole } from 'functions/helpers';
import TabPanel from 'components/ui/TabPanel';

import FiscalOperations from './Admin';
import useMenu from 'hooks/useMenu';
import { useUser } from 'hooks';

import UserFiscalOperations from './User';

const useStyles = makeStyles(() => ({
  customTabs: {
    margin: '0 0 30px 0',
  },
}));

const Fiscal = () => {
  const { fiscalMenu } = useMenu();
  const { userRoles } = useUser();

  const [currentTab, setCurrentTab] = useState(0);
  const classes = useStyles();

  const tabsContent = useMemo(() => {
    return [
      {
        component: <UserFiscalOperations />,
        id: 'tabs1',
      },
      {
        component: <FiscalOperations />,
        id: 'tabs2',
      },
    ];
  }, []);

  const tabsContentForUser = useMemo(() => {
    return getItemsByUserRole(tabsContent, userRoles);
  }, [userRoles, tabsContent]);

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={(_, newTab) => setCurrentTab(newTab)}
        scrollButtons="auto"
        variant="scrollable"
        className={classes.customTabs}
        style={{ justifyContent: 'space-between' }}
        indicatorColor="primary"
      >
        {fiscalMenu.map((item, i) => (
          <Tab key={item.id} label={item.label} {...a11yProps(i)} />
        ))}
      </Tabs>
      {tabsContentForUser.map((content, key) => {
        return (
          <TabPanel value={currentTab} index={key} key={content.id}>
            {content.component}
          </TabPanel>
        );
      })}
    </>
  );
};

export default Fiscal;
