import React from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'functions/helpers';
import BitrixTaskForm from 'components/BitrixTaskForm';
import { PROVIDER_BITRIX_TASK_TYPES } from 'constants/bitrixTask';

const bitixFormsFields = [
  { label: 'Наименование ИП/ТОО *', name: 'companyName', req: true },
  { label: 'Номер агента (ID) *', name: 'agentNumber', req: true },
  { label: 'ID провайдера *', name: 'providerId', req: true },
  { label: 'Название провайдера *', name: 'providerName', req: true },
  { label: 'ID сервиса *', name: 'serviceId', req: true },
  { label: 'Название сервиса *', name: 'serviceName', req: true },
  {
    label: 'Описание проблемы *',
    name: 'description',
    type: 'textarea',
    req: true,
  },
];

const ProblemWithTheProvider = ({ sendBitrixTask }) => {
  const state = {
    providerId: '',
    providerName: '',
    serviceId: '',
    serviceName: '',
    description: '',
    companyName: '',
    agentNumber: '',
  };

  const sendBitrixTaskFunc = ({
    providerId,
    providerName,
    serviceId,
    serviceName,
    description,
    companyName,
    agentNumber,
  }) => {
    const data = {
      providerId,
      providerName,
      serviceId,
      serviceName,
      description,
      companyName,
      agentNumber,
      userId: Number(getFromLocalStorage('userId')),
    };

    sendBitrixTask(data, PROVIDER_BITRIX_TASK_TYPES.PROBLEM_WITH_PROVIDER);
  };

  return (
    <BitrixTaskForm
      fields={bitixFormsFields}
      initialState={state}
      sendBitrixTask={sendBitrixTaskFunc}
    />
  );
};

ProblemWithTheProvider.propTypes = {
  sendBitrixTask: PropTypes.func.isRequired,
};

export default ProblemWithTheProvider;
