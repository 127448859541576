import React, { useState } from 'react';
import Button from 'components/ui/Button';
import {
  Grid,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TabPanel from 'components/ui/TabPanel';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  getFieldsYup,
  getObjectElement,
  setObjectElement,
} from 'functions/helpers';
import CustomTextField from 'components/ui/TextField';

const forms = [
  {
    type: 2,
    title: 'Установка постомата',
    token: '44a56490-f492-41d8-bf8f-b19ab3c16f33',
    elmaType: '5',
    fields: [
      { label: 'Город', name: 'Gorod', req: true },
      { label: 'Имя', name: 'Imya', req: true },
      { label: 'Телефон', name: 'Telefon', req: true },
      { label: 'Комментарий', name: 'Komentariy', req: true },
    ],
  },
  {
    type: 3,
    title: 'Подключиться к MyPost ',
    token: '3f585b30-90b0-444e-b345-4c021f4beb7a',
    elmaType: '5',
    fields: [
      { label: 'ФИО', name: 'FIO', req: true },
      { label: 'Телефон', name: 'Telefon', req: true },
      {
        label: 'Наименование компании',
        name: 'NaimenovanieKompanii',
        req: true,
      },
      { label: 'Комментарий', name: 'Komentariy', req: true },
    ],
  },
];

const MyPostForms = (props) => {
  const { sendTask } = props;
  const [valueSelect, setValueSelect] = useState(2);
  const state = {
    Gorod: '',
    Ulica: '',
    NomerDoma: '',
    NaimenovanieTochki: '',
    Komentariy: '',
    Imya: '',
    Telefon: '',
    NaimenovanieKompanii: '',
    FIO: '',
  };

  const sendTaskFunc = (_, { resetForm }) => {
    let form = document.getElementById('formMyPost');
    let inputs = form.getElementsByTagName('input');
    let token = '';
    let elmaType = '';

    let formFields = {};

    for (let i in inputs) {
      const inputItem = getObjectElement(inputs, i);

      if (typeof inputItem !== 'undefined') {
        const inpValue = inputItem.value;
        const inpName = inputItem.name;

        switch (inputItem.dataset.type) {
          case 'addedData':
            formFields = setObjectElement(
              formFields,
              inpName,
              inputItem.dataset.label + ' - ' + inpValue
            );

            break;
          case 'data':
            formFields = setObjectElement(
              formFields,
              inpName,
              getFormFieldValue(formFields, inpName, inpValue)
            );

            break;
          case 'params':
            if (inpName === 'token') {
              token = inpValue;
            } else {
              elmaType = inpValue;
            }
            break;
          default:
            break;
        }
      }
    }

    sendTask(token, formFields, elmaType).then((response) => {
      resetFormHundler(response, resetForm);
    });
  };

  const getFormFieldValue = (formFields, inpName, inpValue) => {
    const formField = getObjectElement(formFields, inpName);
    if (formField !== undefined) {
      return formField + '  ' + inpValue;
    } else {
      return inpValue;
    }
  };

  const resetFormHundler = (response, resetForm) => {
    if (response !== undefined) {
      resetForm();
    }
  };

  const validationSchema = yup.object(
    getFieldsYup(
      getObjectElement(forms, valueSelect - 2).fields,
      getObjectElement(forms, valueSelect - 2).addedFields
    )
  );

  const formik = useFormik({
    initialValues: state,
    validationSchema: validationSchema,
    onSubmit: sendTaskFunc,
  });

  const handleChange = (event) => {
    formik.resetForm();
    setValueSelect(event.target.value);
  };

  return (
    <div>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={10}>
          <Typography variant="h1" align="center">
            Постановка задачи:{' '}
            {forms.filter((item) => item.type === valueSelect)[0].title}
          </Typography>
        </Grid>
      </Grid>

      <Grid sm={8} xs={12} className="contentCenter">
        <Grid container spacing={3}>
          <Grid sm={12} xs={12} item>
            <div className="customInp customSelect">
              <FormControl>
                <Select defaultValue={2} fullWidth onChange={handleChange}>
                  {forms.map((form) => (
                    <MenuItem value={form.type} key={form.type}>
                      {form.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {forms.map((form) => (
              <TabPanel index={form.type} key={form.type} value={valueSelect}>
                <form id="formMyPost" onSubmit={formik.handleSubmit}>
                  <input
                    type="hidden"
                    data-type="params"
                    name="token"
                    value={form.token}
                  />
                  <input
                    type="hidden"
                    data-type="params"
                    name="type"
                    value={form.type}
                  />
                  <input
                    type="hidden"
                    data-type="params"
                    name="elmaType"
                    value={form.elmaType}
                  />
                  <Grid container spacing={3}>
                    {form.addedFields !== undefined &&
                      form.addedFields.map((field) => {
                        const value = getObjectElement(
                          formik.values,
                          field.name
                        );
                        const touched = getObjectElement(
                          formik.touched,
                          field.name
                        );
                        const errors = getObjectElement(
                          formik.errors,
                          field.name
                        );
                        return (
                          <Grid key={field.name} item xs={12} sm={4}>
                            <CustomTextField
                              type="text"
                              name={field.name}
                              fullWidth
                              variant="filled"
                              label={field.label}
                              inputProps={{
                                'data-type': 'addedData',
                                'data-label': field.label,
                              }}
                              value={value}
                              onChange={formik.handleChange}
                              error={touched && Boolean(errors)}
                              helperText={touched && errors}
                            />
                          </Grid>
                        );
                      })}

                    {form.fields.map((field) => {
                      const value = getObjectElement(formik.values, field.name);
                      const touched = getObjectElement(
                        formik.touched,
                        field.name
                      );
                      const errors = getObjectElement(
                        formik.errors,
                        field.name
                      );
                      return (
                        <Grid key={field.name} item xs={12} sm={4}>
                          <CustomTextField
                            type="text"
                            name={field.name}
                            label={field.label}
                            fullWidth
                            variant="filled"
                            inputProps={{
                              'data-type': 'data',
                            }}
                            value={value}
                            onChange={formik.handleChange}
                            error={touched && Boolean(errors)}
                            helperText={touched && errors}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid container spacing={3} className="gridPadding">
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={4} className="gridCenter">
                      <Button fullWidth color="primary" type="submit">
                        Поставить задачу
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

MyPostForms.propTypes = {
  sendMail: PropTypes.func.isRequired,
  sendTask: PropTypes.func.isRequired,
};

export default MyPostForms;
