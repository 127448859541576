import React from 'react';
import Api from 'api/Api';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
} from '@material-ui/core';
import {
  formatDate,
  formatRu,
  getFromLocalStorage,
  getObjectElement,
} from 'functions/helpers';
import { useSelector } from 'react-redux';
import ReceiptIcon from '@material-ui/icons/Receipt';
import swal from 'sweetalert';

const TERMINAL_FOR_LK = 27574;
const tableHead = [
  'Время и сумма',
  'Номер счета и чека',
  'Терминал',
  'Сервис',
  'Статус',
];
let headKey = 0;

const TransactionTableLk = ({ transList }) => {
  const clientData = useSelector((state) => state.main.clientData);
  //отправка чека
  const sendCheck = (index) => {
    if (!clientData.Email) {
      swal('Ошибка!', 'Заполните Email', 'error');
    } else {
      //берем параметры платежа по индексу из массива платежей
      let TransList = getObjectElement(transList, index);

      //формируем запрос
      let query = {
        IdSysUser: 1 * TransList.IDSysUser,
        SN: TransList.SN.String,
        Email: clientData.Email.trim(),
      };

      swal({
        title: 'Вы уверены?',
        text: 'Клиенту будет отправлен дубликат чека на ' + query.Email,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((sure) => {
        if (sure) {
          Api.sendCheckFromLk(JSON.stringify(query))
            .then((result) => {
              if (typeof result !== 'undefined' && result) {
                sendCheckSuccess(result, query);
              }
            })
            .catch((e) => {
              //чек не отправился
              swal(
                'Ошибка! Попробуйте еще раз',
                e.response.data.message,
                'error'
              );
            });
        }
      });
    }
  };

  const sendCheckSuccess = (result, query) => {
    try {
      const response = JSON.parse(result);
      Api.log({
        logType: 'sendCheckFromLk',
        userid: Number(getFromLocalStorage('userId')),
        data: {
          ...query,
          apiStatus: response.code,
          success: !response.code,
        },
        timestamp: (Date.now() / 1000) | 0,
      });
      swal('OK', 'Чек успешно отправлен на email', 'success');
    } catch (e) {
      swal('Возникла ошибка при отправке чека', '', 'error');
    }
  };

  return (
    <>
      <h3>Транзакций найдено: {transList.length}</h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHead.map((head) => (
                <TableCell key={headKey++}>{head}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transList.map(
              (
                {
                  DateIn,
                  DateOut,
                  SN,
                  Amount,
                  Account,
                  ServiceName,
                  IsSuccess,
                },
                i
              ) => {
                return (
                  <TableRow key={SN.String}>
                    <TableCell>
                      {formatDate(new Date(DateIn.String).getTime())} <br />
                      {formatDate(new Date(DateOut.String).getTime())} <br />
                      <b>{formatRu(Amount.String)} тг </b>
                      <br />
                    </TableCell>
                    <TableCell>
                      {Account.String} <br />
                      <span className="styleGrey">{SN.String}</span>
                    </TableCell>
                    <TableCell>{TERMINAL_FOR_LK}</TableCell>
                    <TableCell>{ServiceName.String}</TableCell>
                    <TableCell>
                      <span className={!IsSuccess ? 'styleErr' : ''}>
                        {IsSuccess ? 'Успешен' : 'Не успешен'}
                      </span>
                      <IconButton data-index={i} onclick={() => sendCheck(i)}>
                        <ReceiptIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

TransactionTableLk.propTypes = {
  transList: PropTypes.array,
};

export default TransactionTableLk;
