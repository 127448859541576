import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import TableCustom from 'components/ui/Table';
import { formatAmount } from 'functions/helpers';

const tableHead = [
  { label: 'Номер счета', field: 'Account' },
  { label: 'Дата платежа', field: 'DateIn' },
  { label: 'Информация', field: 'Info' },
  { label: 'Сервис', field: 'Service' },
  { label: 'Агент', field: 'Agent' },
  { label: 'Терминал', field: 'Terminal' },
  { label: 'Комментарии', field: 'Comment' },
];

const RepayDealers = ({ reports }) => {
  const getTableRows = () => {
    let rows = [];
    if (reports !== undefined) {
      reports.map(
        ({
          Account,
          DateIn,
          Amount,
          Commission,
          Trans,
          NS,
          IDService,
          AgName,
          Term,
          Comment,
          ErrorCode,
          ErrorName,
        }) => {
          return rows.push({
            Account: Account,
            DateIn: moment(DateIn).format('DD.MM.YYYY HH:mm:ss'),
            Info: (
              <>
                Транзакция: {Trans} <br />
                Принято от клиента: {formatAmount(Amount)} тг <br />
                Комиссия: {formatAmount(Commission)} тг
                <br />
                Зачислено на счет:{' '}
                {formatAmount(Number(Amount) - Number(Commission))} тг
                <br />
                Код ошибки: {ErrorCode} <br />
                Ошибка: {ErrorName}
              </>
            ),
            Serivice: `${NS} (${IDService})`,
            Agent: AgName,
            Terminal: Term,
            Comment: Comment,
          });
        }
      );
    }
    return rows;
  };

  return (
    <>
      <div>
        {reports.length > 0 && (
          <TableCustom
            head={tableHead}
            columns={getTableRows()}
            pageSize={30}
            showPaging={true}
          />
        )}
      </div>
    </>
  );
};

RepayDealers.propTypes = { reports: PropTypes.array };

export default RepayDealers;
