export const USER_ADMIN = 'admin';
export const USER_AUDITOR = 'auditor';
export const USER_B2B = 'b2b';
export const USER_GUEST = 'guest';
export const USER_MANAGER = 'manager';
export const USER_OPERATOR = 'operator';
export const USER_SUPERVISOR = 'supervisor';
export const USER_OPERATOR_ADVANCED = 'operatorAdvanced';

export const USER_AUDITOR_DEFAULT_PAGE = '/kassa24';

export const USER_ROLE_KEYS = {
  OPERATOR: 'o',
  MANAGER: 'm',
  SUPERVISOR: 's',
  B2B: 'b2b',
  GUEST: 'g',
  ADMIN: 'a',
  AUDITOR: 'auditor',
  OPERATOR_ADVANCED: 'oa',
};

export const USER_ROLE_LABELS = {
  [USER_ROLE_KEYS.OPERATOR]: 'Оператор',
  [USER_ROLE_KEYS.MANAGER]: 'Менеджер',
  [USER_ROLE_KEYS.SUPERVISOR]: 'Супервайзер',
  [USER_ROLE_KEYS.B2B]: 'B2b',
  [USER_ROLE_KEYS.GUEST]: 'Гость',
  [USER_ROLE_KEYS.ADMIN]: 'Админ',
  [USER_ROLE_KEYS.AUDITOR]: 'Аудитор',
  [USER_ROLE_KEYS.OPERATOR_ADVANCED]: 'Оператор продвинутый',
};

export const USER_ROLES = [
  {
    title: USER_ROLE_LABELS[USER_ROLE_KEYS.OPERATOR],
    value: USER_ROLE_KEYS.OPERATOR,
  },
  {
    title: USER_ROLE_LABELS[USER_ROLE_KEYS.MANAGER],
    value: USER_ROLE_KEYS.MANAGER,
  },
  {
    title: USER_ROLE_LABELS[USER_ROLE_KEYS.SUPERVISOR],
    value: USER_ROLE_KEYS.SUPERVISOR,
  },
  { title: USER_ROLE_LABELS[USER_ROLE_KEYS.B2B], value: USER_ROLE_KEYS.B2B },
  {
    title: USER_ROLE_LABELS[USER_ROLE_KEYS.GUEST],
    value: USER_ROLE_KEYS.GUEST,
  },
  {
    title: USER_ROLE_LABELS[USER_ROLE_KEYS.ADMIN],
    value: USER_ROLE_KEYS.ADMIN,
    isAdmin: true,
  },
  {
    title: USER_ROLE_LABELS[USER_ROLE_KEYS.AUDITOR],
    value: USER_ROLE_KEYS.AUDITOR,
  },
  {
    title: USER_ROLE_LABELS[USER_ROLE_KEYS.OPERATOR_ADVANCED],
    value: USER_ROLE_KEYS.OPERATOR_ADVANCED,
  },
];
