import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

const Profile = () => {
  const userInfo = useSelector((state) => state.user.userInfo || {});

  return (
    <Typography variant="h1">Добро пожаловать, {userInfo.name}!</Typography>
  );
};

export default Profile;
