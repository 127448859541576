import Api from 'api/Api';
import swal from 'sweetalert';
import moment from 'moment';
import * as yup from 'yup';
import base64 from 'base-64';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import history from 'appHistory';

import { CHANGE_PASSWORD_PERIOD_DAYS } from 'constants/main';
import { USER_AUDITOR, USER_AUDITOR_DEFAULT_PAGE } from 'constants/user';

export const getFieldsYup = (formMain, formAdded) => {
  let fieldsYup = {};
  formMain.map((item) => {
    const itemName = getObjectElement(item, 'name');

    if (item.req) {
      if (item.min) {
        let objFieldYup = {
          [itemName]: yup
            .string('Введите ' + item.label)
            .min(item.min, 'Длина ' + item.min + ' символов')
            .required('Поле обязательно для заполнения'),
        };

        Object.assign(fieldsYup, objFieldYup);
      } else {
        Object.defineProperty(fieldsYup, item.name, {
          enumerable: true,
          writable: true,
          value: yup
            .string('Введите ' + item.label)
            .required('Поле обязательно для заполнения'),
        });
      }
    }

    return fieldsYup;
  });

  if (formAdded) {
    formAdded.map((item) => {
      return (
        item.req &&
        Object.defineProperty(fieldsYup, item.name, {
          enumerable: true,
          writable: true,
          value: yup
            .string('Введите ' + item.label)
            .required('Поле обязательно для заполнения'),
        })
      );
    });
  }

  return fieldsYup;
};

export const createMarkup = (text) => {
  return { __html: DOMPurify.sanitize(text) };
};

export const getParsedHtmlContent = (text) => {
  return parse(DOMPurify.sanitize(text));
};

export const strip_tags = (input) => {
  let inputModified = '';
  if (input) {
    let tags = /<[^>]{0,100}(?:>|$)|<!--.*?-->/gs;
    if (!Array.isArray(input)) {
      inputModified = input.replace(tags, '').replace(/(&nbsp;)/gi, '  ');
    } else {
      inputModified = [];
      let i = input.length;
      while (i--) {
        const inputItem = getObjectElement(input, i);
        inputModified.push(
          inputItem.replace(tags, '').replace(/(&nbsp;)/gi, '  ')
        );
      }
    }
  }

  return inputModified;
};

export const b64DecodeUnicode = (str) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    base64
      .decode(str)
      .split('')
      .map((c) => {
        const code = `00${c.charCodeAt(0).toString(16)}`;
        return `%${code.slice(-2)}`;
      })
      .join('')
  );
};

export const formatRu = (num) => {
  return Number(num).toLocaleString('ru');
};

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const setToLocalStorage = (name, data) => {
  window.localStorage.setItem(name, JSON.stringify(data));
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};

export const getFromLocalStorage = (name) => {
  const value = window.localStorage.getItem(name);
  if (value !== null) {
    return JSON.parse(value);
  }
};

export function getServiceNameOnId(services, id) {
  const service = services.filter(
    (serviceItem) => serviceItem.value.toString() === id.toString()
  )[0];

  if (typeof service !== 'undefined') {
    return service.label || null;
  }

  return null;
}

export function convertAmount(amount) {
  const sum = parseFloat(amount).toFixed(2);
  const sumSplitted = sum.toString().split('.');
  const strToInt = Number(sumSplitted[0]);

  if (sumSplitted[1] === '00') {
    return strToInt.toLocaleString('ru');
  }

  return strToInt.toLocaleString('ru') + '.' + sumSplitted[1];
}

export function createLinkAndClickOnIt(url) {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', '');
  document.body.appendChild(link);
  link.click();
}

export const exportReport = (report, reportType) =>
  Api.exportReport(reportType, report).then((response) => {
    if (typeof response !== 'undefined') {
      const { status, message, url } = response;

      if (status) {
        createLinkAndClickOnIt(url);
      } else {
        swal('Ошибка!', '' + message, 'error');
      }
    }
  });

export const formatDate = (unix_timestamp) => {
  let date = new Date(unix_timestamp);
  let hours = date.getHours();
  let minutes = '0' + date.getMinutes();
  let seconds = '0' + date.getSeconds();
  let dd = date.getDate();
  let mm = date.getMonth() + 1; //January is 0!
  let yyyy = date.getFullYear();
  let formattedTime = hours + ':' + minutes.slice(-2) + ':' + seconds.slice(-2);

  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  let convertedDate = dd + '/' + mm + '/' + yyyy;

  return convertedDate + ' ' + formattedTime;
};

export const getHumanDateFromTimestamp = (timestamp) =>
  moment.unix(timestamp).format('DD.MM.YYYY HH:mm:ss');

/**
 * Возвращает разницу дат в днях
 * @param {moment date} dateFrom
 * @param {moment date} dateTo
 */
export const getDateDiffInDays = (dateFrom, dateTo = moment()) => {
  if (dateFrom > dateTo || dateFrom === dateTo) return 0;

  if (dateFrom && dateTo) {
    const duration = moment.duration(dateTo.diff(dateFrom));
    const days = duration.asDays();

    return Math.ceil(days);
  }

  return 0;
};

/**
 * Прибавляет три дня до и после указанной даты, учитывая текущую дату
 * @param {moment date} date
 */
export const addTwoDaysBeforeAndAfterDate = (date) => {
  const dateDiff = getDateDiffInDays(date);

  if (!dateDiff) {
    // если разница в днях 0, то выбрана сегодняшняя дата, или дата в будущем
    return {
      dateFrom: moment().subtract(3, 'days'),
      dateTo: moment().add(1, 'days'),
    };
  }

  if (dateDiff <= 3) {
    const dateFrom = moment(date).subtract(2, 'days');

    return {
      dateFrom,
      dateTo: moment().add(1, 'days'),
    };
  }

  if (dateDiff > 3) {
    const dateFrom = moment(date).subtract(2, 'days');
    const dateTo = moment(date).add(2, 'days');

    return {
      dateFrom,
      dateTo,
    };
  }
};

export const getClearPhoneNumber = (phone) =>
  phone.toString().replace(/ /g, '').replace('8(', '').replace(')', '');

export const formatAmount = (amount) => Number(amount).toLocaleString('ru');

export const getHumanDateFormat = (date) =>
  moment(date).format('DD.MM.YYYY HH:mm:ss').replace('T', ' ').replace('Z', '');

export const getHumanJsDateFormat = (dateStr) => {
  if (dateStr) {
    try {
      const splittedDate = dateStr.split('T');
      const time = splittedDate[1].split('.')[0];

      return `${splittedDate[0]} ${time}`;
    } catch (e) {
      return dateStr;
    }
  }

  return '-';
};

/* перевод минут в дни, часы и минуты */
export const formatMinutes = (minutes) => {
  const hours = minutes / 60;
  const day = Math.floor(hours / 24);
  const hour = Math.floor(hours % 24);
  const min = Math.floor(minutes % 60);

  return `${day}д. ${hour}ч. ${min} мин.`;
};

export const sortData = (sortedItems, field) => {
  sortedItems.sort((a, b) => {
    let nameA = getObjectElement(a, field).toLowerCase(),
      nameB = getObjectElement(b, field).toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return sortedItems;
};

export const formatLkDate = (str) =>
  str ? str.replace('T', ' ').replace('Z', '').split('.')[0] : '';

const ldapToJS = (n) => {
  return new Date(n / 1e4 - 1.16444736e13);
};

export const getDaysBeforePassExpired = (lastChangePassword) => {
  const lastChangeDate = new Date(ldapToJS(lastChangePassword));
  const dateExpired = new Date(lastChangeDate);
  dateExpired.setDate(lastChangeDate.getDate() + CHANGE_PASSWORD_PERIOD_DAYS);

  const todayTime = new Date().getTime();
  return Math.floor(
    (dateExpired.getTime() - todayTime) / (1000 * 60 * 60 * 24)
  );
};

export const getObjectElement = (obj, property) => {
  const descriptor = Object.getOwnPropertyDescriptor(obj, `${property}`);

  return descriptor ? descriptor.value : undefined;
};

export const getClassNameForSelect = (touched, errors) => {
  if (touched && errors) return 'customSelectErr';
  return '';
};

export const setObjectElement = (object, itemKey, itemValue) => {
  return Object.defineProperty(object, itemKey, {
    value: itemValue,
    writable: true,
    enumerable: true,
  });
};

export const copyText = (text) => {
  navigator.clipboard.writeText(text);
};

export const getItemsByUserRole = (menu, userRoles) => {
  const filteredMenu = [];

  menu.forEach((menuItem) => {
    let allowed = false;
    if (menuItem?.deniedForRoles) {
      userRoles.forEach((userRole) => {
        if (!menuItem.deniedForRoles.includes(userRole)) {
          allowed = true;
        }
      });
    }

    if (allowed || !menuItem.deniedForRoles) {
      filteredMenu.push(menuItem);
    }
  });

  return filteredMenu;
};

export const setMainLocationByRole = (userRoles) => {
  if (userRoles.length === 1 && userRoles.includes(USER_AUDITOR)) {
    history.push(USER_AUDITOR_DEFAULT_PAGE);
  }
};

export const jsonBeautifier = (json) => {
  json = JSON.parse(json);

  return JSON.stringify(json, null, 2);
};
