import React from 'react';
import { useSelector } from 'react-redux';
import TransactionsTable from './transactionsTable';
import TransactionsTableLK from './transactionTableLk';

const TransactionsResult = () => {
  const transListAP = useSelector((state) => state.main.transListAP);
  const transListLK = useSelector((state) => state.main.transListLK);
  const transListAuto = useSelector((state) => state.main.transListAuto);
  const clientData = useSelector((state) => state.main.clientData);

  return (
    <>
      {transListAP.length > 0 && (
        <div>
          {transListAP != null && transListAP.length > 0 && (
            <TransactionsTable
              list={transListAP}
              title={'Транзакций найдено: ' + transListAP.length}
            />
          )}

          {transListAuto != null && (
            <TransactionsTable
              list={transListAuto}
              title={
                'Номер телефона: ' +
                clientData.Cellphone +
                '. Последние транзакции: ' +
                transListAuto.length
              }
            />
          )}
        </div>
      )}

      {transListLK.length > 0 && (
        <TransactionsTableLK transList={transListLK} />
      )}
    </>
  );
};
export default TransactionsResult;
