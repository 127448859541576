import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const AlertWithDescription = ({ type, text }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity={type}>{text}</Alert>
    </div>
  );
};

AlertWithDescription.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default AlertWithDescription;
