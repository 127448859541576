import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/ui/Button';
import { goToMainPage } from 'functions/route';

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 130px)',
    '& h1': {
      fontSize: '60px',
    },
  },
  text: {
    fontSize: '30px',
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.mainContent}>
      <div>
        <h1>404</h1>
        <div className={classes.text}>Страница не найдена!</div>
        <div className={classes.link}>
          <Button color="primary" onClick={goToMainPage}>
            Перейти на главную
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
