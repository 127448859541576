import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const ServicesSelect = (props) => {
  const services = useSelector((state) => state.main.services);

  const handleInputChange = (newValue) => {
    //передать выбранное значение выше
    if (typeof props.setData === 'function') {
      if (newValue && newValue.value) props.setData(newValue.value);
      else props.setData(0);
    }
  };

  return (
    <div className="customSelectAuto">
      <Select
        isClearable="true"
        isSearchable="true"
        label="Поиск"
        options={services}
        onChange={handleInputChange}
        placeholder="Начните вводить название сервиса"
        noOptionsMessage={() => 'Ничего не найдено'}
      />
    </div>
  );
};
ServicesSelect.propTypes = {
  setData: PropTypes.func.isRequired,
};
export default ServicesSelect;
