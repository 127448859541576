const VERSION_PO = process.env.REACT_APP_VERSION_PO || '';
const isProd = VERSION_PO && Number(VERSION_PO.split('.')[0]);

const prod = () => {
  return {
    API_URL: 'https://api.andromeda.kassa24.kz',
  };
};

const dev = () => {
  return {
    //API_URL: 'http://localhost:8080',
    API_URL: 'https://test.api.andromeda.kassa24.kz',
  };
};

if (isProd) {
  module.exports = prod();
} else {
  module.exports = dev();
}
