import React, { useState, useEffect } from 'react';
import Api from 'api/Api';
import Button from 'components/ui/Button';
import { Grid, Typography } from '@material-ui/core';
import { exportReport } from 'functions/helpers';
import TableCustom from 'components/ui/Table';
import ReportSearch from 'components/ReportSearch';

const tableHead = [
  { field: 'Name', label: 'Имя сервиса' },
  { field: 'State', label: 'Состояние платежей' },
];
let rowsKey = 0;

const ReportProviderStatus = () => {
  const [report, setReports] = useState([]);
  const [filteredReport, setFilteredReport] = useState([]);

  useEffect(() => {
    getApiReports();
  }, []);

  const getApiReports = () => {
    Api.getReport('provStatus').then((response) => {
      if (response !== undefined) {
        const sortedReports = response.sort((a, b) => {
          if (a.Name < b.Name) return -1;
          if (a.Name > b.Name) return 1;
          return 0;
        });
        setReports(sortedReports);
        setFilteredReport(sortedReports);
      }
    });
  };

  const getTableRows = () => {
    let rows = [];
    if (report !== undefined) {
      filteredReport.map((val) => {
        let states = [];
        val.State.map((value) => {
          return states.push(
            <p className={value.Code !== '0' ? 'styleErr' : ''} key={rowsKey++}>
              {value.Text}
            </p>
          );
        });
        return rows.push({ Name: val.Name, State: states });
      });
    }
    return rows;
  };

  const onReportSearch = (searchedData) => {
    setFilteredReport(searchedData);
  };

  return (
    <div>
      <Grid container alignItems="center" spacing={3}>
        <Grid item sm={6} xs={12}>
          <Typography variant="h5">Отчет: провайдеры, статус</Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <ReportSearch
            placeholder="Поиск по названию"
            onReportSearch={onReportSearch}
            fieldsSearch={['Name']}
            itemsList={report}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Button
            color="blueOutlined"
            fullWidth
            onClick={() => exportReport(filteredReport, 'provStatus')}
            disabled={!filteredReport.length}
            variant="outlined"
          >
            Экспорт в Excel
          </Button>
        </Grid>
      </Grid>

      <div className="searchResult">
        {filteredReport.length > 0 && (
          <TableCustom
            head={tableHead}
            columns={getTableRows()}
            pageSize={30}
            showPaging={true}
          />
        )}
        {report.length > 0 &&
          filteredReport.length === 0 &&
          'Ничего не найдено. Попробуйте изменить параметры запроса и повторить поиск.'}
      </div>
    </div>
  );
};

export default ReportProviderStatus;
