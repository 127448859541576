import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Grid,
} from '@material-ui/core';

import InputMask from 'components/ui/InputMaskCustom';
import Button from 'components/ui/Button';
import { formatAmount, getHumanDateFormat } from 'functions/helpers';
import { searchTransactionsSattyZhuldyz } from 'functions/kassa24';

const tableHead = [
  'Терминал',
  'Номер транзакции',
  'UUID',
  'Ref',
  'Сумма',
  'Дата заявки',
  'Статус',
];
let headKey = 0;

const SattyZhuldyz = () => {
  const [uuid, setUuid] = useState('');
  const [ref, setRef] = useState('');
  const [data, setData] = useState([]);

  const searchTrans = () => {
    searchTransactionsSattyZhuldyz({ uuid, ref }, setData);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid sm={4} xs={6} item>
          <div className="customInp">
            <InputMask
              type="text"
              name="uuid"
              callback={(e) => setUuid(e.target.value.trim())}
              placeholder="Введите Uuid"
              mask={'*'.repeat(40)}
              id="uuid"
            />
          </div>
        </Grid>
        <Grid sm={4} xs={6} item>
          <div className="customInp">
            <InputMask
              type="text"
              name="Введите Ref"
              callback={(e) => setRef(e.target.value.trim())}
              placeholder="ref"
              mask={'*'.repeat(40)}
              id="ref"
            />
          </div>
        </Grid>
        <Grid sm={4} xs={12} item>
          <Button
            color="primary"
            className="noMarg"
            onClick={searchTrans}
            fullWidth
          >
            Найти
          </Button>
        </Grid>
      </Grid>

      {data.length ? (
        <>
          <h2>Количество найденных выплат: {data.length}</h2>
          <br />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHead.map((item) => {
                    return <TableCell key={headKey++}>{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(
                  (
                    { IDTerminal, UUID, Ref, DateIn, SN, Amount, Status },
                    i
                  ) => {
                    return (
                      <TableRow key={SN.String}>
                        <TableCell>{IDTerminal}</TableCell>
                        <TableCell>{SN.String}</TableCell>
                        <TableCell>{UUID.String}</TableCell>
                        <TableCell>{Ref.String}</TableCell>
                        <TableCell>{formatAmount(Amount.String)} тг</TableCell>
                        <TableCell>
                          {getHumanDateFormat(DateIn.String)}
                        </TableCell>
                        <TableCell>
                          <span
                            className={Status ? 'styleSuctext' : 'styleGrey'}
                          >
                            {Status ? 'Выдано' : 'Ошибка при выдаче'}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
    </>
  );
};
export default SattyZhuldyz;
