import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  customInput: {
    '& .MuiFilledInput-root.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)!important',
    },
    '& .MuiInputLabel-filled': {
      transform: 'translate(12px, 19px) scale(1)',
    },
    '& .MuiFormLabel-root.Mui-focused': { color: '#00a0e9' },
    '& .MuiInputLabel-shrink': {
      lineHeight: 'unset',
      transform: 'translate(0, -15px) scale(0.75)',
      padding: '0 0 0 10px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
      border: '1px solid #e1e4e8',
      borderRadius: 5,
      boxSizing: 'border-box',
      margin: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInput-underline': {
      '&::before': {
        border: 0,
      },
      '&::after': {
        border: 0,
      },
    },
    '& input': {
      '&:focus': {
        borderColor: '#00a0e9',
        outline: 'none',
      },
      '&:hover': {
        borderColor: '#00a0e9',
        outline: 'none',
      },
      padding: '0 10px',
      boxSizing: 'border-box',
      border: '1px solid #fff',
      height: 50,
      borderRadius: 5,
      width: '100%',
    },
  },
}));

export default useStyles;
