import React, { useState } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import InputMask from 'components/ui/InputMaskCustom';
import Button from 'components/ui/Button';
import DatePicker from 'components/ui/DatePicker';
import Api from 'api/Api';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Grid,
} from '@material-ui/core';
import {
  formatAmount,
  getHumanJsDateFormat,
  getClearPhoneNumber,
} from 'functions/helpers';

const tableHead = [
  'Номер телефона',
  'Дата создания заявки',
  'Дата выдачи',
  'Статус заявки',
  'Запрошенная сумма',
  'Выданная сумма',
  'Терминал',
  'Провайдер',
];
let tableHeadKey = 0;
let resultKey = 0;

const CashOut = () => {
  const [account, setAccount] = useState('');
  const [dateFrom, setDateFrom] = useState(
    new Date(moment().subtract(1, 'days'))
  );
  const [dateTo, setDateTo] = useState(new Date());
  const [resultTable, setResult] = useState([]);

  const search = () => {
    if (!account) {
      return swal('', 'Номер телефона обязателен к заполнению', 'warning');
    }

    Api.searchCashOut({
      account: getClearPhoneNumber(account),
      dateFrom: moment(dateFrom).format('YYYY-MM-DD HH:00:00'),
      dateTo: moment(dateTo).format('YYYY-MM-DD HH:59:00'),
    }).then((response) => {
      if (typeof response !== 'undefined') {
        const { result } = response;

        if (result !== null) {
          setResult(result);
        } else {
          swal('', 'Не найдено операций за выбранный период', 'warning');
          setResult([]);
        }
      }
    });
  };

  const handleChangeDateFrom = (date) => {
    setDateFrom(date);
  };

  const handleChangeDateTo = (date) => {
    setDateTo(date);
  };

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return 'В обработке';
      case 2:
        return 'Истекла';
      case 3:
        return 'Обработана';
      case 4:
        return 'Отменена';
      default:
        return '';
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid sm={3} xs={12} item>
          <div className="customInp">
            <InputMask
              type="tel"
              name="account"
              callback={(e) => setAccount(e.target.value.trim())}
              placeholder="Номер телефона"
              mask="8 (999) 999 99 99"
              id="account"
            />
          </div>
        </Grid>
        <Grid sm={3} xs={6} item>
          <DatePicker selected={dateFrom} onChange={handleChangeDateFrom} />
        </Grid>
        <Grid sm={3} xs={6} item>
          <DatePicker selected={dateTo} onChange={handleChangeDateTo} />
        </Grid>
        <Grid sm={3} xs={12} item>
          <Button color="primary" onClick={search} className="noMarg" fullWidth>
            Найти
          </Button>
        </Grid>
      </Grid>

      {resultTable.length ? (
        <>
          <h2>Количество найденных операций: {resultTable.length}</h2>
          <br />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHead.map((item) => {
                    return <TableCell key={tableHeadKey++}>{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {resultTable.map(
                  ({
                    AmountRequest,
                    AmountOut,
                    DateIn,
                    DateOut,
                    RequestStatus,
                    IDProviders,
                    IDTerminal,
                    Name: ProviderName,
                    PhoneNumber,
                  }) => {
                    return (
                      <TableRow key={resultKey++}>
                        <TableCell>{PhoneNumber.String}</TableCell>
                        <TableCell>
                          {getHumanJsDateFormat(DateIn.String)}
                        </TableCell>
                        <TableCell>
                          {getHumanJsDateFormat(DateOut.String)}
                        </TableCell>
                        <TableCell>
                          {getStatus(Number(RequestStatus.Int64))}
                        </TableCell>
                        <TableCell>
                          {formatAmount(AmountRequest.String)} тг
                        </TableCell>
                        <TableCell>
                          {formatAmount(AmountOut.String)} тг
                        </TableCell>
                        <TableCell>{IDTerminal.Int64}</TableCell>
                        <TableCell>
                          {ProviderName.String} (id: {IDProviders.Int64})
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
    </>
  );
};

export default CashOut;
