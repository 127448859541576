import Axios from 'axios';
import swal from 'sweetalert';

import history from 'appHistory';
import { logout } from 'features/userSlice';
import { showProgressBar } from 'features/uiSlice';
import { logErrorToSentry } from 'functions/sentry';

const Interceptor = (store) => {
  Axios.interceptors.request.use(
    (conf) => {
      store.dispatch(showProgressBar(true));
      return conf;
    },
    (error) => {
      store.dispatch(showProgressBar(false));
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (next) => {
      store.dispatch(showProgressBar(false));
      return Promise.resolve(next);
    },
    (error) => {
      store.dispatch(showProgressBar(false));

      if (error.response.status === 401) {
        store.dispatch(logout());
        store.dispatch(history.push('/'));
      } else {
        logErrorToSentry(error);

        swal(
          typeof error.response.data.message !== 'undefined'
            ? error.response.data.message
            : 'Не удалось выполнить запрос',
          typeof error.response.data.message !== 'undefined'
            ? error.response.data.error
            : 'Повторите попытку позднее',
          'error'
        );
      }

      if (error.response.status >= 500) {
        logErrorToSentry(error);
      }

      // You can handle error here and trigger warning message without get in the code inside
      return Promise.reject(error);
    }
  );
};

export default Interceptor;
